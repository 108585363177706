<template>
  <canvas ref="canvas">
    <img
      v-if="imageSource"
      :alt="imageAlt"
      :src="imageSource"
    >
  </canvas>
</template>

<script>
import CropArea from '@/api/models/CropArea';
import { MARKS } from '@/helpers/const';
import circleIcon from '@/assets/icons/circle.svg';
import triangleIcon from '@/assets/icons/triangle.svg';
import squareIcon from '@/assets/icons/square.svg';
import polygonIcon from '@/assets/icons/polygon.svg';
import firstCustomIcon from '@/assets/icons/firstCustom.svg';
import secondCustomIcon from '@/assets/icons/secondCustom.svg';
import thirdCustomIcon from '@/assets/icons/thirdCustom.svg';
import fourthCustomIcon from '@/assets/icons/fourthCustom.svg';

/**
 * @emits onLoadedImage - image was loaded
 */
export default {
  name: 'ImageViewer',
  props: {
    imageSource: {
      type: String,
      default: '',
      required: false,
    },
    imageAlt: {
      type: String,
      default: '',
      required: false,
    },
    cropArea: {
      type: CropArea,
      default: null,
    },
    marks: {
      type: Array,
      default: () => [],
    },
    coefficientMarkSize: {
      type: Number,
      default: 1,
    },
    showMarks: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    image: null,
    circle: null,
    triangle: null,
    square: null,
    polygon: null,
    firstCustom: null,
    secondCustom: null,
    thirdCustom: null,
    fourthCustom: null,
  }),
  watch: {
    async imageSource(value) {
      await this.reloadImage(value);
    },
    cropArea() {
      this.renderCrop();
    },
    marks() {
      this.renderCrop();
    },
  },
  async created() {
    this.circle = await this.loadImage(circleIcon);
    this.triangle = await this.loadImage(triangleIcon);
    this.square = await this.loadImage(squareIcon);
    this.polygon = await this.loadImage(polygonIcon);
    this.firstCustom = await this.loadImage(firstCustomIcon);
    this.secondCustom = await this.loadImage(secondCustomIcon);
    this.thirdCustom = await this.loadImage(thirdCustomIcon);
    this.fourthCustom = await this.loadImage(fourthCustomIcon);
    await this.reloadImage(this.imageSource);
  },
  methods: {
    async loadImage(source) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => resolve(image);
        image.onerror = reject;
        image.src = source;
      });
    },
    async reloadImage(imageSource) {
      if (imageSource) {
        try {
          this.image = await this.loadImage(imageSource);
          this.renderCrop();
          this.$emit('onLoadedImage');
        } catch (e) {
          console.log(`error loading image ${imageSource}`);
        }
      }
    },
    getMarkIcon(markType) {
      switch (markType) {
        case MARKS.CIRCLE:
          return this.circle;
        case MARKS.TRIANGLE:
          return this.triangle;
        case MARKS.SQUARE:
          return this.square;
        case MARKS.POLYGON:
          return this.polygon;
        case MARKS.FIRST_CUSTOM:
          return this.firstCustom;
        case MARKS.SECOND_CUSTOM:
          return this.secondCustom;
        case MARKS.THIRD_CUSTOM:
          return this.thirdCustom;
        case MARKS.FOURTH_CUSTOM:
          return this.fourthCustom;
        default:
          throw new RangeError();
      }
    },
    renderCrop() {
      if (!this.imageSource || !this.image) return undefined;

      const { canvas } = this.$refs;
      if (!canvas.getContext) return undefined;

      let sx = 0;
      let sy = 0;
      let dx = 0;
      let dy = 0;
      if (this.cropArea && this.cropArea.height > 0 && this.cropArea.width > 0) {
        if (this.cropArea.x > 0) {
          sx = this.cropArea.x;
        } else {
          dx = -this.cropArea.x;
        }
        if (this.cropArea.y > 0) {
          sy = this.cropArea.y;
        } else {
          dy = -this.cropArea.y;
        }
        canvas.width = this.cropArea.width;
        canvas.height = this.cropArea.height;
      } else {
        canvas.width = this.image.naturalWidth;
        canvas.height = this.image.naturalHeight;
      }

      const context = canvas.getContext('2d');
      context.drawImage(
        this.image,
        sx,
        sy,
        canvas.width,
        canvas.height,
        dx,
        dy,
        canvas.width,
        canvas.height,
      );

      if (this.showMarks) {
        const size = canvas.width * this.coefficientMarkSize;
        this.marks.forEach((mark) => {
          const icon = this.getMarkIcon(mark.type);
          context.drawImage(
            icon,
            mark.x - this.cropArea.x,
            mark.y - this.cropArea.y,
            size,
            size,
          );
        });
      }

      return undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
