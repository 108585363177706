import { LOAD_SESSION } from '@/store/modules/session/actionTypes';
import { UPDATE_SESSION } from '@/store/modules/session/mutationTypes';
import { UPDATE_WORKSPACE } from '@/store/modules/workspace/mutationTypes';
import { API } from '@/api/api';

export default ({
  async [LOAD_SESSION]({ commit }) {
    const response = await API.SessionService.getSession();
    if (response.success) {
      commit(UPDATE_SESSION, response.data);
      commit(UPDATE_WORKSPACE, response.data.workspace);
    }
  },
});
