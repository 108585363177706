import {
  ADD_NOTIFICATIONS,
  NOTIFICATION_RESET_STATE,
  UPDATE_NOTIFICATIONS,
} from '@/store/modules/notifications/mutationTypes';
import getInitialState from '@/store/modules/notifications/getInitialState';

export default ({
  [UPDATE_NOTIFICATIONS](state, data) {
    state.notifications = data;
  },
  [ADD_NOTIFICATIONS](state, data) {
    state.notifications = [...state.notifications, ...data];
  },
  [NOTIFICATION_RESET_STATE](state) {
    Object.assign(state, getInitialState());
  },
});
