import request from '@/api/httpClient';
import Workspace from '@/api/models/Workspace';

export class WorkspaceService {
  static async getWorkspace() {
    try {
      const response = await request.getRequest(
        '/Workspaces',
        {},
        {},
      );
      const workspace = Workspace.fromApi(response.data);
      return { success: true, data: workspace };
    } catch (e) {
      return { success: false, data: { error: '' } };
    }
  }

  static async updateWorkspace(id, workspace) {
    try {
      const response = await request.putRequest(
        `/Workspaces/${id}`,
        workspace.toApi(),
        { 'Content-Type': 'multipart/form-data' },
      );
      const updatedWorkspace = Workspace.fromApi(response.data);
      return { success: true, data: updatedWorkspace };
    } catch (e) {
      return { success: false, data: { error: '' } };
    }
  }
}
