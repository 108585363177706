<template>
  <div class="cw">
    <div class="cw__wrap">
      <fab
        v-if="!showCW"
        icon-name="comment"
        class="cw__fab"
        :class="{ 'md-fab_new': comments.length > 0 }"
        @click.native="toggleCW"
      />
      <div
        v-show="showCW"
        class="cw__chat"
      >
        <md-button
          class="md-icon-button cw__close"
          @click.native="toggleCW"
        >
          <md-icon>close</md-icon>
        </md-button>
        <comments-widget-header
          :get-localize="getLocalize"
        />
        <comments-widget-comments
          ref="comments"
          :comments="comments"
          :user-id="userId"
          :allow-delete-my-self="allowDeleteMySelf"
          :allow-delete-any="allowDeleteAny"
          :allow-reply-my-self="allowReplyMySelf"
          :allow-reply-any="allowReplyAny"
          :allow-reply-other="allowReplyOther"
          :get-localize="getLocalize"
          :emojis="emojis"
          @onCommentDeleted="onCommentDeleted"
          @onCommentReplied="onCommentSelectedToReply"
        />
        <comments-widget-reply
          ref="reply"
          :max-reply-length="maxCommentLength"
          :get-localize="getLocalize"
          :smile-enabled="smileEnabled"
          :text-enabled="textEnabled"
          :reply-author="replyCommentAuthor"
          :emojis="emojis"
          @onReplyText="onReplyText"
          @onReplyEmoji="onReplyEmoji"
          @onReplyCanceled="onReplyCanceled"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _find from 'lodash/find';
import _upperFirst from 'lodash/upperFirst';
import _camelCase from 'lodash/camelCase';
import Fab from '@/components/buttons/Fab.vue';
import CommentsWidgetHeader from './CWHeader.vue';
import CommentsWidgetComments from './CWComments.vue';
import CommentsWidgetReply from './CWReply.vue';

/**
 * @emit onCommentTextAdded - comment text added
 * @emit onCommentEmojiAdded - comment emoji added
 * @emit onCommentDeleted - comment deleted
 * @emit onShowCWUpdated - comment updated
 */
export default {
  name: 'CommentsWidget',
  components: {
    CommentsWidgetHeader,
    CommentsWidgetComments,
    CommentsWidgetReply,
    Fab,
  },
  props: {
    comments: {
      type: Array,
      default: () => [],
      required: false,
    },
    getLocalize: {
      type: Function,
      default: (key) => key,
      required: false,
    },
    maxCommentLength: {
      type: Number,
      default: 10,
      required: false,
    },
    smileEnabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    textEnabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    userId: {
      type: Number,
      default: -1,
      required: false,
    },
    allowDeleteMySelf: {
      type: Boolean,
      default: false,
      required: false,
    },
    allowDeleteAny: {
      type: Boolean,
      default: false,
      required: false,
    },
    allowReplyMySelf: {
      type: Boolean,
      default: false,
      required: false,
    },
    allowReplyAny: {
      type: Boolean,
      default: false,
      required: false,
    },
    allowReplyOther: {
      type: Boolean,
      default: false,
      required: false,
    },
    showCW: {
      type: Boolean,
      default: false,
    },
    emojis: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    selectedComment: null,
  }),
  computed: {
    replyCommentAuthor() {
      const comment = _find(this.comments, (c) => +c.id === +this.selectedComment);

      if (!comment || !comment.author) return '';

      return _upperFirst(_camelCase(comment.author));
    },
  },
  methods: {
    toggleCW() {
      this.$emit('onShowCWUpdated', !this.showCW);
    },
    onCommentDeleted(commentId) {
      this.$emit('onCommentDeleted', commentId);
      this.$refs.comments.unSelectAllComments();
    },
    onReplyText(text) {
      this.$emit('onCommentTextAdded', text, this.selectedComment);
      this.onReplyCanceled();
      this.$refs.reply.cleanReplyText();
    },
    onReplyEmoji(emojiName) {
      this.$emit('onCommentEmojiAdded', emojiName, this.selectedComment);
      this.onReplyCanceled();
    },
    onCommentSelectedToReply(commentId) {
      this.$refs.reply.focus();
      this.$refs.comments.unSelectAllComments();
      this.$refs.comments.selectComment(commentId);
      this.selectedComment = commentId;
    },
    onReplyCanceled() {
      this.selectedComment = null;
      this.$refs.comments.unSelectAllComments();
    },
    scrollToBottom() {
      this.$refs.comments.scrollToBottom();
    },
    showWidget() {
      if (!this.showCW) {
        this.toggleCW();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/commentsWidget.scss";
</style>
