<template>
  <div class="workspace">
    <div
      class="workspace__sub-header"
      :class="{ 'workspace__sub-header_center': !showClassList }"
    >
      <custom-button
        v-if="showClassList"
        class="workspace__class-list"
        :title="getLocalize('workspace.myClasses')"
        icon="keyboard_arrow_left"
        :show-shadow="false"
        @click="openClassList"
      />
      <SwitchTwoLabel
        :value="showMyBooks"
        :off-label="getLocalize('workspace.classBooks')"
        off-icon="people"
        :on-label="getLocalize('workspace.myBooks')"
        on-icon="person"
        @input="onChangeShowMyBooks"
      />
      <custom-button
        v-if="showClassList"
        class="hidden"
        :title="getLocalize('workspace.myClasses')"
        icon="keyboard_arrow_left"
      />
    </div>
    <div class="workspace-wrap">
      <div
        v-for="book of sortedBooks"
        :key="book.id"
        class="book"
      >
        <div
          v-if="book.predefined"
          class="book__inspiration"
        >
          {{ getLocalize('workspace.inspiration') }}
        </div>
        <checkered-background
          :background-color="book.backgroundColor"
          :line-color="backgroundTint(book)"
          :lines-count-horizontally="14"
          :lines-count-vertically="21"
          class="book-wrap"
          @click.native="openBook(book.id)"
        >
          <div class="book__info">
            <p
              v-if="book.title"
              class="book__info-title"
            >
              {{ book.title }}
            </p>
            <p
              v-else
              class="book__info-title hidden"
            >
              title
            </p>
            <p
              v-if="book.author"
              class="book__info-author"
            >
              {{ book.author }}
            </p>
            <p
              v-else
              class="book__info-author hidden"
            >
              author
            </p>
          </div>
          <div class="book__picture">
            <image-viewer
              class="book__picture-image"
              :class="{ 'placeholder': !book.imageSource }"
              :image-source="book.imageSource"
              :crop-area="book.visibleArea"
            />
          </div>
        </checkered-background>
      </div>
    </div>
    <FabTemplateBooks
      v-if="templateBooks && templateBooks.length > 0"
      :template-books="templateBooks"
      @open="loadTemplates"
      @onClickBook="createBookFromTemplate"
    />
    <fab
      v-else
      class="workspace__fab"
      icon-name="add"
      @click.native="$router.push({ name: pageRoutes.CREATE_BOOK })"
    />
  </div>
</template>

<script>
import _invoke from 'lodash/invoke';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import { API } from '@/api/api';
import { APP_PAGES, BOOK_SOURCE } from '@/helpers/const';
import getBackgroundTintMixin from '@/mixins/getBackgroundTintMixin';
import CheckeredBackground from '@/components/checkeredBackground/CheckeredBackground.vue';
import FabTemplateBooks from '@/components/buttons/FabTemplateBooks.vue';
import Fab from '@/components/buttons/Fab.vue';
import ImageViewer from '@/components/imageViewer/ImageViewer.vue';
import toastMixin from '@/mixins/toastMixin';
import localizationMixin from '@/mixins/localizationMixin';
import Book from '@/api/models/Book';
import SwitchTwoLabel from '@/components/switchTwoLabel/SwitchTwoLabel.vue';
import { CHANGE_SHOW_MY_BOOKS } from '@/store/modules/userSettings/actionTypes';
import sessionMixin from '@/mixins/sessionMixin';
import CustomButton from '@/components/buttons/CustomButton.vue';
import classListMixin from '@/mixins/classListMixin';
import { MESSAGES_SET_HEADER_MESSAGE } from '@/store/modules/messages/actionTypes';

export default {
  name: 'Workspace',
  components: {
    SwitchTwoLabel,
    ImageViewer,
    CheckeredBackground,
    FabTemplateBooks,
    Fab,
    CustomButton,
  },
  mixins: [getBackgroundTintMixin, localizationMixin, toastMixin, sessionMixin, classListMixin],
  data: () => ({
    books: null,
    templates: null,
    isTemplatesLoading: false,

    colors: JSON.parse(process.env.VUE_APP_FRONT_PAGE_COLORS),
    pageRoutes: APP_PAGES,
    nextPageToken: '',
    nextTemplateToken: '',
  }),
  computed: {
    workspaceName() {
      return _get(this.$store, 'state.workspace.name', false);
    },
    showMyBooks() {
      return _get(this.$store, 'state.userSettings.showMyBooks', false);
    },
    templateBooks() {
      return this.templates || _invoke(this.books, 'filter', (book) => book.isTemplate);
    },
    sortedBooks() {
      return _sortBy(this.filteredBooks, ['predefined', 'createDatetime']).reverse();
    },
    filteredBooks() {
      return _filter(this.books,
        (book) => {
          if (this.showMyBooks && book.userId !== this.sessionUserId) return false;

          return !book.predefined || (
            book.predefined
            && book.language
            && book.language.toLowerCase().includes(this.currentLanguage.toLowerCase()));
        });
    },
  },
  watch: {
    async nextPageToken(value) {
      if (value && value.length) {
        await this.getLoadBookTask(value);
      }
    },
    async nextTemplateToken(value) {
      if (value && value.length) {
        await this.loadTemplates(value);
      }
    },
    workspaceName(value) {
      this.$store.dispatch(MESSAGES_SET_HEADER_MESSAGE, value);
    },
  },
  async created() {
    this.books = [];
    this.nextPageToken = '';
    const loadFirstPageTask = this.getLoadBookTask();
    await this.toggleToast(this.getLocalize('toast.loading'), loadFirstPageTask, this.loadTemplates());
  },
  mounted() {
    this.$store.dispatch(MESSAGES_SET_HEADER_MESSAGE, this.workspaceName);
  },
  beforeDestroy() {
    this.$store.dispatch(MESSAGES_SET_HEADER_MESSAGE, '');
  },
  methods: {
    onChangeShowMyBooks(value) {
      this.$store.dispatch(CHANGE_SHOW_MY_BOOKS, value);
    },
    backgroundTint(book) {
      return this.getBackgroundTint(this.colors, book.backgroundColor, book.backgroundTint);
    },
    openBook(id) {
      this.$router.push({ name: APP_PAGES.SOLVE_FRONT_PAGE, params: { bookId: id } });
    },
    getLoadBookTask(pageToken) {
      return API.BooksService.getBooks({ pageToken }).then((response) => {
        if (response.success) {
          this.books = [...this.books, ...response.data];
          this.nextPageToken = response.token;
        }
      }).catch(() => {
      });
    },
    async loadTemplates(pageToken) {
      if (!this.isTemplatesLoading) {
        this.isTemplatesLoading = true;
        const response = await API.BooksService.getBooks({
          type: BOOK_SOURCE.TEMPLATE,
          pageToken,
        });

        if (response.success) {
          this.templates = pageToken ? this.templates.concat(response.data) : response.data;
          this.nextTemplateToken = response.token;
        }
        this.isTemplatesLoading = false;
      }
    },
    async createBookFromTemplate(bookId) {
      const task = API.BooksService.createBook(new Book({
        sourceBookId: bookId,
      }));
      const [response] = await this.toggleToast(this.getLocalize('toast.loading'), task);
      if (response.success) {
        await this.$router.push({
          name: APP_PAGES.EDIT_FRONT_PAGE,
          params: { bookId: response.data.id },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/workspace.scss";
</style>
