import request from '@/api/httpClient';
import SignInApiModel from '@/api/models/SignInApiModel';
import UserStorage from '@/storages/userStorage';
import { AUTHORIZATION_HEADER, TOKEN_EXPIRES_HEADER } from '@/helpers/const';

export class AccountService {
  static async signIn(login, password) {
    try {
      const data = new SignInApiModel({ login, password });
      const response = await request.postRequest('/Token', data, {});
      const token = response.headers[AUTHORIZATION_HEADER];
      const expiresIn = response.headers[TOKEN_EXPIRES_HEADER];
      UserStorage.setToken(token, expiresIn);
      return { success: true };
    } catch (e) {
      return { success: false, data: { error: e.message } };
    }
  }

  static async login(authKey) {
    try {
      const response = await request.postRequest('/Auths', { token: authKey });
      const token = response.headers[AUTHORIZATION_HEADER];
      const expiresIn = response.headers[TOKEN_EXPIRES_HEADER];
      UserStorage.setToken(token, expiresIn);
      return { success: true };
    } catch (e) {
      return { success: false, data: { error: e.message } };
    }
  }

  static async logout() {
    try {
      UserStorage.clear();
      return { success: true };
    } catch (e) {
      return { success: false, data: { error: e.message } };
    }
  }
}
