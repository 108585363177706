import EditBook from '@/views/pages/book/EditBook.vue';
import SolveBook from '@/views/pages/book/SolveBook.vue';
import { APP_PAGES } from '@/helpers/const';

export const bookCreateRoutes = {
  path: '',
  component: {
    render(c) {
      return c('router-view');
    },
  },
  meta: { requiresAuth: true },
  children: [
    {
      path: '/book/create',
      name: APP_PAGES.CREATE_BOOK,
      component: EditBook,
    },
    {
      path: '/book/:bookId/page/front',
      name: APP_PAGES.EDIT_FRONT_PAGE,
      component: EditBook,
    },
    {
      path: '/book/:bookId/page/:pageNumber/step/:step',
      name: APP_PAGES.EDIT_PAGES,
      component: EditBook,
    },
    {
      path: '/book/:bookId/pageId/:pageId/step/:step',
      name: APP_PAGES.EDIT_PAGES_BY_ID,
      component: EditBook,
    },
    {
      path: '/book/:bookId',
      redirect: { name: APP_PAGES.EDIT_FRONT_PAGE },
    },
    {
      path: '/solve/:bookId/page/front',
      name: APP_PAGES.SOLVE_FRONT_PAGE,
      component: SolveBook,
    },
    {
      path: '/solve/:bookId/page/end',
      name: APP_PAGES.SOLVE_END_PAGE,
      component: SolveBook,
    },
    {
      path: '/solve/:bookId/page/:pageNumber',
      name: APP_PAGES.SOLVE_BOOK,
      component: SolveBook,
    },
    {
      path: '/solve/:bookId/pageId/:pageId',
      name: APP_PAGES.SOLVE_BOOK_BY_ID,
      component: SolveBook,
    },
    {
      path: '/solve/:bookId',
      redirect: { name: APP_PAGES.SOLVE_FRONT_PAGE },
    },
  ],
};
