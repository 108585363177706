export default () => ({
  id: undefined,
  name: '',
  showImageSearchInFileDialog: false,
  showCameraInFileDialog: false,
  showUploadFileInFileDialog: false,
  showWorkspaceImageInFileDialog: false,
  showUserImageInFileDialog: false,
  smileCommentsAvailable: false,
  textCommentsAvailable: false,
});
