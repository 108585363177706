import _invoke from 'lodash/invoke';
import Error from '@/api/models/Error';

const ERROR_DATE = 'date';
const ERROR_MESSAGE = 'message';
const ERRORS = 'errors';

export default class ErrorResponse {
  constructor(parameters) {
    const {
      date,
      message,
      errors,
    } = parameters;
    this.errorDate = date;
    this.message = message;
    this.errors = errors;
  }

  static fromApi(object = {}) {
    return new ErrorResponse(
      {
        date: object[ERROR_DATE],
        message: object[ERROR_MESSAGE],
        errors: _invoke(
          object,
          [ERRORS, 'map'],
          Error.fromApi,
        ) || [],
      },
    );
  }
}
