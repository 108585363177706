import _invoke from 'lodash/invoke';

const ID = 'id';
const PARENT_COMMENT_ID = 'parentCommentId';
const USER_ID = 'userId';
const AUTHOR = 'authorName';
const TEXT = 'text';
const SMILE = 'smile';
const CREATION_DATE = 'createDate';
const REPLIES = 'childComments';

export default class Comment {
  constructor(parameters) {
    const {
      id,
      parentCommentId,
      userId,
      author,
      text,
      smile,
      created,
    } = parameters;
    this.id = id;
    this.parentCommentId = parentCommentId;
    this.userId = userId;
    this.author = author;
    this.text = text;
    this.smile = smile;
    this.created = created;
    this.replies = _invoke(
      parameters,
      'replies.map',
      (item) => new Comment(item),
    ) || [];
  }

  static fromApi(object = {}) {
    return new Comment(
      {
        id: object[ID],
        userId: object[USER_ID],
        author: object[AUTHOR],
        text: object[TEXT],
        smile: object[SMILE],
        created: object[CREATION_DATE],
        replies: _invoke(
          object,
          [REPLIES, 'map'],
          Comment.fromApi,
        ) || [],
      },
    );
  }

  toApi() {
    return {
      [PARENT_COMMENT_ID]: this.parentCommentId,
      [TEXT]: this.text,
      [SMILE]: this.smile,
    };
  }
}
