import Vue from 'vue';
import VueRouter from 'vue-router';

import { bookCreateRoutes } from '@/router/modules/book';
import SignInPage from '@/views/pages/signIn/SignInPage.vue';
import UserStorage from '@/storages/userStorage';
import Workspace from '@/views/pages/Workspace.vue';
import Settings from '@/views/pages/settings/Settings.vue';
import { APP_PAGES } from '@/helpers/const';

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: '/',
      component: {
        render(c) {
          return c('router-view');
        },
      },
      meta: { requiresAuth: true },
      children: [
        {
          path: '',
          name: APP_PAGES.WORKSPACE,
          component: Workspace,
        },
        {
          path: '/settings',
          name: APP_PAGES.SETTINGS,
          component: Settings,
        },
        bookCreateRoutes,
      ],
    },
    {
      path: '/signIn',
      name: APP_PAGES.SIGN_IN,
      component: SignInPage,
      meta: { guest: true },
    },
    {
      path: '*',
      redirect: { name: APP_PAGES.WORKSPACE },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (UserStorage.isAuthenticated()) {
    if (to.name === APP_PAGES.SIGN_IN && !to.query.authKey) {
      next({ name: APP_PAGES.WORKSPACE });
      return;
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    next({ name: APP_PAGES.SIGN_IN });
    return;
  }
  next();
});

const originalPush = router.push;
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => {
    if (err) {
      if (err.name && err.name !== 'NavigationDuplicated') {
        return Promise.resolve(true);
      }
      return Promise.reject(err);
    }
    return Promise.resolve(false);
  });
};

export default router;
