<template>
  <img
    :src="src"
    alt=""
    class="logo"
    @click="$router.push({ name: pageRoutes.WORKSPACE })"
  >
</template>

<script>
import { APP_PAGES } from '@/helpers/const';

export default {
  name: 'Logo',
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    pageRoutes: APP_PAGES,
  }),
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/logo.scss";
</style>
