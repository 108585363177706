<template>
  <div class="mark-counter">
    <slot />
    <div class="mark-counter__counter-box">
      <div
        v-for="predefinedMarkType of markTypes"
        :key="predefinedMarkType"
        class="mark-counter__counter"
      >
        <md-badge
          :md-content="getMarkCount(predefinedMarkType)"
          class="mark-counter__counter-mark"
        >
          <img
            class="mark-counter__mark"
            :src="getMarkImageSource(predefinedMarkType)"
          >
        </md-badge>
      </div>
    </div>
  </div>
</template>

<script>
import { MARKS } from '@/helpers/const';
import getMarkImageSource from '@/mixins/getMarkImageSource';

export default {
  name: 'MarkCounter',
  mixins: [getMarkImageSource],
  props: {
    marks: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    markTypes: MARKS,
  }),
  methods: {
    getMarkCount(markType) {
      return this.marks.filter((mark) => mark.type === markType).length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/markCounter.scss";
</style>
