import ErrorResponse from '@/api/models/ErrorResponse';

export function parseErrors(requestTask) {
  return requestTask.catch((e) => {
    const error = ErrorResponse.fromApi(e.response.data);
    return { success: false, error, status: e.response.status };
  });
}

export function getBaseUrl() {
  const restConformity = JSON.parse(process.env.VUE_APP_FM_API_ENDPOINTS_CONFORMITY);
  return restConformity[window.location.host] || process.env.VUE_APP_FM_API_ENDPOINT;
}
