<template>
  <div class="cw__reply">
    <div
      v-if="showEbar"
      class="cw__ebar"
    >
      <div class="cw__ebar-emojies">
        <emoji
          v-for="(emoji, i) of emojis"
          :key="i"
          :image-source="emoji.source"
          @click.native="replyEmoji(emoji.name)"
        />
      </div>
    </div>
    <textarea
      id="cw-reply"
      ref="textBlock"
      v-model="replyText"
      name="cw-reply"
      class="cw__reply-input"
      :placeholder="getLocalize(placeHolder)"
      :maxlength="maxReplyLength"
      :readonly="!textEnabled"
      @input="resize($event)"
      @keyup.enter="onReplyText"
    />
    <md-button
      v-if="smileEnabled"
      class="cw__reply-emoji md-icon-button"
      @click="toggleEbar"
    >
      <md-icon>emoji_emotions</md-icon>
    </md-button>
    <md-button
      v-if="commentingEnabled"
      type="submit"
      class="cw__reply-button md-icon-button md-primary"
      @click="onReplyText"
    >
      <md-icon>send</md-icon>
    </md-button>
  </div>
</template>

<script>
import _trimStart from 'lodash/trimStart';
import Emoji from '@/components/emoji/Emoji.vue';

/**
 * @emit onReplyText - reply text added
 * @emit onReplyEmoji - reply emoji added
 * @emit onReplyCanceled - cancel reply for the comment
 */
export default {
  name: 'CommentsWidgetReply',
  components: {
    Emoji,
  },
  props: {
    maxReplyLength: {
      type: Number,
      default: 10,
      required: false,
    },
    getLocalize: {
      type: Function,
      default: (key) => key,
      required: false,
    },
    smileEnabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    textEnabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    replyAuthor: {
      type: String,
      default: '',
      required: false,
    },
    emojis: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    showEbar: false,
    feedback: '',
    replyText: '',
  }),
  computed: {
    placeHolder() {
      if (this.replyAuthor) {
        return '';
      }
      if (this.textEnabled) {
        return 'commentsWidget.enterComment';
      }
      if (this.smileEnabled) {
        return 'commentsWidget.selectSmile';
      }
      return 'commentsWidget.notAvailable';
    },
    commentingEnabled() {
      return this.textEnabled || this.smileEnabled;
    },
  },
  watch: {
    replyAuthor(newValue, oldValue) {
      if (oldValue) {
        this.replyText = _trimStart(this.replyText.replace(`@${oldValue}`, ''));
      }

      if (newValue) {
        this.replyText = `@${newValue} ${_trimStart(this.replyText)}`.slice(0, this.maxReplyLength);
      }
    },
    replyText(newValue, oldValues) {
      if (this.replyAuthor && !newValue.includes(`@${this.replyAuthor}`)) {
        this.replyText = _trimStart(oldValues.replace(`@${this.replyAuthor}`, ''));
        this.$emit('onReplyCanceled');
      }
    },
  },
  methods: {
    resize(e) {
      e.target.style.height = 'auto';
      e.target.style.height = `${e.target.scrollHeight}px`;
    },
    toggleEbar() {
      this.showEbar = !this.showEbar;
    },
    onReplyText() {
      this.$emit('onReplyText', this.replyText);
    },
    cleanReplyText() {
      this.replyText = '';
    },
    replyEmoji(emojiName) {
      if (emojiName) {
        this.$emit('onReplyEmoji', emojiName);
      }
      this.toggleEbar();
    },
    focus() {
      this.$refs.textBlock.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/commentsWidget.scss";
</style>
