import i1 from '@/assets/images/emojis/1.png';
import i2 from '@/assets/images/emojis/2.png';
import i3 from '@/assets/images/emojis/3.png';
import i4 from '@/assets/images/emojis/4.png';
import i5 from '@/assets/images/emojis/5.png';
import i6 from '@/assets/images/emojis/6.png';
import i7 from '@/assets/images/emojis/7.png';
import i8 from '@/assets/images/emojis/8.png';
import i9 from '@/assets/images/emojis/9.png';
import i10 from '@/assets/images/emojis/10.png';
import i11 from '@/assets/images/emojis/11.png';
import i12 from '@/assets/images/emojis/12.png';
import i13 from '@/assets/images/emojis/13.png';
import i14 from '@/assets/images/emojis/14.png';
import i15 from '@/assets/images/emojis/15.png';
import i16 from '@/assets/images/emojis/16.png';

export default {
  data: () => ({
    emojis: [
      {
        name: 'emoji_1',
        source: i1,
      },
      {
        name: 'emoji_2',
        source: i2,
      },
      {
        name: 'emoji_3',
        source: i3,
      },
      {
        name: 'emoji_4',
        source: i4,
      },
      {
        name: 'emoji_5',
        source: i5,
      },
      {
        name: 'emoji_6',
        source: i6,
      },
      {
        name: 'emoji_7',
        source: i7,
      },
      {
        name: 'emoji_8',
        source: i8,
      },
      {
        name: 'emoji_9',
        source: i9,
      },
      {
        name: 'emoji_10',
        source: i10,
      },
      {
        name: 'emoji_11',
        source: i11,
      },
      {
        name: 'emoji_12',
        source: i12,
      },
      {
        name: 'emoji_13',
        source: i13,
      },
      {
        name: 'emoji_14',
        source: i14,
      },
      {
        name: 'emoji_15',
        source: i15,
      },
      {
        name: 'emoji_16',
        source: i16,
      },
    ],
  }),
};
