<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="cw__comment">
    <div
      src="#"
      alt="Avatar"
      class="cw__comment-avatar"
    >
      {{ commentAuthor }}
    </div>
    <div class="cw__comment-body">
      <img
        v-if="emojiSource"
        class="cw__comment-emoji"
        :src="emojiSource"
        alt=""
      >
      <span
        v-else
        class="cw__comment-text"
        v-html="htmlText"
      />
      <div class="cw__comment-body-footer">
        <span class="cw__comment-timestamp">{{ agoFormatedDate }}</span>
        <md-icon
          v-if="allowReply && !selected"
          md-icon
          class="cw__comment-body-reply"
          @click.native="onCommentSelectedToReply"
        >
          reply
        </md-icon>
        <md-icon
          v-if="allowDelete && !selected"
          md-icon
          class="cw__comment-body-delete"
          @click.native="onCommentDeleted"
        >
          delete
        </md-icon>
      </div>
    </div>
  </div>
</template>

<script>
import _escape from 'lodash/escape';
import _find from 'lodash/find';
import _get from 'lodash/get';
import dateFormatMixin from '@/mixins/dateFormatMixin';

/**
 * @emit onCommentDeleted - comment deleted
 * @emit onCommentReplied - comment replied
 */
export default {
  name: 'CommentsWidgetComment',
  mixins: [dateFormatMixin],
  props: {
    text: {
      type: String,
      default: '',
      required: false,
    },
    emojiName: {
      type: String,
      default: '',
      required: false,
    },
    emojis: {
      type: Array,
      default: () => [],
    },
    created: {
      type: String,
      default: '',
      required: false,
    },
    author: {
      type: String,
      default: 'No author',
      required: false,
    },
    getLocalize: {
      type: Function,
      default: (key) => key,
      required: false,
    },
    allowDelete: {
      type: Boolean,
      default: false,
      required: false,
    },
    allowReply: {
      type: Boolean,
      default: false,
      required: false,
    },
    selected: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    commentAuthor() {
      const splited = this.author.split(' ');
      const result = (splited && splited.length > 0)
        ? splited.map((s) => (s && s.length ? s.charAt(0) : '')).join('')
        : 'NA';
      return result.length > 0 ? (result.length > 2 ? result.substr(0, 2) : result).toUpperCase() : 'NA';
    },
    htmlText() {
      return _escape(this.text).replace(/(@\w+)/g, '<span style="color: #2196F3;">$1</span>');
    },
    emojiSource() {
      if (!this.emojiName) return '';

      return _get(
        _find(this.emojis, (emoji) => emoji.name === this.emojiName),
        'source',
        '',
      );
    },
  },
  methods: {
    onCommentDeleted() {
      this.$emit('onCommentDeleted');
    },
    onCommentSelectedToReply() {
      this.$emit('onCommentReplied');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/commentsWidget.scss";
</style>
