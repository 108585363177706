<template>
  <div class="page-navigation">
    <div
      v-for="page in viewPages"
      :key="`mob_${page.index}`"
      class="page-navigation__page page-navigation__mobnav"
    >
      <div
        :class="getPageClass(page.index)"
        class="page-navigation__page-item"
        @click="goToPage(page.index)"
      >
        <md-icon
          v-if="isPreviousPage(page.index) && !isFirstPage(page.index)"
          :class="getPageClass(page.index)"
          class="main-theme-icon"
        >
          chevron_left
        </md-icon>
        <md-icon
          v-else-if="isNextPage(page.index) && !isLastPage(page.index)"
          :class="getPageClass(page.index)"
          class="main-theme-icon"
        >
          chevron_right
        </md-icon>
        <md-icon
          v-else-if="page.iconSrc && page.iconSrc.length > 0"
          :class="getPageClass(page.index)"
          :md-src="iconLink(page.iconSrc)"
          class="main-theme-icon"
        />
        <md-icon
          v-else-if="page.icon && page.icon.length > 0"
          :class="getPageClass(page.index)"
          class="main-theme-icon"
        >
          {{ page.icon }}
        </md-icon>
        <template v-else>
          {{ page.caption }}
        </template>
      </div>
    </div>
    <div
      v-for="page in viewPages"
      :key="`web_${page.index}`"
      class="page-navigation__page page-navigation__webnav"
    >
      <div
        v-if="addDots(page.index)"
        class="dots"
      >
        •••
      </div>
      <div
        :class="getPageClass(page.index)"
        class="page-navigation__page-item"
        @click="goToPage(page.index)"
      >
        <md-icon
          v-if="page.iconSrc && page.iconSrc.length > 0"
          :class="getPageClass(page.index)"
          :md-src="iconLink(page.iconSrc)"
          class="main-theme-icon"
        />
        <md-icon
          v-else-if="page.icon && page.icon.length > 0"
          :class="getPageClass(page.index)"
          class="main-theme-icon"
        >
          {{ page.icon }}
        </md-icon>
        <template v-else>
          {{ page.caption }}
        </template>
      </div>
    </div>
    <md-button
      class="md-icon-button md-raised"
      :class="{'page-add__animate': animateNewPage}"
      @click.native="addNewPage()"
    >
      <md-icon class="main-theme-icon">
        note_add
      </md-icon>
      <md-tooltip
        :md-active="showTooltip"
        md-direction="right"
        class="page-add__tooltip"
      >
        <img
          src="~@/assets/icons/arrow_back_white.svg"
          alt="Add page"
        >
        {{ getLocalize('book.createNextPage') }}
      </md-tooltip>
    </md-button>
  </div>
</template>

<script>
/**
 * @emit onPageSelected (pageIndex) - page was selected
 * @emit onAddNewPageClick (pageIndex) - was click on button add page
 */
export default {
  props: {
    pages: {
      type: Array,
      required: true,
    },
    selectedPage: {
      type: Number,
      default: 0,
      required: false,
    },
    selectedStep: {
      type: Number,
      default: 0,
      required: false,
    },
    availableSteps: {
      type: Number,
      default: 0,
      required: false,
    },
    animateNewPage: {
      type: Boolean,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    getLocalize: {
      type: Function,
      default: (key) => key,
      required: false,
    },
  },
  computed: {
    viewPages() {
      return this.pages.filter((page) => this.displayPage(page.index));
    },
  },
  methods: {
    addNewPage() {
      this.$emit('onAddNewPageClick');
    },
    goToPage(index) {
      this.$emit('onPageSelected', index);
    },
    getPageClass(index) {
      return this.isCurrentPage(index) ? 'active-page' : '';
    },
    addDots(index) {
      // display dots only for pages where previous is not visible and not first page
      return this.displayPage(index) && !this.displayPage(index - 1) && !this.isFirstPage(index);
    },
    iconLink(name) {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`@/assets/icons/${name}.svg`);
    },
    isFirstPage(index) {
      return index === 0;
    },
    isLastPage(index) {
      return index === this.pages.length - 1;
    },
    isCurrentPage(index) {
      return index === this.selectedPage;
    },
    isPreviousPage(index) {
      return index === this.selectedPage - 1;
    },
    isNextPage(index) {
      return index === this.selectedPage + 1;
    },
    displayPage(index) {
      return index >= 0
        && (this.isFirstPage(index)
          || this.isLastPage(index)
          || this.isCurrentPage(index)
          || this.isPreviousPage(index)
          || this.isNextPage(index));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/pageNavigation.scss";
</style>
