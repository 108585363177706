<template>
  <div class="cw__header">
    <md-icon class="cw__header-icon">
      comment
    </md-icon>
    <h5 class="cw__header-title">
      {{ getLocalize('commentsWidget.header') }}
    </h5>
  </div>
</template>

<script>
export default {
  name: 'CommentsWidgetHeader',
  props: {
    getLocalize: {
      type: Function,
      default: (key) => key,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/commentsWidget.scss";
</style>
