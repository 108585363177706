import {
  IMAGE_SEARCH_IMAGE_SOURCE_UPDATED,
  IMAGE_SEARCH_LOAD_MORE_IMAGES,
  IMAGE_SEARCH_SEARCHING,
} from '@/store/modules/imageSearch/actionTypes';
import { API } from '@/api/api';
import {
  IMAGE_SEARCH_ADD_IMAGES,
  IMAGE_SEARCH_SET_IMAGES,
  IMAGE_SEARCH_SET_ARE_IMAGES_LOADING,
  IMAGE_SEARCH_SET_SEARCH,
  IMAGE_SEARCH_SET_IMAGE_SOURCE,
} from '@/store/modules/imageSearch/mutationTypes';
import { IMAGE_SOURCE } from '@/helpers/const';

async function loadImages(commit, imageSource, search) {
  commit(IMAGE_SEARCH_SET_ARE_IMAGES_LOADING, {
    imageSource,
    areImagesLoading: true,
  });
  commit(IMAGE_SEARCH_SET_SEARCH, {
    imageSource,
    search,
  });
  const response = await API.MediaService.getImages(search, imageSource);
  if (response.success) {
    commit(IMAGE_SEARCH_SET_IMAGES, {
      imageSource,
      ...response.data,
    });
  }
  commit(IMAGE_SEARCH_SET_ARE_IMAGES_LOADING, {
    imageSource,
    areImagesLoading: false,
  });
}

export default ({
  async [IMAGE_SEARCH_SEARCHING]({ commit, state }, search) {
    const { imageSource } = state;
    if (!state[imageSource].areImagesLoading) {
      await loadImages(commit, imageSource, search);
    }
  },
  async [IMAGE_SEARCH_LOAD_MORE_IMAGES]({ commit, state }) {
    const { imageSource } = state;
    if (!state[imageSource].areImagesLoading
      && (state[imageSource].nextPageToken || !state[imageSource].images.length)) {
      commit(IMAGE_SEARCH_SET_ARE_IMAGES_LOADING, {
        imageSource,
        areImagesLoading: true,
      });
      const response = await API.MediaService.getImages(
        state[imageSource].search,
        imageSource,
        state[imageSource].nextPageToken,
      );
      if (response.success) {
        commit(IMAGE_SEARCH_ADD_IMAGES, {
          imageSource,
          ...response.data,
        });
      }
      commit(IMAGE_SEARCH_SET_ARE_IMAGES_LOADING, {
        imageSource,
        areImagesLoading: false,
      });
    }
  },
  async [IMAGE_SEARCH_IMAGE_SOURCE_UPDATED]({ commit, state }, imageSource) {
    commit(IMAGE_SEARCH_SET_IMAGE_SOURCE, imageSource);

    if ([IMAGE_SOURCE.WORKSPACE, IMAGE_SOURCE.PERSONAL].includes(imageSource)) {
      if (!state[imageSource].areImagesLoading) {
        const { search } = state;
        await loadImages(commit, imageSource, search);
      }
    }
  },
});
