<template>
  <md-dialog
    class="offline-dialog"
    :md-active="showDialog"
  >
    <md-icon class="md-size-4x offline-dialog__icon">
      wifi_off
    </md-icon>
    <div class="offline-dialog__description">
      {{ getLocalize('offlineDialog.description') }}
    </div>
  </md-dialog>
</template>

<script>
export default {
  name: 'OfflineDialog',
  model: {
    prop: 'value',
    emit: 'onColorSelected',
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    getLocalize: {
      type: Function,
      default: (key) => key,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/offlineDialog.scss";
</style>
