<template>
  <img
    class="emoji"
    :src="imageSource"
    alt=""
  >
</template>

<script>
export default {
  name: 'Emoji',
  props: {
    imageSource: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/emoji.scss";
</style>
