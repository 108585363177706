<template>
  <div class="ntf">
    <md-badge
      v-if="events && events.length > 0"
      :md-content="events.length"
    >
      <md-button
        class="md-icon-button md-primary"
        @click.native="toggleNtf"
      >
        <md-icon class="ntf__bell">
          notifications
        </md-icon>
      </md-button>
    </md-badge>
    <div
      v-show="events && events.length > 0 && showNtf"
      class="ntf__container"
    >
      <md-field class="ntf__filter">
        <md-select
          id="event_type"
          v-model="currentType"
        >
          <md-option
            v-for="(type, index) in filterTypes"
            :key="index"
            :value="type"
          >
            {{ getTypeName(type) }}
          </md-option>
        </md-select>
      </md-field>
      <div class="ntf__events">
        <placeholder-message
          v-if="!filteredEvents || filteredEvents.length == 0"
          :icon="'light_mode'"
          :message="getLocalize('placeholderMessage.noNotifications')"
        />
        <event
          v-for="event of filteredEvents"
          :key="event.id"
          :icon="getEventIcon(event)"
          :link="getEventLink(event)"
          :text="getEventText(event)"
          :created="event.created"
          :user="event.details.author"
          :get-localize="getLocalize"
          @onClick="onEventClicked(event)"
        />
      </div>
      <custom-button
        size="small"
        type="white-filled"
        :title="getLocalize('notifications.readAll')"
        class="ntf__action"
        @click.native="readAll"
      />
    </div>
  </div>
</template>

<script>
import _reduce from 'lodash/reduce';
import _union from 'lodash/union';
import Event from '@/components/notifications/Event.vue';
import PlaceholderMessage from '@/components/placeholderMessage/PlaceholderMessage.vue';
import CustomButton from '@/components/buttons/CustomButton.vue';
import { EVET_TYPES } from '@/helpers/const';

/**
 * @emit onEventClicked - event link clicked
 * @emit onReadAll - all event selected to mark as read
 */
export default {
  name: 'Notifications',
  components: {
    CustomButton,
    Event,
    PlaceholderMessage,
  },
  props: {
    text: {
      type: String,
      default: '',
      required: false,
    },
    events: {
      type: Array,
      default: () => [],
      required: false,
    },
    getLocalize: {
      type: Function,
      default: (key) => key,
      required: false,
    },
  },
  data: () => ({
    showNtf: false,
    currentType: '',
  }),
  computed: {
    filterTypes() {
      const types = _reduce(this.events, (result, item) => _union(result, [item.type]), []);
      return ['all', ...types];
    },
    filteredEvents() {
      if (!this.currentType || this.currentType === '' || this.currentType === 'all') {
        return this.events || [];
      }
      return (this.events || []).filter((event) => event.type === this.currentType);
    },
  },
  created() {
    this.currentType = 'all';
  },
  beforeDestroy() {
    this.showNtf = false;
  },
  methods: {
    toggleNtf() {
      this.showNtf = !this.showNtf;
    },
    getEventLink(event) {
      if (!event || !event.type) {
        return '';
      }
      switch (event.type) {
        case EVET_TYPES.NEW_PAGE:
          return this.getLocalize('notifications.newPage');
        case EVET_TYPES.NEW_COMMENT:
          return this.getLocalize('notifications.newComment');
        case EVET_TYPES.PAGE_READY:
          return this.getLocalize('notifications.pageReady');
        case EVET_TYPES.BOOK_READY:
          return this.getLocalize('notifications.bookReady');
        default:
          return '';
      }
    },
    getEventIcon(event) {
      if (!event || !event.type) {
        return undefined;
      }
      switch (event.type) {
        case EVET_TYPES.NEW_PAGE:
        case EVET_TYPES.PAGE_READY:
        case EVET_TYPES.BOOK_READY:
          return 'book';
        case EVET_TYPES.NEW_COMMENT:
          return 'comment';
        default:
          return undefined;
      }
    },
    getEventText(event) {
      if (!event || !event.type) {
        return '';
      }
      switch (event.type) {
        case EVET_TYPES.NEW_PAGE:
          return this.getLocalize('notifications.pageAddedToBook');
        case EVET_TYPES.NEW_COMMENT:
          return this.getLocalize('notifications.commentAddedToBook');
        case EVET_TYPES.PAGE_READY:
          return this.getLocalize('notifications.pageReadyToSolve');
        case EVET_TYPES.BOOK_READY:
          return this.getLocalize('notifications.bookReadyToSolve');
        default:
          return '';
      }
    },
    getTypeName(eventType) {
      if (eventType === 'all') {
        return this.getLocalize('notifications.allType');
      }
      switch (eventType) {
        case EVET_TYPES.NEW_PAGE:
          return this.getLocalize('notifications.newPageType');
        case EVET_TYPES.NEW_COMMENT:
          return this.getLocalize('notifications.newCommentType');
        case EVET_TYPES.PAGE_READY:
          return this.getLocalize('notifications.pageReadyType');
        case EVET_TYPES.BOOK_READY:
          return this.getLocalize('notifications.bookReadyType');
        default:
          return '';
      }
    },
    onEventClicked(event) {
      this.toggleNtf();
      this.$emit('onEventClicked', event);
    },
    readAll() {
      this.toggleNtf();
      this.$emit('onReadAll');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/notifications.scss";
</style>
