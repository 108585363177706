<template>
  <md-snackbar
    class="toast"
    :md-position="position"
    :md-active="showToast"
    :md-duration="maxDisplayToast"
    md-persistent
    @update:mdActive="onUpdateShowToast"
    @md-closed="$emit('onClosed')"
  >
    <span>{{ message }}</span>
    <md-button
      v-if="showCloseButton"
      class="md-icon-button"
      @click="onClose"
    >
      <md-icon class="main-theme-icon">
        close
      </md-icon>
    </md-button>
  </md-snackbar>
</template>

<script>
/**
 * @emit onClose - toast will close
 * @emit onClosed - toast closed
 */
export default {
  name: 'ToastAlert',
  props: {
    showToast: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: 'left',
      validator: (value) => ['left', 'center'].indexOf(value) !== -1,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    getLocalize: {
      type: Function,
      default: (key) => key,
      required: false,
    },
    maxDisplayToast: {
      type: Number,
      default: 10_000,
    },
  },
  methods: {
    onUpdateShowToast(showToast) {
      if (!showToast && this.showToast !== showToast) {
        this.$emit('onClose');
      }
    },
    onClose() {
      this.$emit('onClose');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/toast.scss";
</style>
