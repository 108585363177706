import _invoke from 'lodash/invoke';
import CropArea from '@/api/models/CropArea';
import Comment from '@/api/models/Comment';

const ID = 'id';
const TITLE = 'name';
const AUTHOR = 'authorName';
const BACKGROUND_COLOR = 'coverBackgroundColor';
const BACKGROUND_TINT = 'coverBackgroundTint';
const CREATED_DATE = 'createDate';
const WHITE_PREDEFINED_TINTS = ['#F44336', '#FF66B4', '#9C27B0', '#2196F3', '#353535'];
const IMAGE_SOURCE = 'pictureUrl';
const VISIBLE_AREA = 'visibleArea';
const COMMENTS = 'comments';
const USERID = 'userId';
const USER_FULL_NAME = 'userFullName';
const IS_TEMPLATE = 'isTemplate';
const SOURCE_BOOK_ID = 'sourceBookId';
const PREDEFINED = 'isPredefined';
const CREATE_DATETIME = 'createDate';
const LANGUAGE = 'language';

export default class Book {
  constructor(parameters) {
    const {
      id,
      title,
      author,
      backgroundColor,
      backgroundTint,
      createdDate,
      imageSource,
      visibleArea,
      comments,
      userId,
      userFullName,
      isTemplate,
      sourceBookId,
      predefined,
      createDatetime,
      language,
    } = parameters;
    this.id = id;
    this.title = title;
    this.author = author;
    this.backgroundColor = backgroundColor;
    this.backgroundTint = backgroundTint;
    this.createdDate = createdDate;
    this.imageSource = imageSource;
    this.visibleArea = visibleArea && new CropArea(visibleArea);
    this.comments = comments;
    this.userId = userId;
    this.userFullName = userFullName;
    this.isTemplate = isTemplate;
    this.sourceBookId = sourceBookId;
    this.predefined = predefined;
    this.createDatetime = createDatetime;
    this.language = language;
  }

  static fromApi(object = {}) {
    const getBackgroundTint = function getBackgroundTint() {
      const backgroundColorLowerCase = object[BACKGROUND_COLOR].toLowerCase();
      return WHITE_PREDEFINED_TINTS.some((el) => el.toLowerCase() === backgroundColorLowerCase)
        ? 'rgba(255, 255, 255, .5)'
        : 'rgba(0, 0, 0, .3)';
    };
    const book = new Book(
      {
        id: object[ID],
        title: object[TITLE],
        author: object[AUTHOR],
        backgroundColor: object[BACKGROUND_COLOR],
        createdDate: object[CREATED_DATE],
        backgroundTint: object[BACKGROUND_TINT] ? object[BACKGROUND_TINT] : getBackgroundTint(),
        imageSource: object[IMAGE_SOURCE],
        visibleArea: object[VISIBLE_AREA] && CropArea.fromApi(object[VISIBLE_AREA]),
        comments: _invoke(
          object,
          [COMMENTS, 'map'],
          Comment.fromApi,
        ),
        userId: object[USERID],
        userFullName: object[USER_FULL_NAME],
        isTemplate: object[IS_TEMPLATE],
        predefined: object[PREDEFINED],
        createDatetime: object[CREATE_DATETIME],
        language: object[LANGUAGE],
      },
    );

    if (book.predefined) {
      const bookInspirationBackgroundColor = process.env.VUE_APP_BOOK_INSPIRATION_BACKGROUND_COLOR;
      if (bookInspirationBackgroundColor) {
        book.backgroundColor = bookInspirationBackgroundColor;
      }
    }

    return book;
  }

  toApi() {
    return {
      [ID]: this.id,
      [TITLE]: this.title,
      [AUTHOR]: this.author,
      [BACKGROUND_COLOR]: this.backgroundColor,
      [CREATED_DATE]: this.createdDate,
      [BACKGROUND_TINT]: this.backgroundTint,
      [IMAGE_SOURCE]: this.imageSource,
      [VISIBLE_AREA]: this.visibleArea && this.visibleArea.toApi(),
      [USERID]: this.userId,
      [USER_FULL_NAME]: this.userFullName,
      [IS_TEMPLATE]: this.isTemplate,
      [SOURCE_BOOK_ID]: this.sourceBookId,
      [PREDEFINED]: this.predefined,
      [LANGUAGE]: this.language,
    };
  }
}
