<template>
  <div class="upload-file">
    <ValidationProvider
      ref="formSelectImage"
      v-slot="{ errors }"
      :bails="false"
      :rules="{image: true, size: sizeImageMaxKB, ext: imageExtensions}"
      name="image"
    >
      <custom-button
        :title="uploadFileButtonTitle"
        class="md-raised b-button--filled-white"
        icon="file_upload"
        type="contained"
        @click="onFileSelectShow"
      />
      <p
        v-for="error in errors"
        :key="error"
      >
        {{ error }}
      </p>
      <input
        v-show="false"
        ref="inputFile"
        type="file"
        @change="onFileSelected"
      >
    </ValidationProvider>
  </div>
</template>

<script>
import { extend, ValidationProvider } from 'vee-validate';
import { ext, image, size } from 'vee-validate/dist/rules';
import CustomButton from '@/components/buttons/CustomButton.vue';

/**
 * @emit onFileSelected - image file was selected
 */
export default {
  name: 'UploadFile',
  components: {
    CustomButton,
    ValidationProvider,
  },
  props: {
    sizeImageMaxKB: {
      type: Number,
      default: 1024,
    },
    imageExtensions: {
      type: Array,
      default: () => ['jpeg', 'jpg', 'png'],
    },
    openFileLoadingDialogOnMounted: {
      type: Boolean,
      required: true,
    },
    getLocalize: {
      type: Function,
      default: (key) => key,
      required: false,
    },
  },
  computed: {
    uploadFileButtonTitle() {
      return this.getLocalize('uploadFile.uploadFileButtonTitle');
    },
  },
  created() {
    extend('image', {
      ...image,
      message: this.getLocalize('uploadFile.validationImage'),
    });
    extend('ext', {
      ...ext,
      message: this.getLocalize('uploadFile.validationExt'),
    });
    extend('size', {
      ...size,
      message: this.getLocalize('uploadFile.validationSize'),
    });
  },
  mounted() {
    if (this.openFileLoadingDialogOnMounted) {
      this.onFileSelectShow();
    }
  },
  methods: {
    async onFileSelected(e) {
      const { valid } = await this.$refs.formSelectImage.validate(e);

      if (valid && this.$refs.inputFile.files && this.$refs.inputFile.files.length) {
        this.$emit('onFileSelected', this.$refs.inputFile.files[0]);
      }
    },
    onFileSelectShow() {
      this.$refs.inputFile.click();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/uploadFile.scss";
</style>
