export default {
  computed: {
    currentLanguage() {
      return this.getLocale();
    },
  },
  methods: {
    getLocalize(key) {
      return this.$t(key);
    },
    getLocale() {
      return this.$i18n.locale;
    },
  },
};
