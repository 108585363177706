<template>
  <md-dialog
    :md-active="showDialog"
    class="dialog"
    @update:mdActive="onUpdateShowDialog($event)"
  >
    <md-button
      v-if="showCloseButton"
      class="md-icon-button dialog__close"
      @click="onDialogClosed"
    >
      <md-icon>close</md-icon>
    </md-button>
    <div class="app-dialog">
      <div
        class="app-dialog__content"
        :class="{ 'app-dialog__content_background': contentBackground }"
      >
        <slot />
      </div>
      <slot name="footer" />
      <div
        v-if="showButtons"
        class="app-dialog__buttons"
      >
        <div class="app-dialog__additional_buttons">
          <slot name="buttons" />
        </div>
        <div class="app-dialog__action_buttons">
          <custom-button
            :title="getLocalize('appDialog.cancelButtonTitle')"
            :icon="cancelIcon"
            type="white-filled"
            @click="onDialogClosed"
          />
          <custom-button
            :title="getLocalize('appDialog.saveButtonTitle')"
            :icon="saveIcon"
            type="primary-filled"
            @click="onSave"
          />
        </div>
      </div>
    </div>
  </md-dialog>
</template>

<script>
import CustomButton from '@/components/buttons/CustomButton.vue';

/**
 * @emit onSave - was clicked on save button
 * @emit onDialogClosed - dialog closed
 */
export default {
  name: 'AppDialog',
  components: { CustomButton },
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    contentBackground: {
      type: Boolean,
      default: true,
    },
    saveIcon: {
      type: String,
      default: null,
    },
    cancelIcon: {
      type: String,
      default: null,
    },
    getLocalize: {
      type: Function,
      default: (key) => key,
      required: false,
    },
  },
  methods: {
    onUpdateShowDialog(showDialog) {
      if (!showDialog && this.showDialog !== showDialog) {
        this.$emit('onDialogClosed');
      }
    },
    onSave() {
      this.$emit('onSave');
    },
    onDialogClosed() {
      this.$emit('onDialogClosed');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/appDialog.scss";
</style>
