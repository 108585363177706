import _tail from 'lodash/tail';
import {
  MESSAGES_PUSH_TOAST_MESSAGE,
  MESSAGES_RESET_STATE,
  MESSAGES_SHIFT_TOAST_MESSAGE, MESSAGES_UPDATE_HEADER_MESSAGE,
} from '@/store/modules/messages/mutationTypes';
import getInitialState from '@/store/modules/messages/getInitialState';

export default ({
  [MESSAGES_PUSH_TOAST_MESSAGE](state, message) {
    state.toastMessageQueue = [...state.toastMessageQueue, message];
  },
  [MESSAGES_SHIFT_TOAST_MESSAGE](state) {
    state.toastMessageQueue = _tail(state.toastMessageQueue);
  },
  [MESSAGES_UPDATE_HEADER_MESSAGE](state, message) {
    state.headerMessage = message;
  },
  [MESSAGES_RESET_STATE](state) {
    Object.assign(state, getInitialState());
  },
});
