import request from '@/api/httpClient';
import Notification from '@/api/models/Notification';
import ErrorResponse from '@/api/models/ErrorResponse';

export class NotificationsService {
  static async getNotifications(pageToken) {
    try {
      const token = pageToken ? `paginationToken=${pageToken}` : '';
      const result = await request.getRequest(
        `/Notifications?${token}`,
        {}, {},
      ).then((response) => {
        const notifications = response.data.data.map(Notification.fromApi);
        return { success: true, data: notifications, token: response.data.token };
      }).catch((e) => {
        const error = ErrorResponse.fromApi(e.response.data);
        return { success: false, error };
      });
      return result;
    } catch (e) {
      return { success: false };
    }
  }

  static async markNotificationRead(notificationId) {
    try {
      const notification = new Notification({
        isNew: false,
      });
      const result = await request.putRequest(
        `/Notifications/${notificationId}`,
        notification.toApi(),
        {},
      ).then((response) => {
        const data = Notification.fromApi(response.data);
        return { success: true, data };
      }).catch((e) => {
        const error = ErrorResponse.fromApi(e.response.data);
        return { success: false, error };
      });
      return result;
    } catch (e) {
      return { success: false };
    }
  }
}
