<template>
  <div
    class="wrong-answers"
  >
    <span class="wrong-answers__title">
      {{ getLocalize('editPage.suggestedAnswers') }}
    </span>
    <div class="wrong-answers__wrap">
      <div
        v-for="value in values"
        :key="value"
        class="wrong-answers__item"
        :class="{disabled: readOnly || disabledValues.includes(value)}"
        @click="onWrongAnswersSelected(value)"
      >
        {{ value }}
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @emit onWrongAnswersSelected - wrong answers selected
 */
import _random from 'lodash/random';
import _invoke from 'lodash/invoke';
import _sample from 'lodash/sample';
import _shuffle from 'lodash/shuffle';
import { NUMBER_REGEXP_TEMPLATE_FOR_RANDOM_ANSWER } from '@/helpers/const';
import unitMixin from '@/mixins/unitMixin';

export default {
  name: 'RandomWrongAnswers',
  mixins: [
    unitMixin,
  ],
  props: {
    rightAnswer: {
      type: String,
      default: '0',
      validator(value) {
        return new RegExp(NUMBER_REGEXP_TEMPLATE_FOR_RANDOM_ANSWER).test(value);
      },
    },
    rightAnswerUnit: {
      type: String,
      default: '',
    },
    round: {
      type: Number,
      default: 0.66,
      validator(value) {
        return value >= 0 && value <= 1;
      },
    },
    excludeValues: {
      type: Array,
      default: () => [],
    },
    disabledValues: {
      type: Array,
      default: () => [],
    },
    countValues: {
      type: Number,
      default: 12,
      validator(value) {
        return value >= 0;
      },
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    maxAnswerLength: {
      type: Number,
      default: 25,
    },
    unitGroup: {
      type: Object,
      default: () => {},
    },
    getLocalize: {
      type: Function,
      default: (key) => key,
      required: false,
    },
  },
  data() {
    return {
      numberRegexp: new RegExp(NUMBER_REGEXP_TEMPLATE_FOR_RANDOM_ANSWER),
    };
  },
  computed: {
    unitNames() {
      return _invoke(this, 'unitGroup.units.map', (i) => i.name) || [];
    },
    rightAnswerNumber() {
      return this.getAnswerNumberOrOne(this.rightAnswer);
    },
    countDigits() {
      const [, second] = this.rightAnswer.split(/[,.]/);
      const digits = second && second.length;
      return Math.min(+digits, 3);
    },
    otherUnit() {
      const rightAnswerUnitLowerCase = this.rightAnswerUnit.toLowerCase();
      return this.unitNames.filter((u) => u.toLowerCase() !== rightAnswerUnitLowerCase);
    },
    addUnit() {
      return !!this.unitNames.length;
    },
    roundTotal() {
      return Math.trunc(this.countValues * this.round);
    },
    roundValues() {
      const numbers = [];
      let x = 0;

      if (this.rightAnswerNumber > Number.MAX_SAFE_INTEGER
        || this.rightAnswerNumber < Number.MIN_SAFE_INTEGER) {
        return numbers;
      }

      while (numbers.length < this.roundTotal) {
        x += 1;
        const newNumbers = [];

        const newNumForward = (this.rightAnswerNumber + x).toFixed(this.countDigits);
        const newNumForwardUnit = this.addUnit ? `${newNumForward} ${this.rightAnswerUnit}` : newNumForward.toString();
        if (newNumForward < 999 && !numbers.includes(newNumForwardUnit)) {
          newNumbers.push(newNumForward);
          numbers.push(newNumForwardUnit);
        }
        const newNumBack = (this.rightAnswerNumber - x).toFixed(this.countDigits);
        const newNumBackUnit = this.addUnit ? `${newNumBack} ${this.rightAnswerUnit}` : newNumBack.toString();
        if (newNumBack >= 0 && !numbers.includes(newNumBackUnit)) {
          newNumbers.push(newNumBack);
          numbers.push(newNumBackUnit);
        }

        if (this.addUnit && this.unitNames.length > 1) {
          numbers.push(`${_sample(newNumbers)} ${_sample(this.otherUnit)}`);

          const unit = _sample(this.otherUnit);
          const number = this.convertAnswerNumber(_sample(newNumbers), this.rightAnswerUnit, unit);
          const answer = `${number.toFixed(this.countDigits)} ${unit}`;
          if (!numbers.includes(answer)) {
            numbers.push(answer);
          }
        }
      }
      return numbers;
    },
    values() {
      const numbers = [...this.roundValues];
      while (numbers.length < this.countValues) {
        const random = _random(0, this.deviation * this.rightAnswerNumber)
          .toFixed(this.countDigits);
        const answer = this.addUnit ? `${random} ${_sample(this.unitNames)}` : random.toString();
        if (answer.length <= this.maxAnswerLength
          && !this.excludeValues.concat(numbers).includes(answer)) {
          numbers.push(answer);
        }
      }
      return _shuffle(numbers);
    },
    deviation() {
      let base = this.rightAnswerNumber;
      let deviation = 1.5;

      if (base === 0) base = 1;
      if (base < 3) {
        deviation = base * this.countValues;
      } else if (base >= 3 && base < 5) {
        deviation = 12;
      } else if (base >= 5 && base < 7) {
        deviation = 7;
      } else if (base >= 7 && base < 10) {
        deviation = 5;
      } else if (base >= 10 && base < 20) {
        deviation = 4;
      } else if (base >= 20 && base < 30) {
        deviation = 3;
      } else if (base >= 30) {
        deviation = 2;
      }
      return deviation;
    },
  },
  methods: {
    convertAnswerNumber(number, fromUnitName, toUnitName) {
      const fromCoefficient = this.findUnitByName(fromUnitName).coefficient;
      const toCoefficient = this.findUnitByName(toUnitName).coefficient;

      // eslint-disable-next-line no-mixed-operators
      return number / fromCoefficient * toCoefficient;
    },
    onWrongAnswersSelected(answer) {
      if (!this.readOnly && !this.disabledValues.includes(answer)) {
        const [number, unit] = answer.split(' ');
        this.$emit('onWrongAnswersSelected', {
          number,
          unit,
        });
      }
    },
    findUnitByName(unitName) {
      const unitNameLowerCase = unitName.toLowerCase();
      return this.unitGroup.units.find((i) => i.name.toLowerCase() === unitNameLowerCase);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/randomWrongAnswers.scss";
</style>
