<template>
  <div
    v-if="isPagesLoaded && isBookLoaded"
    class="edit-book"
  >
    <div class="edit-book__header">
      <div class="header__group start">
        <custom-button
          :icon-src="'bookshelf_icon'"
          :title="getLocalize('buttonBookShelves.btnGoWorkspace')"
          type="white-filled"
          @click="openBookShelf()"
        />
      </div>
      <div class="header__group center">
        <page-navigation
          :pages="pagesNavigationInfo"
          :selected-page="currentPageNumber"
          :selected-step="currentStepNumber-1"
          :available-steps="getPageMaxStepByNumber(currentPageNumber)-1"
          :get-localize="getLocalize"
          :animate-new-page="showAddPageTooltip"
          :show-tooltip="showAddPageTooltip"
          @onPageSelected="onPageSelected"
          @onAddNewPageClick="addNewPage"
        />
      </div>
      <div class="header__group end">
        <div class="edit-book__header-right">
          <templateSwitch
            v-if="showTemplateSwitch"
            class="edit-book__template_switch"
            :title="getLocalize('buttonBookShelves.templateSwitch')"
            :value="book.isTemplate"
            :read-only="isTemplateDisabled"
            @change="onIsTemplateUpdated"
          />
          <custom-button
            :icon="'menu_book'"
            :title="getLocalize('buttonSolveBook.btnGoSolveBook')"
            type="primary-filled"
            @click="openBookSolveMode()"
          />
        </div>
      </div>
    </div>
    <div class="edit-book__content">
      <div
        class="page-leaf__with-delete"
      >
        <div />
        <md-button
          class="md-icon-button md-raised center solve-book__nav-button"
          :class="{ hidden: currentPageNumber <= 0 }"
          @click="leafPageBack()"
        >
          <md-icon class="main-theme-icon">
            chevron_left
          </md-icon>
        </md-button>
        <md-button
          class="md-icon-button md-raised center md-accent"
          :class="{ hidden: hiddenDeleteButton }"
          @click="confirmAndDelete"
        >
          <md-icon>
            delete
          </md-icon>
        </md-button>
      </div>
      <front-page
        v-if="currentPageNumber === 0"
        ref="frontPageRef"
        :author="book.author"
        :background-color="book.backgroundColor"
        :background-tint="book.backgroundTint"
        :colors-sets="colorsSets"
        :get-localize="getLocalize"
        :image-bank-source="imageBankSource"
        :image-source="book.imageSource"
        :title="book.title"
        :upload-image-extensions="uploadImageExtensions"
        :upload-size-image-max-k-b="uploadSizeImageMaxKB"
        :show-image-search-tab="showImageSearchTab"
        :show-camera-tab="showCameraTab"
        :show-upload-file-tab="showUploadFileTab"
        :show-user-image-tab="showUserImageTab"
        :show-workspace-image-tab="showWorkspaceImageTab"
        :found-images="foundImages"
        :loaded-image-source="frontPageLoadedImageSource"
        :image-search-provider-name="imageSearchProviderName"
        :image-search-provider-link="imageSearchProviderLink"
        :crop-area="book.visibleArea"
        :search-string="searchString"
        :active-file-dialog-tab="activeFileDialogTab"
        :image-bank-tab-id-chain="imageBankTabIdChain"
        @onAuthorUpdated="onAuthorUpdated"
        @onImageFileUpdated="onFrontPageImageFileUpdated"
        @onImageSourceUpdated="onFrontImageSourceUpdated"
        @onTitleUpdated="onTitleUpdated"
        @onValidUpdated="onValidUpdated"
        @onImageSearch="onImageSearch"
        @onLoadMoreSearchImages="onLoadMoreSearchImages"
        @onImageSearchSourceUpdated="onImageSearchSourceUpdated"
        @onCropAreaUpdated="onFrontPageCropAreaUpdated"
        @onColorDialogShow="onColorDialogShow"
        @onActiveFileDialogTabUpdated="onActiveFileDialogTabUpdated"
        @onImageBankTabIdChainUpdated="onImageBankTabIdChainUpdated"
      />
      <template v-else-if="currentPage">
        <create-page
          ref="createPageRef"
          :key="'edit' + currentPage.number"
          :current-step-number="currentStepNumber"
          :page="currentPage"
          :creation-completed="pageCreationCompleted"
          :loaded-image-source="pageLoadedImageSource"
          :marks="currentPage.marks"
          :custom-marks="currentPage.customMarkNames"
          :coefficient-mark-size="coefficientMarkSize"
          :total-steps="5"
          :selected-step="currentStepNumber-1"
          :available-steps="getPageMaxStepByNumber(currentPageNumber)-1"
          :is-disabled-recorder="isDisabledRecorder"
          @onStepCompleted="onStepCreationCompleted"
          @onImageFileUpdated="onPageImageFileUpdated"
          @onImageSearch="onImageSearch"
          @onLoadMoreSearchImages="onLoadMoreSearchImages"
          @onImageSearchSourceUpdated="onImageSearchSourceUpdated"
          @onCropAreaUpdated="onPageCropAreaUpdated"
          @onMarksUpdated="onPageMarksUpdated"
          @onCustomMarkUpdated="onPageCustomMarkUpdated"
          @onAudioFileUpdated="onAudioFileUpdated"
          @onAudioFileDeleted="onAudioFileDeleted"
          @onStepSelected="onStepSelected"
          @onRightAnswersUpdated="onRightAnswersUpdated"
          @onRightAnswerUnitsUpdated="onRightAnswerUnitsUpdated"
          @onWrongAnswersUpdated="onWrongAnswersUpdated"
          @onWrongAnswerUnitsUpdated="onWrongAnswerUnitsUpdated"
          @onAddNewPageClick="addNewPage"
        />
        <book-page
          :key="'view' + currentPage.number"
          :page-hint="currentPage.promptAnswer"
          :page-number="currentPage.number + 1"
          :page-text="currentPage.exerciseText"
          :right-answers="currentPage.rightAnswers"
          :show-page-image="currentStepNumber > 1"
          :show-page-text="currentStepNumber > 2"
          :show-right-answers="currentStepNumber > 3"
          :show-wrong-answers="currentStepNumber > 4"
          :show-page-hint="displayHint"
          :wrong-answers="currentPage.wrongAnswers"
          :page-image-source="currentPage.imageSource"
          :page-area="currentPage.visibleArea"
          :page-text-audio-source="currentPage.audioSource"
          :marks="currentPage.marks"
          :coefficient-mark-size="coefficientMarkSize"
          :student-source="studentSource(currentIndexPage)"
          view-only
          :show-mark-counter="!!currentPage.marks.length"
          @onPageTextToSpeech="onPageTextToSpeech"
          @onPageHintToSpeech="onPageHintToSpeech"
        />
      </template>
      <div
        class="page-leaf"
        :class="{ hidden: currentPageNumber >= pagesCount }"
      >
        <md-button
          class="md-icon-button md-raised center solve-book__nav-button"
          @click="leafPageForward()"
        >
          <md-icon class="main-theme-icon">
            chevron_right
          </md-icon>
        </md-button>
      </div>
      <div class="edit-book__mobnav">
        <md-button
          class="md-icon-button md-raised center md-accent button-delete"
          :class="{ hidden: hiddenDeleteButton }"
          @click="confirmAndDelete"
        >
          <md-icon>
            delete
          </md-icon>
        </md-button>
        <div>
          <md-button
            class="md-icon-button md-raised center solve-book__nav-button"
            :class="{ hidden: currentPageNumber <= 0 }"
            @click="leafPageBack()"
          >
            <md-icon class="main-theme-icon">
              chevron_left
            </md-icon>
          </md-button>
          <md-button
            class="md-icon-button md-raised center solve-book__nav-button"
            :class="{ hidden: currentPageNumber >= pagesCount }"
            @click="leafPageForward()"
          >
            <md-icon class="main-theme-icon">
              chevron_right
            </md-icon>
          </md-button>
        </div>
        <md-button
          class="md-icon-button md-raised center md-accent button-delete"
          :class="{ hidden: true }"
        >
          <md-icon>
            delete
          </md-icon>
        </md-button>
      </div>
    </div>
    <commentsWidget
      v-if="smileCommentsAvailable || textCommentsAvailable"
      ref="commentsWidget"
      :comments="pageComments"
      :get-localize="getLocalize"
      :max-comment-length="maxCommentLength"
      :smile-enabled="smileCommentsAvailable"
      :text-enabled="textCommentsAvailable"
      :user-id="sessionUserId"
      :allow-delete-any="allowDeleteAnyComments"
      allow-delete-my-self
      :allow-reply-other="allowReplyOnComment"
      :show-c-w="showCW"
      :emojis="emojis"
      @onCommentDeleted="onCommentDeleted"
      @onCommentTextAdded="onCommentTextAdded"
      @onCommentEmojiAdded="onCommentEmojiAdded"
      @onShowCWUpdated="showCWUpdated"
    />
    <color-dialog
      :colors-sets="colorsSets"
      :get-localize="getLocalize"
      :show-dialog="showColorDialog"
      :value="book && book.backgroundColor || ''"
      @onColorSelected="onBackgroundColorUpdated($event)"
      @onDialogClosed="onColorDialogClosed"
    />
    <confirm-dialog
      :show-dialog="showDeleteBookConfirm"
      :title-text="getLocalize('editPage.deleteBookConfirmTitle')"
      :content-text="getLocalize('editPage.deleteBookConfirmText')"
      :confirm-button-text="getLocalize('editPage.actionConfirmTitle')"
      :cancel-button-text="getLocalize('editPage.actionCancelTitle')"
      cancel-icon="close"
      confirm-icon="delete"
      @onConfirm="showDeleteBookConfirm = false; deleteBook();"
      @onCancel="showDeleteBookConfirm = false"
    />
    <confirm-dialog
      :show-dialog="showDeletePageConfirm"
      :title-text="getLocalize('editPage.deletePageConfirmTitle')"
      :content-text="getLocalize('editPage.deletePageConfirmText')"
      :confirm-button-text="getLocalize('editPage.actionConfirmTitle')"
      :cancel-button-text="getLocalize('editPage.actionCancelTitle')"
      cancel-icon="close"
      confirm-icon="delete"
      @onConfirm="showDeletePageConfirm = false; deletePage();"
      @onCancel="showDeletePageConfirm = false"
    />
  </div>
</template>

<script>
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _debounce from 'lodash/debounce';
import _filter from 'lodash/filter';
import _invoke from 'lodash/invoke';
import _trim from 'lodash/trim';
import _omit from 'lodash/omit';
import _defaults from 'lodash/defaults';
import _max from 'lodash/max';
import Page from '@/api/models/Page';
import { APP_PAGES, STATUS_CODE, USER_ROLE } from '@/helpers/const';
import { API } from '@/api/api';
import PageNavigation from '@/components/navigation/PageNavigation.vue';
import CustomButton from '@/components/buttons/CustomButton.vue';
import BookPage from '@/components/bookPage/BookPage.vue';
import FrontPage from '@/components/frontPage/FrontPage.vue';
import Book from '@/api/models/Book';
import CreatePage from '@/components/createPage/CreatePage.vue';
import CropArea from '@/api/models/CropArea';
import TemplateSwitch from '@/components/templateSwitch/TemplateSwitch.vue';
import MarkCollection from '@/api/models/MarkCollection';
import CommentsWidget from '@/components/commentsWidget/CW.vue';
import toastMixin from '@/mixins/toastMixin';
import studentSource from '@/mixins/studentSource';
import getPageMaxStep from '@/mixins/getPageMaxStep';
import localizationMixin from '@/mixins/localizationMixin';
import fileDialogDataMixin from '@/mixins/fileDialogDataMixin';
import commentsWidgetMixin from '@/mixins/commentsWidgetMixin';
import sessionMixin from '@/mixins/sessionMixin';
import emojiSourceMixin from '@/mixins/emojiSourceMixin';
import ConfirmDialog from '@/components/confirmDialog/ConfirmDialog.vue';
import textToSpeechMixin from '@/mixins/textToSpeechMixin';
import { getTextLengthFromHtml } from '@/helpers/functions';
import unitMixin from '@/mixins/unitMixin';
import ColorDialog from '@/components/colorDialog/ColorDialog.vue';
import getBackgroundTintMixin from '@/mixins/getBackgroundTintMixin';
import { MESSAGES_SET_HEADER_MESSAGE } from '@/store/modules/messages/actionTypes';

export default {
  name: 'EditBook',
  components: {
    ColorDialog,
    ConfirmDialog,
    PageNavigation,
    CustomButton,
    BookPage,
    FrontPage,
    CreatePage,
    TemplateSwitch,
    CommentsWidget,
  },
  mixins: [
    localizationMixin,
    fileDialogDataMixin,
    toastMixin,
    studentSource,
    getPageMaxStep,
    commentsWidgetMixin,
    sessionMixin,
    emojiSourceMixin,
    textToSpeechMixin,
    unitMixin,
    getBackgroundTintMixin,
  ],
  data: () => ({
    pages: null,
    book: null,
    isPagesLoaded: false,
    isBookLoaded: false,
    maxPageSteps: 5,
    frontPageLoadedImageSource: null,
    pageLoadedImageSource: null,
    colorsSets: JSON.parse(process.env.VUE_APP_FRONT_PAGE_COLORS),
    validFrontPage: true,
    pageCreationCompleted: false,
    coefficientMarkSize: Number.parseFloat(process.env.VUE_APP_COEFFICIENT_MARK_SIZE),
    isTemplateDisabled: false,
    showDeleteBookConfirm: false,
    showDeletePageConfirm: false,
    showColorDialog: false,
    savingFrontPage: false,
    isDisabledRecorder: false,
  }),
  computed: {
    headerMessage() {
      if (this.book && this.book.userFullName && this.book.title) {
        return `${this.book.userFullName} / ${this.book.title}`;
      }
      if (this.book && this.book.userFullName) {
        return this.book.userFullName;
      }
      if (this.book && this.book.title) {
        return this.book.title;
      }
      return '';
    },
    isDeleteAllowed() {
      return this.sessionRole === USER_ROLE.TEACHER || _get(this.book, 'userId') === this.sessionUserId;
    },
    displayHint() {
      return !!_invoke(this.currentPage, 'promptAnswer.trim')
        && (this.currentStepNumber > 5 || this.pageCreationCompleted);
    },
    showAddPageTooltip() {
      return !!(this.currentPageNumber === 0
        && this.pagesCount === 0
        && this.book.author
        && this.book.title
        && this.book.imageSource);
    },
    showTemplateSwitch() {
      return this.sessionRole === USER_ROLE.TEACHER || this.sessionRole === USER_ROLE.ADMIN;
    },
    page() {
      return this.currentPage;
    },
    isViewFrontPage() {
      return this.currentPageNumber === 0;
    },
    pagesNavigationInfo() {
      return [
        {
          iconSrc: 'front_page_icon', caption: '', index: 0,
        },
        ...(this.pages ? this.pages.map((page, index) => ({
          icon: '', caption: index + 1, index: index + 1,
        })) : []),
      ];
    },
    bookId() {
      return +this.$route.params.bookId;
    },
    currentPageNumber() {
      if (this.$route.name === APP_PAGES.CREATE_BOOK
        || this.$route.name === APP_PAGES.EDIT_FRONT_PAGE) {
        return 0;
      }
      if (this.$route.name === APP_PAGES.EDIT_PAGES_BY_ID) {
        const { pageId } = this.$route.params;
        const page = this.pages.find((p) => +p.id === +pageId);
        return page ? page.number + 1 : 0;
      }

      return +this.$route.params.pageNumber || 0;
    },
    currentIndexPage() {
      return Math.max(0, this.currentPageNumber - 1);
    },
    currentStepNumber() {
      return +this.$route.params.step || 1;
    },
    currentPage() {
      return this.getPage(this.currentPageNumber);
    },
    pagesCount() {
      return this.pages ? this.pages.length : 0;
    },
    hiddenDeleteButton() {
      return !this.isDeleteAllowed;
    },
    unitGroups() {
      return this.getLocalize('editPage.units') || [];
    },
  },
  watch: {
    bookId: {
      handler(newValue, oldValue) {
        if ((newValue && newValue !== oldValue) || !this.book) {
          this.debouncedLoadBookAndPages(newValue);
        }
      },
      immediate: true,
    },
    headerMessage(value) {
      this.$store.dispatch(MESSAGES_SET_HEADER_MESSAGE, value);
    },
  },
  mounted() {
    this.$store.dispatch(MESSAGES_SET_HEADER_MESSAGE, this.headerMessage);
  },
  beforeDestroy() {
    this.$store.dispatch(MESSAGES_SET_HEADER_MESSAGE, '');
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === APP_PAGES.EDIT_FRONT_PAGE || !this.isBookLoaded || !this.isPagesLoaded) {
      next();
    } else if (to.name === APP_PAGES.EDIT_PAGES) {
      if (+to.params.pageNumber > this.pages.length) {
        next(false);
      } else if (+to.params.step > this.getPageMaxStepByNumber(+to.params.pageNumber)
        || +to.params.step < 1) {
        next(false);
      } else {
        next();
      }
    } else if (to.name === APP_PAGES.EDIT_PAGES_BY_ID) {
      const page = this.pages.find((p) => +p.id === +to.params.pageId);
      if (!page) {
        next(false);
      } else if (+to.params.step > this.getPageMaxStep(page)
        || +to.params.step < 1) {
        next(false);
      } else {
        next();
      }
    } else {
      next();
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (+to.params.bookId !== +from.params.bookId
      || !this.isBookLoaded
      || !this.isPagesLoaded
      || to.name === APP_PAGES.EDIT_FRONT_PAGE) {
      next();
    } else if (to.name === APP_PAGES.EDIT_PAGES && +to.params.pageNumber > this.pages.length) {
      next({
        name: APP_PAGES.EDIT_FRONT_PAGE,
        params: { bookId: this.bookId },
        replace: true,
      });
    } else if (to.name === APP_PAGES.EDIT_PAGES_BY_ID
      && this.pages.every((p) => +p.id !== +to.params.pageId)) {
      next({
        name: APP_PAGES.EDIT_FRONT_PAGE,
        params: { bookId: this.bookId },
        replace: true,
      });
    } else if (+to.params.step < 1) {
      next({
        name: APP_PAGES.EDIT_PAGES,
        params: { bookId: this.bookId, pageNumber: this.currentPageNumber, step: 1 },
        replace: true,
      });
    } else if (+to.params.step > this.getPageMaxStepByNumber(+to.params.pageNumber)) {
      const step = this.getPageMaxStepByNumber(+to.params.pageNumber);
      next({
        name: APP_PAGES.EDIT_PAGES,
        params: { bookId: this.bookId, pageNumber: this.currentPageNumber, step },
        replace: true,
      });
    } else {
      next();
    }
  },
  methods: {
    getPage(number) {
      return this.pages && this.pages.length >= number
        ? this.pages[number - 1] : null;
    },
    getPageMaxStepByNumber(number) {
      const page = this.getPage(number);
      return page ? this.getPageMaxStep(page) : 1;
    },
    onPageSelected(pageIndex) {
      if (pageIndex !== this.currentPageNumber) {
        const getMaxStep = () => this.getPageMaxStepByNumber(pageIndex);
        const step = _get(this.getPage(pageIndex), 'currentEditStep', getMaxStep());
        this.moveToPageStepToast(pageIndex, step, {
          doNotValidate: true,
          checkDisplayCompletePageScreen: true,
        });
      }
    },
    onStepSelected(stepIndex) {
      const pageIndex = this.currentPageNumber;
      const maxStep = this.getPageMaxStepByNumber(pageIndex);
      const step = Math.min(stepIndex + 1, maxStep);
      if (this.currentStepNumber === this.maxPageSteps && step === this.maxPageSteps) {
        this.pageCreationCompleted = false;
      } else if (step !== this.currentStepNumber) {
        this.moveToPageStepToast(pageIndex, step);
      }
    },
    onColorDialogShow() {
      this.showColorDialog = true;
    },
    onColorDialogClosed() {
      this.showColorDialog = false;
    },
    async addNewPage() {
      const onDone = () => {
        const newPageNumber = this.currentPageNumber;
        this.pages.splice(newPageNumber, 0, new Page({
          number: newPageNumber,
        }));

        this.pages = this.pages.map((page, index) => {
          if (index > newPageNumber) {
            return new Page({
              ...page,
              number: page.number + 1,
            });
          }
          return page;
        });

        this.moveToPageStepToast(newPageNumber + 1, 1, {
          doNotValidate: true,
        });
      };
      if (this.currentPageNumber === 0) {
        const frontPageValid = await this.$refs.frontPageRef.validate();
        if (!frontPageValid) {
          return;
        }
        await this.saveFrontPage(onDone);
      } else {
        onDone();
      }
    },
    leafPageBack({
      doNotValidate = true,
      checkDisplayCompletePageScreen = true,
      notSave = false,
    } = {}) {
      const pageIndex = this.currentPageNumber - 1;
      const getMaxStep = () => this.getPageMaxStepByNumber(pageIndex);
      const step = _get(this.getPage(pageIndex), 'currentEditStep', getMaxStep());
      this.moveToPageStepToast(pageIndex, step, {
        doNotValidate,
        checkDisplayCompletePageScreen,
        notSave,
      });
    },
    leafPageForward({
      doNotValidate = true,
      checkDisplayCompletePageScreen = true,
      notSave = false,
    } = {}) {
      const pageIndex = this.currentPageNumber + 1;
      const getMaxStep = () => this.getPageMaxStepByNumber(pageIndex);
      const step = _get(this.getPage(pageIndex), 'currentEditStep', getMaxStep());
      this.moveToPageStepToast(pageIndex, step, {
        doNotValidate,
        checkDisplayCompletePageScreen,
        notSave,
      });
    },
    moveToPageStepToast(page, step, options) {
      this.toggleToast(this.getLocalize('toast.loading'), this.moveToPageStep(page, step, options));
    },
    async moveToPageStep(
      page,
      step,
      { doNotValidate, checkDisplayCompletePageScreen, notSave } = {},
    ) {
      if (!notSave && this.currentPageNumber === 0) {
        await this.saveFrontPage();
      }
      if (page < 0 || page > this.pagesCount || step < 0 || step > this.maxPageSteps) {
        return;
      }
      // we can go to step and page in such ways:
      // - from front page
      // - back to previous steps
      // - in case current step valid
      if (doNotValidate || this.currentPageNumber === 0 || this.currentStepNumber > step
        || await this.$refs.createPageRef.validateCurrentStep()) {
        if (!notSave && this.currentPageNumber !== 0) {
          await this.saveCurrentPage();
        }
        if (checkDisplayCompletePageScreen) {
          this.pageCreationCompleted = step === this.maxPageSteps
            && !!getTextLengthFromHtml(_get(this.getPage(page), 'promptAnswer'));
        } else {
          this.pageCreationCompleted = false;
        }

        if (page === 0) {
          await this.$router.push({
            name: APP_PAGES.EDIT_FRONT_PAGE,
            params: { bookId: this.book.id },
            query: { comments: this.showCW },
          });
        } else {
          await this.$router.push({
            name: APP_PAGES.EDIT_PAGES,
            params: { bookId: this.book.id, pageNumber: page, step },
            query: { comments: this.showCW },
          });
        }
      }
    },
    debouncedSaveFrontPage: _debounce(async function save(onSuccess) {
      if (this.savingFrontPage) {
        this.debouncedSaveFrontPage();
      } else {
        this.savingFrontPage = true;
        await this.saveFrontPage(onSuccess);
        this.savingFrontPage = false;
      }
    }, 500),
    async saveFrontPage(onSuccess) {
      if (!this.validFrontPage) return;

      const result = (this.book.id) ? await this.updateFrontPage() : await this.createFrontPage();
      if (onSuccess && result) {
        onSuccess(result);
      }
    },
    getUnitGroup(rightAnswer) {
      return this.unitGroups.find((g) => this.testExistUnits(
        rightAnswer,
        this.getUnitGroupNames(g),
      ));
    },
    checkResetWrongAnswer(rightAnswer, rightUnit, wrongAnswer, wrongUnit) {
      const right = _trim(rightAnswer);
      const wrong = _trim(wrongAnswer);

      if (right === wrong) return true;

      if (rightUnit
        && wrongUnit
        && this.checkAnswerOnlyNumber(rightAnswer)
        && this.checkAnswerOnlyNumber(wrongAnswer)) {
        const unitGroup = this.getUnitGroup(rightUnit);
        if (!unitGroup) return false;

        const number = this.convertAnswerNumber(rightAnswer, rightUnit, wrongUnit, unitGroup);
        if (new RegExp(`^${number}\\s*${wrongUnit}$`).test(`${wrongAnswer} ${wrongUnit}`)) {
          return true;
        }
      }

      return false;
    },
    async saveCurrentPage() {
      let requestTask;
      const pageNumber = this.currentPageNumber;

      const { currentPage } = this;
      if (currentPage.rightAnswer) {
        if (this.checkResetWrongAnswer(
          currentPage.rightAnswer,
          currentPage.rightAnswerUnit,
          currentPage.firstWrongAnswer,
          currentPage.firstWrongAnswerUnit,
        )) {
          currentPage.firstWrongAnswer = '';
          currentPage.firstWrongAnswerUnit = '';
        }
        if (this.checkResetWrongAnswer(
          currentPage.rightAnswer,
          currentPage.rightAnswerUnit,
          currentPage.secondWrongAnswer,
          currentPage.secondWrongAnswerUnit,
        )) {
          currentPage.secondWrongAnswer = '';
          currentPage.secondWrongAnswerUnit = '';
        }
        if (this.checkResetWrongAnswer(
          currentPage.rightAnswer,
          currentPage.rightAnswerUnit,
          currentPage.thirdWrongAnswer,
          currentPage.thirdWrongAnswerUnit,
        )) {
          currentPage.thirdWrongAnswer = '';
          currentPage.thirdWrongAnswerUnit = '';
        }
      }

      if (this.currentPage.id) {
        requestTask = API.PagesService
          .updatePage(this.bookId, this.currentPage.id, currentPage);
      } else {
        requestTask = API.PagesService.createPage(this.bookId, currentPage);
      }

      const [response] = await this.toggleToast(this.getLocalize('editPage.savePage'), requestTask);
      if (response.success && this.pages && this.pages.length >= this.currentPageNumber) {
        this.pages[pageNumber - 1] = response.data;
        this.pages = [...this.pages];
      } else {
        await this.displayToast(this.getLocalize('editPage.savePageError'));
      }
    },
    debouncedLoadBookAndPages: _debounce(async function debounce(bookId) {
      if (bookId) {
        if (!this.book || +bookId !== this.book.id) {
          this.isBookLoaded = false;
          this.isPagesLoaded = false;
          const loadBookTask = this.loadBook(bookId);
          const loadPagesTask = this.loadPages(bookId);
          const [loadBookSuccess, loadPageSuccess] = await this.toggleToast(
            this.getLocalize('toast.loading'),
            loadBookTask,
            loadPagesTask,
          );

          if (!loadBookSuccess || !loadPageSuccess) {
            this.openBookShelf();
            await this.displayToast(this.getLocalize('errors.loadWrong'));
          } else if (this.$route.name === APP_PAGES.EDIT_PAGES_BY_ID
            && this.pages.every((p) => +p.id !== +this.$route.params.pageId)) {
            await this.$router.replace({
              name: APP_PAGES.EDIT_FRONT_PAGE,
              params: { bookId: this.bookId },
            });
          } else if (this.$route.name === APP_PAGES.EDIT_PAGES) {
            const { pageNumber } = this.$route.params;
            if (this.pages.length < pageNumber) {
              await this.$router.replace({
                name: APP_PAGES.EDIT_FRONT_PAGE,
                params: { bookId: this.bookId },
              });
            }

            if (this.currentStepNumber > this.getPageMaxStep(this.currentPage)) {
              await this.$router.replace({
                name: APP_PAGES.EDIT_PAGES,
                params: {
                  bookId: this.book.id,
                  pageNumber: this.currentPageNumber,
                  step: this.getPageMaxStep(this.currentPage),
                },
                query: { comments: this.showCW },
              });
            } else if (this.currentStepNumber === this.maxPageSteps) {
              this.pageCreationCompleted = !!getTextLengthFromHtml(this.currentPage.promptAnswer);
            } else if (this.currentStepNumber < 1) {
              await this.$router.replace({
                name: APP_PAGES.EDIT_PAGES,
                params: {
                  bookId: this.book.id,
                  pageNumber: this.currentPageNumber,
                  step: 1,
                },
                query: { comments: this.showCW },
              });
            }
          }
        }
      } else {
        this.book = new Book({
          title: '',
          author: '',
          userId: this.sessionUserId,
          backgroundColor: '#FFFFFF',
          backgroundTint: 'rgba(0, 0, 0, .5)',
          visibleArea: null,
        });
        this.pages = [];
        this.isBookLoaded = true;
        this.isPagesLoaded = true;
      }
    }, 200),
    async loadPages(bookId) {
      const responsePages = await API.PagesService.getPages(bookId, {
        includeMarks: true,
        includeComments: true,
        includeAttempts: false,
      });

      if (responsePages.success) {
        this.isPagesLoaded = true;

        this.pages = responsePages.data;
      }

      return responsePages.success;
    },
    async loadBook(bookId) {
      const responseBook = await API.BooksService.getBook(bookId);

      if (responseBook.success) {
        this.isBookLoaded = true;

        this.book = responseBook.data;
      }

      return responseBook.success;
    },
    openBookSolveMode() {
      if (this.isViewFrontPage) {
        this.$router.push({
          name: APP_PAGES.SOLVE_FRONT_PAGE,
          params: { bookId: this.bookId },
          query: { comments: this.showCW },
        });
      } else {
        this.$router.push({
          name: APP_PAGES.SOLVE_BOOK,
          params: { bookId: this.bookId, pageNumber: this.currentPageNumber },
          query: { comments: this.showCW },
        });
      }
    },
    openBookShelf() {
      this.$router.push({ name: APP_PAGES.WORKSPACE });
    },
    onTitleUpdated(title) {
      this.book.title = title;
      this.debouncedSaveFrontPage();
    },
    onAuthorUpdated(author) {
      this.book.author = author;
      this.debouncedSaveFrontPage();
    },
    onBackgroundColorUpdated(color) {
      const backgroundTint = this.getBackgroundTint(this.colorsSets, color);

      this.book.backgroundColor = color;
      this.book.backgroundTint = backgroundTint;
      this.debouncedSaveFrontPage();
    },
    async onIsTemplateUpdated(isTemplate) {
      this.book.isTemplate = isTemplate;
      this.isTemplateDisabled = true;
      await this.saveFrontPage();
      this.isTemplateDisabled = false;
    },
    onValidUpdated(valid) {
      this.validFrontPage = valid;
    },
    async uploadImage(file) {
      const uploadImageTask = API.MediaService.uploadImage(file);
      const [response] = await this.toggleToast(this.getLocalize('editPage.uploadImage'), uploadImageTask);
      return response;
    },
    async onFrontPageImageFileUpdated(file) {
      const response = await this.uploadImage(file);
      if (response.success) {
        this.frontPageLoadedImageSource = response.data.url;
      } else {
        await this.displayToast(this.getLocalize('editPage.uploadImageError'));
      }
    },
    async onPageImageFileUpdated(file) {
      const response = await this.uploadImage(file);
      if (response.success) {
        this.pageLoadedImageSource = response.data.url;
      } else {
        await this.displayToast(this.getLocalize('editPage.uploadImageError'));
      }
    },
    async onAudioFileUpdated(file) {
      const uploadAudioTask = API.MediaService.uploadAudio(file);
      this.isDisabledRecorder = true;
      const [response] = await this.toggleToast(this.getLocalize('editPage.uploadAudio'), uploadAudioTask);
      if (response.success) {
        this.currentPage.audioSource = response.data.url;
        await this.saveCurrentPage();
        this.isDisabledRecorder = false;
      } else {
        this.isDisabledRecorder = false;
        await this.displayToast(this.getLocalize('editPage.uploadAudioError'));
      }
    },
    async onAudioFileDeleted() {
      this.isDisabledRecorder = true;
      this.currentPage.audioSource = '';
      await this.saveCurrentPage();
      this.isDisabledRecorder = false;
    },
    async onFrontImageSourceUpdated(imageSource) {
      this.book.imageSource = imageSource;
      this.debouncedSaveFrontPage();
    },
    async onFrontPageCropAreaUpdated(cropArea) {
      this.book.visibleArea = new CropArea(cropArea);
      this.debouncedSaveFrontPage();
    },
    async onPageCropAreaUpdated(cropArea) {
      this.currentPage.visibleArea = new CropArea(cropArea);
      await this.saveCurrentPage();
    },
    async onPageMarksUpdated(newMarks) {
      if (this.currentPage && this.currentPage.id) {
        this.currentPage.markCollections = this.currentPage.markCollections
          .map((item) => new MarkCollection({
            ...item,
            marks: [],
          }));

        newMarks.forEach((mark) => {
          const markCollection = _find(
            this.currentPage.markCollections,
            (item) => item.type === mark.type,
          );
          if (markCollection) {
            markCollection.marks.push(mark);
          } else {
            this.currentPage.markCollections.push(new MarkCollection({
              type: mark.type,
              marks: [mark],
            }));
          }
        });

        await this.saveCurrentPage();
      }
    },
    async onPageCustomMarkUpdated(customMark) {
      const markCollectionIndex = _findIndex(
        this.currentPage.markCollections,
        (item) => item.type === customMark.type,
      );

      if (markCollectionIndex >= 0) {
        const markCollection = this.currentPage.markCollections[markCollectionIndex];
        markCollection.name = customMark.name;
      } else {
        const markCollection = new MarkCollection({
          type: customMark.type,
          name: customMark.name,
          marks: [],
        });
        this.currentPage.markCollections.push(markCollection);
      }

      await this.saveCurrentPage();
    },
    async createFrontPage() {
      const response = await API.BooksService.createBook(this.book);

      if (response.success) {
        const notAutoUpdateFields = _omit(
          response.data,
          ['title', 'author', 'backgroundColor', 'backgroundTint', 'imageSource', 'visibleArea'],
        );
        this.book = new Book(_defaults(notAutoUpdateFields, this.book));
      }
      return response.success;
    },
    async updateFrontPage() {
      const response = await API.BooksService.updateBook(this.book.id, this.book);
      return response.success;
    },
    async onStepCreationCompleted(step) {
      // this.currentPage = page;
      if (step < this.maxPageSteps) {
        this.page.currentEditStep = _max([step + 1, this.page.currentEditStep]);
        await this.moveToPageStepToast(this.currentPageNumber, step + 1);
      } else {
        await this.toggleToast(this.getLocalize('toast.loading'), this.saveCurrentPage());
        this.displayCompletePageScreen();
      }
    },
    displayCompletePageScreen() {
      this.pageCreationCompleted = true;
    },
    onRightAnswersUpdated(rightAnswers) {
      [this.currentPage.rightAnswer] = rightAnswers;
    },
    onRightAnswerUnitsUpdated(rightAnswerUnits) {
      const [rightAnswerUnit] = rightAnswerUnits;
      this.currentPage.rightAnswerUnit = rightAnswerUnit;
      this.currentPage.firstWrongAnswerUnit = rightAnswerUnit;
      this.currentPage.secondWrongAnswerUnit = rightAnswerUnit;
      this.currentPage.thirdWrongAnswerUnit = rightAnswerUnit;
    },
    onWrongAnswersUpdated(wrongAnswers) {
      [
        this.currentPage.firstWrongAnswer,
        this.currentPage.secondWrongAnswer,
        this.currentPage.thirdWrongAnswer,
      ] = wrongAnswers;
    },
    onWrongAnswerUnitsUpdated(wrongAnswerUnits) {
      [
        this.currentPage.firstWrongAnswerUnit,
        this.currentPage.secondWrongAnswerUnit,
        this.currentPage.thirdWrongAnswerUnit,
      ] = wrongAnswerUnits;
    },
    async updateDataAfterCommentActivity() {
      if (this.isViewFrontPage) {
        await this.loadBook(this.book.id);
      } else {
        await this.loadPage(this.book.id, this.page.id);
      }
    },
    async loadPage(bookId, pageId) {
      const response = await API.PagesService.getPage(bookId, pageId, {
        includeMarks: true,
        includeComments: true,
        includeAttempts: false,
      });
      if (response.success) {
        this.pages = _map(this.pages, (page) => (page.id === pageId ? response.data : page));
      }
      return response.success;
    },
    async deleteBook() {
      if (this.book && this.book.id) {
        const response = await API.BooksService.deleteBook(this.book.id);
        if (response.success) {
          this.openBookShelf();
        } else if (response.status === STATUS_CODE.NotFound) {
          this.openBookShelf();
          await this.displayToast(this.getLocalize('errors.bookNotFound'));
        } else if (response.status === STATUS_CODE.Forbidden) {
          await this.displayToast(this.getLocalize('errors.bookForbidden'));
        } else {
          await this.displayToast(this.getLocalize('errors.somethingWrong'));
        }
      } else {
        this.openBookShelf();
      }
    },
    async deletePage() {
      if (this.bookId && this.currentPage && this.currentPage.id) {
        const pageId = this.currentPage.id;
        const response = await API.PagesService.deletePage(this.bookId, pageId);
        if (response.success) {
          this.leafPageBack({
            notSave: true,
          });
          this.pages = _filter(this.pages, (page) => page.id !== pageId);
        } else if (response.status === STATUS_CODE.NotFound) {
          this.leafPageBack({
            notSave: true,
          });
          await this.displayToast(this.getLocalize('errors.pageNotFound'));
          this.pages = _filter(this.pages, (page) => page.id !== pageId);
        } else if (response.status === STATUS_CODE.Forbidden) {
          await this.displayToast(this.getLocalize('errors.pageForbidden'));
        } else {
          await this.displayToast(this.getLocalize('errors.somethingWrong'));
        }
      } else if (this.bookId && this.currentPage) {
        this.leafPageBack({
          notSave: true,
        });
        this.pages = _filter(this.pages, (page) => !!page.id);
      }
    },
    confirmAndDelete() {
      if (this.currentPageNumber <= 0) {
        this.showDeleteBookConfirm = true;
      } else {
        this.showDeletePageConfirm = true;
      }
    },
    async onPageTextToSpeech() {
      await this.textToSpeech(this.currentPage.exerciseText);
    },
    async onPageHintToSpeech() {
      await this.textToSpeech(this.currentPage.promptAnswer);
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/desktop/pages/book/editBook.scss";
</style>
