import request from '@/api/httpClient';
import Page from '@/api/models/Page';
import { parseErrors } from '@/api/utils';

export class PagesService {
  static async getPages(bookId, params = {
    includeMarks: false,
    includeComments: false,
    includeAttempts: false,
  }) {
    try {
      const requestTask = request.getRequest(
        `/books/${bookId}/pages`,
        params,
        {},
      ).then((response) => {
        const pages = response.data.map(Page.fromApi);
        return { success: true, data: pages };
      });
      return await parseErrors(requestTask);
    } catch (e) {
      return { success: false };
    }
  }

  static async createPage(bookId, pageOfBook) {
    try {
      const requestTask = request.postRequest(
        `/Books/${bookId}/pages`,
        pageOfBook.toApi(),
        {},
      ).then((response) => {
        const newPage = Page.fromApi(response.data);
        return { success: true, data: newPage };
      });
      return await parseErrors(requestTask);
    } catch (e) {
      console.error(e);
      return { success: false };
    }
  }

  static async updatePage(bookId, pageId, pageOfBook) {
    try {
      const requestTask = request.putRequest(
        `/Books/${bookId}/pages/${pageId}`,
        pageOfBook.toApi(),
        { 'Content-Type': 'multipart/form-data' },
      ).then((response) => {
        const updatedPage = Page.fromApi(response.data);
        return { success: true, data: updatedPage };
      });
      return await parseErrors(requestTask);
    } catch (e) {
      return { success: false };
    }
  }

  static async deletePage(bookId, pageId) {
    try {
      const requestTask = request.deleteRequest(`/Books/${bookId}/pages/${pageId}`).then(() => ({ success: true }));
      return await parseErrors(requestTask);
    } catch (e) {
      return { success: false };
    }
  }

  static async getPage(bookId, pageId, params = {
    includeMarks: false,
    includeComments: false,
    includeAttempts: false,
  }) {
    try {
      const requestTask = request.getRequest(
        `/books/${bookId}/pages/${pageId}`,
        params,
        {},
      ).then((response) => {
        const page = Page.fromApi(response.data);
        return { success: true, data: page };
      });
      return await parseErrors(requestTask);
    } catch (e) {
      return { success: false };
    }
  }
}
