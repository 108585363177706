import _get from 'lodash/get';

export function getTextFromHtml(html) {
  const divElement = document.createElement('div');
  divElement.innerHTML = html;
  return divElement.textContent || divElement.innerText;
}

export function getTextLengthFromHtml(html, keyEnterLength = 1) {
  const divCount = _get(html.match(/<div>(?!<\/div>)/g), 'length', 1);
  const text = getTextFromHtml(html);
  return _get(text, 'length') + (divCount - 1) * keyEnterLength;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
