<template>
  <div class="camera-capture">
    <div class="camera-capture__video_box">
      <video
        ref="video"
        autoplay
        class="camera-capture__video"
      >
        {{ videoNotSupportedMessage }}
      </video>
    </div>
    <div
      v-if="!errorMessage"
      class="camera-capture__buttons"
    >
      <custom-button
        v-if="!isPhotoTaken"
        :title="takePhotoButtonTitle"
        icon="camera_alt"
        @click="onPhotoTaken"
      />
      <custom-button
        v-if="isPhotoTaken"
        :title="retakePhotoButtonTitle"
        class="md-raised"
        icon="refresh"
        type="contained"
        @click="onPhotoRetaken"
      />
      <custom-button
        v-if="isPhotoTaken"
        :title="selectPhotoButtonTitle"
        icon="done"
        @click="onPhotoSelected"
      />
    </div>
    <div v-if="errorMessage">
      {{ errorMessage }}
    </div>
    <canvas
      v-show="false"
      ref="canvas"
    />
  </div>
</template>

<script>
import CustomButton from '@/components/buttons/CustomButton.vue';

/**
 * @emit onPhotoSelected - photo file was selected
 */
export default {
  name: 'CameraCapture',
  components: {
    CustomButton,
  },
  props: {
    getLocalize: {
      type: Function,
      default: (key) => key,
      required: false,
    },
  },
  data: () => ({
    videoStream: null,
    isPhotoTaken: false,
    errorMessage: null,
  }),
  computed: {
    videoNotSupportedMessage() {
      return this.getLocalize('cameraCapture.videoNotSupported');
    },
    takePhotoButtonTitle() {
      return this.getLocalize('cameraCapture.takePhotoButtonTitle');
    },
    selectPhotoButtonTitle() {
      return this.getLocalize('cameraCapture.selectPhotoButtonTitle');
    },
    retakePhotoButtonTitle() {
      return this.getLocalize('cameraCapture.retakePhotoButtonTitle');
    },
  },
  async mounted() {
    if (!this.$refs.canvas.getContext) {
      this.errorMessage = this.getLocalize('cameraCapture.canvasUnsupported');
      return;
    }

    if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) return;

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'environment', width: { ideal: 1620 }, height: { ideal: 1080 } },
      });
      this.$refs.video.srcObject = stream;
      this.videoStream = stream;
    } catch (e) {
      console.error(e);
      this.errorMessage = this.getLocalize('cameraCapture.cameraNotAvailable');
    }
  },
  beforeDestroy() {
    if (this.videoStream) {
      this.videoStream.getTracks().forEach((track) => track.stop());
      this.videoStream = null;
    }

    this.$refs.video.srcObject = null;
  },
  methods: {
    onPhotoTaken() {
      this.$refs.video.pause();
      this.isPhotoTaken = true;
    },
    onPhotoRetaken() {
      this.$refs.video.play();
      this.isPhotoTaken = false;
    },
    onPhotoSelected() {
      const width = this.$refs.video.videoWidth;
      const height = this.$refs.video.videoHeight;
      this.$refs.canvas.width = width;
      this.$refs.canvas.height = height;

      const ctx = this.$refs.canvas.getContext('2d');
      ctx.drawImage(this.$refs.video, 0, 0, width, height);
      this.$refs.canvas.toBlob((blob) => {
        const file = new File([blob], 'photo.jpeg');
        this.$emit('onPhotoSelected', file);
      }, 'image/jpeg');
      this.onPhotoRetaken();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/cameraCapture.scss";
</style>
