<template>
  <div
    class="template-miniature"
    :style="`border: .25em solid ${color};
      background: ${color} url(${image}) no-repeat center; background-size: contain;
    `"
  >
    <div class="template-miniature__info">
      <span class="template-miniature__info-title">{{ title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TemplateMiniature',
  props: {
    image: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/fabTemplateBooks.scss";
</style>
