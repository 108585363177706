<template>
  <div>
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'Answer',
  props: {
    text: {
      type: String,
      default: '',
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/answer.scss";
</style>
