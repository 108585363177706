<template>
  <app-dialog
    class="dialog"
    :show-dialog="showDialog"
    :show-buttons="false"
    :content-background="false"
    :show-close-button="true"
    @onDialogClosed="onDialogClosed"
  >
    <div class="file-dialog">
      <div class="file-dialog__head">
        <custom-button
          :title="imageBankButtonTitle"
          class="md-raised"
          icon="photo_library"
          :type="getButtonType(TABS.IMAGE_BANK)"
          :is-disabled="activeFileDialogTab === TABS.IMAGE_BANK"
          @click="onImageBankSelected"
        />
        <custom-button
          v-if="showImageSearchTab"
          :title="imageSearchButtonTitle"
          class="md-raised"
          icon="search"
          :type="getButtonType(TABS.IMAGE_SEARCH)"
          :is-disabled="activeFileDialogTab === TABS.IMAGE_SEARCH"
          @click="onImageSearchSelected"
        />
        <custom-button
          v-if="showCameraTab"
          :title="cameraButtonTitle"
          class="md-raised"
          icon="camera_alt"
          :type="getButtonType(TABS.CAMERA)"
          :is-disabled="activeFileDialogTab === TABS.CAMERA"
          @click="onCameraSelected"
        />
        <custom-button
          v-if="showUploadFileTab"
          :title="uploadFileButtonTitle"
          class="md-raised"
          icon="file_upload"
          :type="getButtonType(TABS.UPLOAD_FILE)"
          :is-disabled="activeFileDialogTab === TABS.UPLOAD_FILE"
          @click="onUploadFileSelected"
        />
        <custom-button
          v-if="showWorkspaceImageTab"
          :title="workspaceImageButtonTitle"
          class="md-raised"
          icon="workspaces"
          :type="getButtonType(TABS.WORKSPACE_IMAGE)"
          :is-disabled="activeFileDialogTab === TABS.WORKSPACE_IMAGE"
          @click="onWorkspaceImageSelected"
        />
        <custom-button
          v-if="showUserImageTab"
          :title="userImageButtonTitle"
          class="md-raised"
          icon="folder"
          :type="getButtonType(TABS.USER_IMAGE)"
          :is-disabled="activeFileDialogTab === TABS.USER_IMAGE"
          @click="onUserImageSelected"
        />
      </div>
      <div class="file-dialog__head-mobile">
        <custom-button
          class="md-raised"
          icon="photo_library"
          :type="getButtonType(TABS.IMAGE_BANK)"
          :is-disabled="activeFileDialogTab === TABS.IMAGE_BANK"
          @click="onImageBankSelected"
        />
        <custom-button
          v-if="showImageSearchTab"
          class="md-raised"
          icon="search"
          :type="getButtonType(TABS.IMAGE_SEARCH)"
          :is-disabled="activeFileDialogTab === TABS.IMAGE_SEARCH"
          @click="onImageSearchSelected"
        />
        <custom-button
          v-if="showCameraTab"
          class="md-raised"
          icon="camera_alt"
          :type="getButtonType(TABS.CAMERA)"
          :is-disabled="activeFileDialogTab === TABS.CAMERA"
          @click="onCameraSelected"
        />
        <custom-button
          v-if="showUploadFileTab"
          class="md-raised"
          icon="file_upload"
          :type="getButtonType(TABS.UPLOAD_FILE)"
          :is-disabled="activeFileDialogTab === TABS.UPLOAD_FILE"
          @click="onUploadFileSelected"
        />
        <custom-button
          v-if="showWorkspaceImageTab"
          class="md-raised"
          icon="workspaces"
          :type="getButtonType(TABS.WORKSPACE_IMAGE)"
          :is-disabled="activeFileDialogTab === TABS.WORKSPACE_IMAGE"
          @click="onWorkspaceImageSelected"
        />
        <custom-button
          v-if="showUserImageTab"
          class="md-raised"
          icon="folder"
          :type="getButtonType(TABS.USER_IMAGE)"
          :is-disabled="activeFileDialogTab === TABS.USER_IMAGE"
          @click="onUserImageSelected"
        />
      </div>
      <div class="file-dialog__content">
        <image-bank
          v-if="activeFileDialogTab === TABS.IMAGE_BANK"
          :categories="getLocalize('imageBank')"
          :image-bank-source="imageBankSource"
          :tab-id-chain="imageBankTabIdChain"
          @onImageSourceSelected="onImageSourceSelected"
          @onTabIdChainUpdated="onImageBankTabIdChainUpdated"
        />
        <image-search
          v-if="activeFileDialogTab === TABS.IMAGE_SEARCH"
          :key="TABS.IMAGE_SEARCH"
          :images="foundImages"
          :search-input-placeholder="getLocalize('imageSearch.searchInputPlaceholder')"
          :search-button-title="getLocalize('imageSearch.searchButtonTitle')"
          :empty-image-list-message="getLocalize('imageSearch.emptyImageListSearch')"
          :provider-message="getLocalize('imageSearch.providerMessage')"
          :provider-name="imageSearchProviderName"
          :provider-link="imageSearchProviderLink"
          :search="searchString"
          @onImageSearch="onImageSearch"
          @onLoadMoreImages="onLoadMoreSearchImages"
          @onImageSourceSelected="onImageSourceSelected"
        />
        <camera-capture
          v-if="activeFileDialogTab === TABS.CAMERA"
          :get-localize="getLocalize"
          @onPhotoSelected="onFileSelected"
        />
        <upload-file
          v-if="activeFileDialogTab === TABS.UPLOAD_FILE"
          :get-localize="getLocalize"
          :image-extensions="uploadImageExtensions"
          :size-image-max-k-b="uploadSizeImageMaxKB"
          :open-file-loading-dialog-on-mounted="openFileLoadingDialog"
          @onFileSelected="onFileSelected"
        />
        <image-search
          v-if="activeFileDialogTab === TABS.WORKSPACE_IMAGE"
          :key="TABS.WORKSPACE_IMAGE"
          :images="foundImages"
          :display-search-input="false"
          :empty-image-list-message="getLocalize('imageSearch.emptyImageListWorkspace')"
          @onLoadMoreImages="onLoadMoreSearchImages"
          @onImageSourceSelected="onImageSourceSelected"
        />
        <image-search
          v-if="activeFileDialogTab === TABS.USER_IMAGE"
          :key="TABS.USER_IMAGE"
          :images="foundImages"
          :display-search-input="false"
          :empty-image-list-message="getLocalize('imageSearch.emptyImageListUser')"
          @onLoadMoreImages="onLoadMoreSearchImages"
          @onImageSourceSelected="onImageSourceSelected"
        />
      </div>
    </div>
  </app-dialog>
</template>

<script>
import CustomButton from '@/components/buttons/CustomButton.vue';
import UploadFile from '@/components/uploadFile/UploadFile.vue';
import CameraCapture from '@/components/cameraCapture/CameraCapture.vue';
import ImageBank from '@/components/imageBank/ImageBank.vue';
import fileDialogForwardingMixin from '@/mixins/fileDialogForwardingMixin';
import ImageSearch from '@/components/imageSearch/ImageSearch.vue';
import { FILE_DIALOG_TABS, IMAGE_SOURCE } from '@/helpers/const';
import AppDialog from '@/components/appDialog/AppDialog.vue';

/**
 * @emit onFileSelected - image file was selected
 * @emit onImageSourceSelected - image was selected
 * @emit onDialogClosed - dialog closed
 * @emit onImageSearch - search images
 * @emit onLoadMoreSearchImages - load more images
 * @emit onImageSearchSourceUpdated - image source updated
 * @emit onActiveFileDialogTabUpdated - activeFileDialogTab was updated
 * @emit onImageBankTabIdChainUpdated - imageBankTabIdChain was updated
 */
export default {
  name: 'FileDialog',
  components: {
    AppDialog,
    ImageSearch,
    ImageBank,
    UploadFile,
    CameraCapture,
    CustomButton,
  },
  mixins: [
    fileDialogForwardingMixin,
  ],
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    getLocalize: {
      type: Function,
      default: (key) => key,
      required: false,
    },
  },
  data: () => ({
    TABS: FILE_DIALOG_TABS,
    openFileLoadingDialog: true,
  }),
  computed: {
    imageBankButtonTitle() {
      return this.getLocalize('fileDialog.imageBankButtonTitle');
    },
    imageSearchButtonTitle() {
      return this.getLocalize('fileDialog.imageSearchButtonTitle');
    },
    cameraButtonTitle() {
      return this.getLocalize('fileDialog.cameraButtonTitle');
    },
    uploadFileButtonTitle() {
      return this.getLocalize('fileDialog.uploadFileButtonTitle');
    },
    workspaceImageButtonTitle() {
      return this.getLocalize('fileDialog.workspaceImageButtonTitle');
    },
    userImageButtonTitle() {
      return this.getLocalize('fileDialog.userImageButtonTitle');
    },
  },
  watch: {
    showDialog(value) {
      if (value) {
        this.openFileLoadingDialog = this.activeFileDialogTab !== this.TABS.UPLOAD_FILE;
      } else {
        this.openFileLoadingDialog = true;
      }
    },
    activeFileDialogTab() {
      this.openFileLoadingDialog = true;
    },
  },
  methods: {
    getButtonType(tab) {
      return this.activeFileDialogTab === tab ? 'primary-filled' : 'white-filled';
    },
    onImageBankSelected() {
      this.$emit('onActiveFileDialogTabUpdated', this.TABS.IMAGE_BANK);
    },
    onImageSearchSelected() {
      this.$emit('onImageSearchSourceUpdated', IMAGE_SOURCE.IMAGE_SEARCH);
      this.$emit('onActiveFileDialogTabUpdated', this.TABS.IMAGE_SEARCH);
    },
    onCameraSelected() {
      this.$emit('onActiveFileDialogTabUpdated', this.TABS.CAMERA);
    },
    onUploadFileSelected() {
      this.$emit('onActiveFileDialogTabUpdated', this.TABS.UPLOAD_FILE);
    },
    onWorkspaceImageSelected() {
      this.$emit('onImageSearchSourceUpdated', IMAGE_SOURCE.WORKSPACE);
      this.$emit('onActiveFileDialogTabUpdated', this.TABS.WORKSPACE_IMAGE);
    },
    onUserImageSelected() {
      this.$emit('onImageSearchSourceUpdated', IMAGE_SOURCE.PERSONAL);
      this.$emit('onActiveFileDialogTabUpdated', this.TABS.USER_IMAGE);
    },
    onDialogClosed() {
      this.$emit('onDialogClosed');
    },
    onFileSelected(file) {
      this.$emit('onFileSelected', file);
    },
    onImageSourceSelected(imageSource) {
      this.$emit('onImageSourceSelected', imageSource);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/fileDialog.scss";
</style>
