import {
  IMAGE_SEARCH_ADD_IMAGES,
  IMAGE_SEARCH_SET_IMAGES,
  IMAGE_SEARCH_SET_ARE_IMAGES_LOADING,
  IMAGE_SEARCH_SET_SEARCH,
  IMAGE_SEARCH_SET_IMAGE_SOURCE,
  IMAGE_SEARCH_RESET_STATE,
} from '@/store/modules/imageSearch/mutationTypes';
import getInitialState from '@/store/modules/imageSearch/getInitialState';

export default ({
  [IMAGE_SEARCH_SET_IMAGES](state, data) {
    state[data.imageSource].images = data.images;
    state[data.imageSource].nextPageToken = data.nextPageToken;
  },
  [IMAGE_SEARCH_ADD_IMAGES](state, data) {
    const { imageSource, images, nextPageToken } = data;
    state[imageSource].images = [...state[imageSource].images, ...images];
    state[imageSource].nextPageToken = nextPageToken;
  },
  [IMAGE_SEARCH_SET_ARE_IMAGES_LOADING](state, data) {
    state[data.imageSource].areImagesLoading = data.areImagesLoading;
  },
  [IMAGE_SEARCH_SET_SEARCH](state, data) {
    state[data.imageSource].search = data.search;
  },
  [IMAGE_SEARCH_SET_IMAGE_SOURCE](state, imageSource) {
    state.imageSource = imageSource;
  },
  [IMAGE_SEARCH_RESET_STATE](state) {
    Object.assign(state, getInitialState());
  },
});
