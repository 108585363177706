<template>
  <md-button
    class="md-fab md-primary md-fab-bottom-right md-fab_notifications"
    :style="{ marginTop: `-${clientHeight}px` }"
  >
    <md-icon>{{ iconName }}</md-icon>
  </md-button>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'add',
    },
  },
  data() {
    return {
      clientHeight: 0,
    };
  },
  mounted() {
    this.clientHeight = this.$el.clientHeight;
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/fab.scss";
</style>
