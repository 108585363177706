<template>
  <div
    v-if="!authKey"
    class="wrapper"
  >
    <div class="rec-prism">
      <div class="face face-front">
        <div
          v-if="showLoginForm"
          class="content"
        >
          <h2>{{ $t("signInPage.titleForm") }}</h2>
          <form
            class="signin-form"
            @submit.prevent="signIn"
          >
            <div class="field-wrapper">
              <input
                ref="login"
                v-model="login"
                :placeholder="$t('signInPage.lblLogin')"
                name="email"
                type="text"
              >
              <label>{{ $t("signInPage.lblLogin") }}</label>
            </div>
            <div class="field-wrapper">
              <input
                v-model="password"
                :placeholder="$t('signInPage.lblPassword')"
                name="password"
                type="password"
                autofocus
              >
              <label>{{ $t("signInPage.lblPassword") }}</label>
            </div>
            <div class="field-wrapper">
              <label
                class="danger"
                type="error"
              >{{ errorMessage }}</label>
            </div>
            <div class="field-wrapper">
              <custom-button
                :block="true"
                :title="$t('signInPage.btnSignIn')"
                type="submit"
                class="button-submit b-button--filled"
                @click="signIn"
              />
            </div>
          </form>
        </div>
        <div
          v-if="showRedirectButton"
          class="content"
        >
          <h2>{{ $t("signInPage.titleRedirect") }}</h2>
          <custom-button
            class="redirect-button"
            :title="$t('signInPage.buttonRedirect')"
            @click="authRedirect"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _invoke from 'lodash/invoke';
import { API } from '@/api/api';
import CustomButton from '@/components/buttons/CustomButton.vue';
import { LOAD_SESSION } from '@/store/modules/session/actionTypes';
import { APP_PAGES } from '@/helpers/const';
import toastMixin from '@/mixins/toastMixin';
import localizationMixin from '@/mixins/localizationMixin';
import { LOGOUT } from '@/store/actionTypes';
import { getBaseUrl } from '@/api/utils';

export default {
  name: 'Login',
  components: {
    CustomButton,
  },
  mixins: [
    localizationMixin,
    toastMixin,
  ],
  data: () => ({
    baseApiUrl: getBaseUrl(),
    showLoginForm: process.env.VUE_APP_FM_SHOW_LOGIN_FORM === 'true',
    autoRedirectAuthApp: process.env.VUE_APP_AUTO_REDIRECT_AUTH_APP === 'true',
    login: '',
    password: '',
    errorMessage: '',
  }),
  computed: {
    authKey() {
      return this.$route.query.authKey;
    },
    showRedirectButton() {
      return !this.showLoginForm && !this.authKey && !this.autoRedirectAuthApp;
    },
  },
  watch: {
    authKey: {
      async handler(newValue) {
        if (newValue) {
          await API.AccountService.logout();
          await this.$store.dispatch(LOGOUT);
          const authTask = API.AccountService.login(newValue);
          const [result] = await this.toggleToast(this.getLocalize('signInPage.signIn'), authTask);
          if (result.success) {
            await this.$store.dispatch(LOAD_SESSION);
            await this.$router.replace({ name: APP_PAGES.WORKSPACE });
          } else {
            await this.displayToast(this.getLocalize('errors.authKey'));
            await this.$router.replace({ name: APP_PAGES.SIGN_IN });
          }
        } else if (!this.showLoginForm && this.autoRedirectAuthApp) {
          this.authRedirect();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    setTimeout(() => {
      _invoke(this.$refs, 'login.focus');
    }, 300);
  },
  methods: {
    authRedirect() {
      window.location.href = new URL('api/authservice', this.baseApiUrl);
    },
    signIn() {
      if (this.login === '') {
        this.errorMessage = this.$t('signInPage.loginIsEmptyErrorMessage');
        return;
      }

      if (this.password === '') {
        this.errorMessage = this.$t('signInPage.passwordIsEmptyErrorMessage');
        return;
      }
      this.errorMessage = '';
      API.AccountService.signIn(this.login, this.password).then((result) => {
        if (!result.success) {
          const keyTranslate = result.data.error === 'Network Error'
            ? 'signInPage.networkErrorMessage'
            : 'signInPage.loginOrPasswordIncorrectErrorMessage';
          this.errorMessage = this.$t(keyTranslate);
          return;
        }
        this.$store.dispatch(LOAD_SESSION);
        this.$router.replace({ name: APP_PAGES.WORKSPACE });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/pages/signIn.scss";
</style>
