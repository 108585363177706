<template>
  <div
    v-if="isLoadedBook"
    class="solve-book"
  >
    <div class="solve-book__header">
      <custom-button
        v-if="book"
        :icon-src="'bookshelf_icon'"
        :title="getLocalize('buttonBookShelves.btnGoWorkspace')"
        type="white-filled"
        class="button-bookshelf"
        @click="openBookShelf()"
      />
      <custom-button
        v-if="book && !book.predefined && (book.userId === sessionUserId || isTeacher)"
        :icon="'create'"
        :title="getLocalize('buttonEditBook.btnGoEditBook')"
        type="primary-filled"
        class="button-edit"
        @click="openBookEditMode()"
      />
    </div>
    <div class="solve-book__content">
      <div
        :class="{ hidden: isViewFrontPage }"
        class="solve-book__nav"
      >
        <md-button
          class="md-icon-button md-raised center solve-book__nav-button"
          @click="leafPageBack()"
        >
          <md-icon class="main-theme-icon">
            chevron_left
          </md-icon>
        </md-button>
      </div>
      <front-page
        v-if="isViewFrontPage"
        :author="book.author"
        :background-color="book.backgroundColor"
        :background-tint="book.backgroundTint"
        :get-localize="getLocalize"
        :image-source="book.imageSource"
        :read-only="true"
        :title="book.title"
        :crop-area="book.visibleArea"
      />
      <book-page
        v-if="!isViewFrontPage && !isViewBackPage"
        :key="page.number"
        :page-hint="page.promptAnswer"
        :page-number="page.number + 1"
        :page-text="exerciseText"
        :select-page-text="!pageIsReady || !existCurrentPageText"
        :page-text-audio-source="pageIsReady ? page.audioSource : ''"
        :right-answers="page.rightAnswers"
        :wrong-answers="page.wrongAnswers"
        :page-image-source="pageIsReady ? page.imageSource : null"
        :page-area="page.visibleArea"
        :marks="page.marks"
        :coefficient-mark-size="coefficientMarkSize"
        :attempts="page.attempts"
        :show-page-hint="displayHint"
        :show-marks="false"
        :allow-select-answer="!forbidClick"
        :student-source="studentSource(currentIndexPage)"
        :view-only="!pageIsReady"
        :show-right-answers="pageIsReady"
        :show-wrong-answers="pageIsReady"
        :shuffle-answers="page.shuffleAnswers"
        @onAnswerSelected="onAnswerSelected"
        @onPageTextToSpeech="onPageTextToSpeech"
        @onPageHintToSpeech="onPageHintToSpeech"
      />
      <back-page
        v-if="isViewBackPage"
        :background-color="book.backgroundColor"
        :background-tint="book.backgroundTint"
        :date="book.createDatetime"
        :locale="book.language"
        :date-format="backPageDateFormat"
      />
      <div
        :class="{ hidden: isViewBackPage }"
        class="solve-book__nav"
      >
        <md-button
          class="md-icon-button md-raised center solve-book__nav-button"
          @click="leafPageForward()"
        >
          <md-icon class="main-theme-icon">
            chevron_right
          </md-icon>
        </md-button>
      </div>
      <div class="solve-book__mobnav">
        <md-button
          class="md-icon-button md-raised center solve-book__nav-button"
          :class="{ hidden: isViewFrontPage }"
          @click="leafPageBack()"
        >
          <md-icon class="main-theme-icon">
            chevron_left
          </md-icon>
        </md-button>
        <md-button
          class="md-icon-button md-raised center solve-book__nav-button"
          :class="{ hidden: isViewBackPage }"
          @click="leafPageForward()"
        >
          <md-icon class="main-theme-icon">
            chevron_right
          </md-icon>
        </md-button>
      </div>
    </div>
    <comments-widget
      v-if="!isViewBackPage && (smileCommentsAvailable || textCommentsAvailable)"
      ref="commentsWidget"
      :comments="pageComments"
      :get-localize="getLocalize"
      :max-comment-length="maxCommentLength"
      :smile-enabled="smileCommentsAvailable"
      :text-enabled="textCommentsAvailable"
      :user-id="sessionUserId"
      :allow-delete-any="allowDeleteAnyComments"
      allow-delete-my-self
      :allow-reply-other="allowReplyOnComment"
      :show-c-w="showCW"
      :emojis="emojis"
      @onCommentDeleted="onCommentDeleted"
      @onCommentTextAdded="onCommentTextAdded"
      @onCommentEmojiAdded="onCommentEmojiAdded"
      @onShowCWUpdated="showCWUpdated"
    />
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import _map from 'lodash/map';
import _get from 'lodash/get';
import { API } from '@/api/api';
import { APP_PAGES, USER_ROLE } from '@/helpers/const';
import CustomButton from '@/components/buttons/CustomButton.vue';
import FrontPage from '@/components/frontPage/FrontPage.vue';
import BookPage from '@/components/bookPage/BookPage.vue';
import BackPage from '@/components/backPage/BackPage.vue';
import CommentsWidget from '@/components/commentsWidget/CW.vue';
import localizationMixin from '@/mixins/localizationMixin';
import commentsWidgetMixin from '@/mixins/commentsWidgetMixin';
import sessionMixin from '@/mixins/sessionMixin';
import toastMixin from '@/mixins/toastMixin';
import studentSource from '@/mixins/studentSource';
import getPageMaxStep from '@/mixins/getPageMaxStep';
import emojiSourceMixin from '@/mixins/emojiSourceMixin';
import existPageText from '@/mixins/existPageText';
import textToSpeechMixin from '@/mixins/textToSpeechMixin';
import Page from '@/api/models/Page';
import { MESSAGES_SET_HEADER_MESSAGE } from '@/store/modules/messages/actionTypes';

export default {
  name: 'SolveBook',
  components: {
    CustomButton,
    FrontPage,
    BookPage,
    BackPage,
    CommentsWidget,
  },
  mixins: [
    localizationMixin,
    toastMixin,
    studentSource,
    getPageMaxStep,
    commentsWidgetMixin,
    sessionMixin,
    emojiSourceMixin,
    existPageText,
    textToSpeechMixin,
  ],
  data: () => ({
    book: null,
    pages: [],
    isLoadedBook: false,
    mouseDownClientX: null,
    mouseDownClientY: null,
    coefficientMarkSize: Number.parseFloat(process.env.VUE_APP_COEFFICIENT_MARK_SIZE),
    maxPageSteps: 5,
    backPageDateFormat: process.env.VUE_APP_BACK_PAGE_DATE_FORMAT,
  }),
  computed: {
    headerMessage() {
      if (this.book && this.book.userFullName && this.book.title) {
        return `${this.book.userFullName} / ${this.book.title}`;
      }
      if (this.book && this.book.userFullName) {
        return this.book.userFullName;
      }
      if (this.book && this.book.title) {
        return this.book.title;
      }
      return '';
    },
    isViewFrontPage() {
      return this.$route.name === APP_PAGES.SOLVE_FRONT_PAGE;
    },
    isViewBackPage() {
      return this.$route.name === APP_PAGES.SOLVE_END_PAGE;
    },
    currentIndexPage() {
      if (this.$route.name === APP_PAGES.SOLVE_FRONT_PAGE
        || this.$route.name === APP_PAGES.SOLVE_END_PAGE) {
        return 0;
      } if (this.$route.name === APP_PAGES.SOLVE_BOOK_BY_ID) {
        const { pageId } = this.$route.params;
        const page = this.pages.find((p) => +p.id === +pageId);
        return page ? +page.number : 0;
      }

      return +this.$route.params.pageNumber - 1 || 0;
    },
    bookId() {
      return +this.$route.params.bookId;
    },
    page() {
      return this.pages[this.currentIndexPage];
    },
    existCurrentPageText() {
      return this.existPageText(this.page);
    },
    exerciseText() {
      if (!this.pageIsReady) {
        return this.getLocalize('solvePage.progressPage');
      }

      return this.existCurrentPageText
        ? this.page.exerciseText
        : this.getLocalize('solvePage.audioExerciseText');
    },
    nextPage() {
      return this.isNextPageExist ? this.pages[this.currentIndexPage + 1] : {};
    },
    pageIsReady() {
      return this.getPageMaxStep(this.page) >= this.maxPageSteps;
    },
    isNextPageExist() {
      return this.currentIndexPage + 1 < this.pages.length;
    },
    displayHint() {
      const attempts = this.page.attempts || [];
      return this.pageIsReady && (this.page.promptAnswer !== undefined)
        && (this.page.promptAnswer.trim().length > 0)
        && (attempts.length > 0);
    },
    forbidClick() {
      const rightAnswers = this.page.rightAnswers || [];
      const attempts = this.page.attempts || [];
      return (attempts.filter((v) => rightAnswers.includes(v)).length === rightAnswers.length);
    },
    isClearAttempts() {
      return this.sessionRole === USER_ROLE.TEACHER || +this.sessionUserId === +this.book.userId;
    },
  },
  watch: {
    bookId: {
      handler(newValue) {
        this.debouncedLoadBookAndPages(newValue);
      },
      immediate: true,
    },
    currentIndexPage: {
      handler(newValue) {
        this.clearAttempts(newValue);
      },
      immediate: true,
    },
    headerMessage(value) {
      this.$store.dispatch(MESSAGES_SET_HEADER_MESSAGE, value);
    },
  },
  mounted() {
    this.$store.dispatch(MESSAGES_SET_HEADER_MESSAGE, this.headerMessage);
  },
  beforeDestroy() {
    this.$store.dispatch(MESSAGES_SET_HEADER_MESSAGE, '');
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isLoadedBook) {
      next();
    } else if (to.name === APP_PAGES.SOLVE_BOOK && +to.params.pageNumber > this.pages.length) {
      next(false);
    } else if (to.name === APP_PAGES.SOLVE_BOOK_BY_ID
      && this.pages.every((p) => +p.id !== +to.params.pageId)) {
      next(false);
    } else {
      next();
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (+to.params.bookId !== +from.params.bookId || !this.isLoadedBook) {
      next();
    } else if (to.name === APP_PAGES.SOLVE_BOOK && +to.params.pageNumber > this.pages.length) {
      next({
        name: APP_PAGES.SOLVE_FRONT_PAGE,
        params: { bookId: this.bookId },
        replace: true,
      });
    } else if (to.name === APP_PAGES.SOLVE_BOOK_BY_ID
      && +to.params.pageId > 0
      && this.pages.every((p) => +p.id !== +to.params.pageId)) {
      next({
        name: APP_PAGES.SOLVE_FRONT_PAGE,
        params: { bookId: this.bookId },
        replace: true,
      });
    } else {
      next();
    }
  },
  methods: {
    async updateDataAfterCommentActivity() {
      if (this.isViewFrontPage) {
        await this.loadBook(this.book.id);
      } else {
        await this.loadPage(this.book.id, this.page.id);
      }
    },
    leafPageBack() {
      if (this.isViewFrontPage) return;

      if (this.isViewBackPage) {
        if (this.pages.length > 0) {
          this.goToPage(this.pages.length - 1);
          return;
        }
        this.$router.push({ name: APP_PAGES.SOLVE_FRONT_PAGE, query: { comments: this.showCW } });
      } else if (this.currentIndexPage < 1) {
        this.$router.push({ name: APP_PAGES.SOLVE_FRONT_PAGE, query: { comments: this.showCW } });
      } else {
        this.goToPage(this.currentIndexPage - 1);
      }
    },
    leafPageForward() {
      if (this.isViewBackPage) return;

      if (this.isViewFrontPage) {
        if (this.pages.length > 0) {
          this.goToPage(0);
          return;
        }
        this.$router.push({ name: APP_PAGES.SOLVE_END_PAGE, query: { comments: this.showCW } });
      } else if (this.currentIndexPage >= this.pages.length - 1) {
        this.$router.push({ name: APP_PAGES.SOLVE_END_PAGE, query: { comments: this.showCW } });
      } else {
        this.goToPage(this.currentIndexPage + 1);
      }
    },
    debouncedLoadBookAndPages: _debounce(async function debounce(bookId) {
      this.isLoadedBook = false;
      await this.toggleToast(this.getLocalize('toast.loading'), this.loadBookAndPages(bookId));
    }, 200),
    async loadBookAndPages(bookId) {
      const bookLoaded = await this.loadBook(bookId);

      if (bookLoaded) {
        const pagesLoaded = await this.loadBookPages(bookId);
        if (pagesLoaded) {
          this.isLoadedBook = true;

          if (this.$route.name === APP_PAGES.SOLVE_BOOK_BY_ID
            && this.pages.every((p) => +p.id !== +this.$route.params.pageId)) {
            await this.$router.replace({
              name: APP_PAGES.SOLVE_FRONT_PAGE,
              params: { bookId: this.bookId },
            });
          } else if (this.$route.name === APP_PAGES.SOLVE_BOOK
            && this.currentIndexPage >= this.pages.length) {
            await this.$router.replace({
              name: APP_PAGES.SOLVE_FRONT_PAGE,
              params: { bookId: this.bookId },
              query: { comments: this.showCW },
            });
          }
        } else {
          await this.$router.push({ name: APP_PAGES.WORKSPACE });
        }
      } else {
        await this.$router.push({ name: APP_PAGES.WORKSPACE });
      }
    },
    async loadBook(bookId) {
      const response = await API.BooksService.getBook(bookId);

      if (response.success) {
        this.book = response.data;
      }
      return response.success;
    },
    async loadPage(bookId, pageId) {
      const response = await API.PagesService.getPage(bookId, pageId, {
        includeMarks: false,
        includeComments: true,
        includeAttempts: true,
      });
      if (response.success) {
        this.pages = _map(
          this.pages,
          (page) => {
            if (page.id === pageId) {
              return new Page({
                ...response.data,
                orderOfAnswers: page.orderOfAnswers,
              });
            }
            return page;
          },
        );
      }
      return response.success;
    },
    async loadBookPages(bookId) {
      const response = await API.PagesService.getPages(bookId, {
        includeMarks: false,
        includeComments: true,
        includeAttempts: true,
      });
      if (response.success) {
        this.pages = response.data;
        this.clearAttempts(this.currentIndexPage);
      }
      return response.success;
    },
    async trackAttempts(page, answerWithUnit) {
      let answer;
      let unit;
      switch (answerWithUnit) {
        case page.rightAnswerWithUnit:
          answer = page.rightAnswer;
          unit = page.rightAnswerUnit;
          break;
        case page.firstWrongAnswerWithUnit:
          answer = page.firstWrongAnswer;
          unit = page.firstWrongAnswerUnit;
          break;
        case page.secondWrongAnswerWithUnit:
          answer = page.secondWrongAnswer;
          unit = page.secondWrongAnswerUnit;
          break;
        case page.thirdWrongAnswerWithUnit:
          answer = page.thirdWrongAnswer;
          unit = page.thirdWrongAnswerUnit;
          break;
        default:
          throw new RangeError();
      }

      const result = await API.AttemptsService.createAttempt(this.book.id, page.id, answer, unit);
      if (!result.success) {
        // TODO: make better error handling there
        console.log('Could not save attempt');
      }
    },
    openBookEditMode() {
      if (this.isViewFrontPage || this.isViewBackPage) {
        this.$router.push({
          name: APP_PAGES.EDIT_FRONT_PAGE,
          params: { bookId: this.bookId },
          query: { comments: this.showCW },
        });
      } else {
        this.$router.push({
          name: APP_PAGES.EDIT_PAGES,
          params: {
            bookId: this.bookId,
            pageNumber: this.currentIndexPage + 1,
            step: _get(this.page, 'currentEditStep', 5),
          },
          query: { comments: this.showCW },
        });
      }
    },
    openBookShelf() {
      this.$router.push({ name: APP_PAGES.WORKSPACE });
    },
    async onAnswerSelected(answerWithUnit) {
      this.pages[this.currentIndexPage].attempts.push(answerWithUnit);
      if (this.sessionRole !== USER_ROLE.TEACHER && +this.sessionUserId !== +this.book.userId) {
        await this.trackAttempts(this.page, answerWithUnit);
      }
    },
    goToPage(pageIndex) {
      this.$router.push({
        name: APP_PAGES.SOLVE_BOOK,
        params: {
          bookId: this.bookId,
          pageNumber: +pageIndex + 1,
        },
        query: { comments: this.showCW },
      });
    },
    async onPageTextToSpeech() {
      await this.textToSpeech(this.exerciseText);
    },
    async onPageHintToSpeech() {
      await this.textToSpeech(this.page.promptAnswer);
    },
    clearAttempts(pageIndex) {
      const page = this.pages[pageIndex];
      if (page && this.isClearAttempts) {
        page.attempts = [];
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/desktop/pages/solveBook.scss";
</style>
