import existPageText from '@/mixins/existPageText';

export default {
  mixins: [
    existPageText,
  ],
  methods: {
    getPageMaxStep(page) {
      switch (true) {
        case !page:
          return 1;
        case !page.imageSource:
          return 1;
        case !this.existPageText(page) && !page.audioSource:
          return 2;
        case !page.rightAnswers.every((item) => item):
          return 3;
        case !(page.firstWrongAnswer && page.secondWrongAnswer && page.thirdWrongAnswer):
          return 4;
        default:
          return 5;
      }
    },
  },
};
