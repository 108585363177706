<template>
  <div class="settings__option">
    <div class="settings__option-label">
      <md-icon class="md-primary settings__option-icon">
        {{ icon }}
      </md-icon>
      <span class="settings__option-text">
        {{ text }}
      </span>
    </div>
    <md-switch
      v-model="model"
      class="md-primary"
    />
    <md-tooltip
      v-if="tooltip"
      md-direction="top"
    >
      {{ tooltip }}
    </md-tooltip>
  </div>
</template>

<script>
import localizationMixin from '@/mixins/localizationMixin';

/**
 * @emit input
 */
export default {
  name: 'SettingsOption',
  mixins: [localizationMixin],
  props: {
    icon: {
      type: String,
      default: '',
      required: false,
    },
    text: {
      type: String,
      default: '',
      required: false,
    },
    tooltip: {
      type: String,
      default: '',
      required: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/pages/settings.scss";
</style>
