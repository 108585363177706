import request from '@/api/httpClient';
import Comment from '@/api/models/Comment';
import ErrorResponse from '@/api/models/ErrorResponse';

export class CommentsService {
  static async addComment(bookId, pageId, comment = {}) {
    try {
      const result = await request.postRequest(
        `/books/${bookId}/pages/${pageId}/comments`,
        new Comment(comment).toApi(),
        {},
      ).then((response) => {
        const data = Comment.fromApi(response.data);
        return { success: true, data };
      }).catch((e) => {
        const error = ErrorResponse.fromApi(e.response.data);
        return { success: false, error };
      });
      return result;
    } catch (e) {
      return { success: false };
    }
  }

  static async deleteComment(bookId, pageId, commentId) {
    try {
      const result = await request.deleteRequest(
        `/books/${bookId}/pages/${pageId}/comments/${commentId}`,
      ).then(() => ({ success: true })).catch((e) => {
        const error = ErrorResponse.fromApi(e.response.data);
        return { success: false, error };
      });
      return result;
    } catch (e) {
      return { success: false };
    }
  }
}
