import {
  SET_ACTIVE_FILE_DIALOG_TAB,
  SET_IMAGE_BANK_TAB_ID_CHAIN,
  SET_SHOW_MY_BOOKS,
  USER_SETTINGS_RESET_STATE,
} from '@/store/modules/userSettings/mutationTypes';
import getInitialState from '@/store/modules/userSettings/getInitialState';

export default ({
  [SET_SHOW_MY_BOOKS](state, value) {
    state.showMyBooks = value;
  },
  [SET_ACTIVE_FILE_DIALOG_TAB](state, value) {
    state.activeFileDialogTab = value;
  },
  [SET_IMAGE_BANK_TAB_ID_CHAIN](state, value) {
    state.imageBankTabIdChain = value;
  },
  [USER_SETTINGS_RESET_STATE](state) {
    Object.assign(state, getInitialState());
  },
});
