export default {
  methods: {
    getBackgroundTint(colorSets, backgroundColor, defaultTint) {
      const defaultValue = defaultTint || 'black';
      if (!colorSets || !backgroundColor) {
        return defaultValue;
      }
      const lowerCaseColor = backgroundColor.toLowerCase();
      const colorSet = colorSets.find((item) => item.color.toLowerCase() === lowerCaseColor);

      return colorSet ? colorSet.textColor : defaultValue;
    },
  },
};
