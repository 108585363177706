<template>
  <div class="recorder">
    <span class="recorder__title">
      {{ getLocalize('recorder.recorderTitle') }}
    </span>
    <div
      ref="recorder"
      class="recorder__body"
    >
      <div v-if="recordMode">
        <vue-ellipse-progress
          v-if="isRecord"
          :legend="false"
          :progress="progress"
          animation="default 0 0"
          :size="progressSize"
          class="recorder__progress"
        />
        <md-button
          v-if="!isRecord"
          :disabled="isDisabled"
          class="recorder__button md-icon-button md-raised md-accent"
          @click="doRecord"
        >
          <md-icon>keyboard_voice</md-icon>
        </md-button>
        <md-button
          v-if="isRecord"
          :disabled="isDisabled"
          class="recorder__button md-icon-button md-raised md-primary"
          @click="doStopRecord"
        >
          <md-icon>stop</md-icon>
        </md-button>
      </div>
      <div v-else>
        <md-button
          v-if="isPlay"
          :disabled="isDisabled"
          class="recorder__button md-icon-button md-raised md-primary"
          @click="doStopAudio"
        >
          <md-icon>stop</md-icon>
        </md-button>
        <md-button
          v-else
          :disabled="isDisabled"
          class="recorder__button md-icon-button md-raised md-primary"
          @click="doPlayAudio"
        >
          <md-icon>play_arrow</md-icon>
        </md-button>
        <md-button
          :disabled="isPlay || isDisabled"
          class="recorder__button recorder__button_delete md-icon-button md-raised"
          @click="doDeleteAudio"
        >
          <md-icon class="main-theme-icon">
            delete
          </md-icon>
        </md-button>
      </div>
      <audio
        ref="audioControl"
        :src="localAudioSource"
        class="recorder_hide"
        @ended="onEndedPlay"
      />
    </div>
  </div>
</template>

<script>
/**
 * @emit onAudioFileUpdated - audio file updated
 * @emit onAudioFileDeleted - audio file deleted
 */
export default {
  name: 'Recorder',
  props: {
    audioSource: {
      type: String,
      default: '',
    },
    maxRecordSeconds: {
      type: Number,
      default: 30,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    getLocalize: {
      type: Function,
      default: (key) => key,
      required: false,
    },
  },
  data() {
    return {
      isRecord: false,
      isPlay: false,
      isCancelRecord: false,
      audioStream: null,
      mediaRecorder: null,
      localAudioSource: this.audioSource,
      progress: 0,
      progressSize: 0,
      progressTimerId: null,
      recordTimerId: null,
      recordMode: !this.audioSource,
    };
  },
  watch: {
    audioSource(newValue) {
      this.localAudioSource = newValue;
      this.recordMode = !newValue;
    },
  },
  beforeDestroy() {
    if (this.mediaRecorder) {
      if (this.mediaRecorder.state !== 'inactive') {
        this.mediaRecorder.stop();
      }
      this.audioStream.getTracks().forEach((track) => track.stop());
      this.audioStream = null;
      this.$refs.audioControl.pause();
    }
  },
  methods: {
    async doRecord() {
      if (!this.mediaRecorder && !await this.initMediaRecorder()) return;

      try {
        this.mediaRecorder.start();
      } catch {
        this.mediaRecorder = null;
        if (!await this.initMediaRecorder()) return;

        this.mediaRecorder.start();
      }

      this.isRecord = true;

      this.recordTimerId = setTimeout(() => {
        this.mediaRecorder.stop();
      }, this.maxRecordSeconds * 1000);

      this.startProgress(this.maxRecordSeconds);
    },
    doStopRecord() {
      this.mediaRecorder.stop();
      this.audioStream.getTracks().forEach((track) => track.stop());
      this.audioStream = null;
    },
    doPlayAudio() {
      this.isPlay = true;
      this.$refs.audioControl.play();
    },
    doStopAudio() {
      this.$refs.audioControl.pause();
      this.$refs.audioControl.currentTime = 0;
      this.isPlay = false;
    },
    doDeleteAudio() {
      this.localAudioSource = null;
      this.recordMode = true;
      this.$emit('onAudioFileDeleted');
    },
    onEndedPlay() {
      this.isPlay = false;
    },
    onMediaRecorderStop() {
      this.isRecord = false;

      clearTimeout(this.recordTimerId);
      this.stopProgress();
    },
    onMediaRecorderDataAvailable(e) {
      if (this.isCancelRecord) {
        this.isCancelRecord = false;
      } else {
        const chunks = [];
        chunks.push(e.data);
        const blob = new Blob(chunks, { type: 'audio/ogg; codecs=opus' });
        this.$emit('onAudioFileUpdated', new File([blob], 'audio.ogg'));
        this.localAudioSource = window.URL.createObjectURL(blob);
        this.recordMode = false;
      }
    },
    async initMediaRecorder() {
      if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) return false;

      try {
        const constraints = { audio: true };
        this.audioStream = await navigator.mediaDevices.getUserMedia(constraints);
        this.mediaRecorder = new MediaRecorder(this.audioStream);
        this.mediaRecorder.ondataavailable = this.onMediaRecorderDataAvailable;
        this.mediaRecorder.onstop = this.onMediaRecorderStop;
        return true;
      } catch (e) {
        return false;
      }
    },
    startProgress(seconds) {
      this.progress = 0;
      const startTime = Date.now();
      this.progressSize = this.$refs.recorder && this.$refs.recorder.clientHeight + 1;

      this.progressTimerId = setInterval(() => {
        const maxTime = seconds * 1000;
        const time = Date.now() - startTime;

        if (maxTime > time) {
          // eslint-disable-next-line no-mixed-operators
          this.progress = time / maxTime * 100;
        } else {
          this.stopProgress();
        }
      }, 100);
    },
    stopProgress() {
      clearInterval(this.progressTimerId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/recorder.scss";
</style>
