import { LOAD_WORKSPACE, SAVE_WORKSPACE } from '@/store/modules/workspace/actionTypes';
import { UPDATE_WORKSPACE } from '@/store/modules/workspace/mutationTypes';
import { API } from '@/api/api';
import Workspace from '@/api/models/Workspace';

export default ({
  async [LOAD_WORKSPACE]({ commit }) {
    const response = await API.WorkspaceService.getWorkspace();
    if (response.success) {
      commit(UPDATE_WORKSPACE, response.data);
    }
  },
  async [SAVE_WORKSPACE]({ commit }, workspace) {
    const response = await API.WorkspaceService.updateWorkspace(
      workspace.id,
      new Workspace(workspace),
    );
    if (response.success) {
      commit(UPDATE_WORKSPACE, response.data);
    }
  },
});
