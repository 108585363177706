<template>
  <div class="switch">
    <label
      class="off-label md-switch-label"
      :class="{ 'active': !localValue }"
      @click="offClick"
    >
      <md-icon>{{ offIcon }}</md-icon>
      <span class="label-text">
        {{ offLabel }}
      </span>
    </label>
    <md-switch
      v-model="localValue"
      class="md-primary switch-on"
    />
    <label
      class="on-label md-switch-label"
      :class="{ 'active': localValue }"
      @click="onClick"
    >
      <md-icon>{{ onIcon }}</md-icon>
      <span class="label-text">
        {{ onLabel }}
      </span>
    </label>
  </div>
</template>

<script>

/**
 * @emit input - value was updated
 */
export default {
  name: 'SwitchTwoLabel',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    onLabel: {
      type: String,
      required: true,
    },
    onIcon: {
      type: String,
      required: true,
    },
    offLabel: {
      type: String,
      required: true,
    },
    offIcon: {
      type: String,
      required: true,
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onClick() {
      this.$emit('input', true);
    },
    offClick() {
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/switchTwoLabel.scss";
</style>
