<template>
  <md-dialog
    :md-active="showDialog"
    @update:mdActive="onUpdateShowDialog($event)"
  >
    <div class="color-dialog__wrap">
      <div
        v-for="colorSet in colorsSets"
        :key="colorSet.color"
        :class="inputColorClass(colorSet)"
        :style="inputColorStyle(colorSet)"
        class="color-dialog__item"
        type="button"
        @click="onColorSelected(colorSet.color);"
      >
        {{ getLocalize(`colors.${colorSet.color}`) }}
      </div>
    </div>
  </md-dialog>
</template>

<script>
/**
 * @emit onColorSelected - color selected
 * @emit onDialogClosed - dialog closed
 */
export default {
  name: 'ColorDialog',
  model: {
    prop: 'value',
    emit: 'onColorSelected',
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    value: {
      type: String,
      default: '',
      required: false,
    },
    colorsSets: {
      type: Array,
      default: () => [],
      required: false,
    },
    getLocalize: {
      type: Function,
      default: (key) => key,
      required: false,
    },
  },
  methods: {
    onColorSelected(color) {
      this.$emit('onColorSelected', color);
      this.$emit('onDialogClosed');
    },
    onUpdateShowDialog(showDialog) {
      if (!showDialog && this.showDialog !== showDialog) {
        this.$emit('onDialogClosed');
      }
    },
    inputColorStyle(colorItem) {
      return {
        backgroundColor: colorItem.color,
        color: colorItem.textColor,
      };
    },
    inputColorClass(colorItem) {
      return {
        active: colorItem.color.toLowerCase() === this.value.toLowerCase(),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/colorDialog.scss";
</style>
