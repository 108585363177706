const ID = 'id';
const NAME = 'name';
const SHOW_IMAGE_SEARCH_IN_FILE_DIALOG = 'allowImageSearch';
const SHOW_CAMERA_IN_FILE_DIALOG = 'allowPhotoUpload';
const SHOW_UPLOAD_FILE_IN_FILE_DIALOG = 'allowFileUpload';
const SHOW_WORKSPACE_IMAGE_IN_FILE_DIALOG = 'allowWorkspaceImageGallery';
const SHOW_USER_IMAGE_IN_FILE_DIALOG = 'allowUserImageGallery';
const SMILE_COMMENTS_AVAILABLE = 'allowSmileComment';
const TEXT_COMMENTS_AVAILABLE = 'allowTextComment';

export default class Workspace {
  constructor(parameters) {
    const {
      id,
      name,
      showImageSearchInFileDialog,
      showCameraInFileDialog,
      showUploadFileInFileDialog,
      showWorkspaceImageInFileDialog,
      showUserImageInFileDialog,
      smileCommentsAvailable,
      textCommentsAvailable,
    } = parameters;
    this.id = id;
    this.name = name;
    this.showImageSearchInFileDialog = showImageSearchInFileDialog;
    this.showCameraInFileDialog = showCameraInFileDialog;
    this.showUploadFileInFileDialog = showUploadFileInFileDialog;
    this.showWorkspaceImageInFileDialog = showWorkspaceImageInFileDialog;
    this.showUserImageInFileDialog = showUserImageInFileDialog;
    this.smileCommentsAvailable = smileCommentsAvailable;
    this.textCommentsAvailable = textCommentsAvailable;
  }

  static fromApi(object = {}) {
    return new Workspace(
      {
        id: object[ID],
        name: object[NAME],
        showImageSearchInFileDialog: object[SHOW_IMAGE_SEARCH_IN_FILE_DIALOG],
        showCameraInFileDialog: object[SHOW_CAMERA_IN_FILE_DIALOG],
        showUploadFileInFileDialog: object[SHOW_UPLOAD_FILE_IN_FILE_DIALOG],
        showWorkspaceImageInFileDialog: object[SHOW_WORKSPACE_IMAGE_IN_FILE_DIALOG],
        showUserImageInFileDialog: object[SHOW_USER_IMAGE_IN_FILE_DIALOG],
        smileCommentsAvailable: object[SMILE_COMMENTS_AVAILABLE],
        textCommentsAvailable: object[TEXT_COMMENTS_AVAILABLE],
      },
    );
  }

  toApi() {
    return {
      [ID]: this.id,
      [NAME]: this.name,
      [SHOW_IMAGE_SEARCH_IN_FILE_DIALOG]: this.showImageSearchInFileDialog,
      [SHOW_CAMERA_IN_FILE_DIALOG]: this.showCameraInFileDialog,
      [SHOW_UPLOAD_FILE_IN_FILE_DIALOG]: this.showUploadFileInFileDialog,
      [SHOW_WORKSPACE_IMAGE_IN_FILE_DIALOG]: this.showWorkspaceImageInFileDialog,
      [SHOW_USER_IMAGE_IN_FILE_DIALOG]: this.showUserImageInFileDialog,
      [SMILE_COMMENTS_AVAILABLE]: this.smileCommentsAvailable,
      [TEXT_COMMENTS_AVAILABLE]: this.textCommentsAvailable,
    };
  }
}
