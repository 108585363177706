import CropArea from '@/api/models/CropArea';
import { FILE_DIALOG_TABS } from '@/helpers/const';

export default {
  props: {
    imageBankSource: {
      type: String,
      default: '',
    },
    uploadSizeImageMaxKB: {
      type: Number,
      default: 1024,
    },
    uploadImageExtensions: {
      type: Array,
      default: () => [],
    },
    showImageSearchTab: {
      type: Boolean,
      default: false,
    },
    showCameraTab: {
      type: Boolean,
      default: false,
    },
    showUploadFileTab: {
      type: Boolean,
      default: false,
    },
    showWorkspaceImageTab: {
      type: Boolean,
      default: false,
    },
    showUserImageTab: {
      type: Boolean,
      default: false,
    },
    foundImages: {
      type: Array,
      default: () => [],
    },
    imageSearchProviderName: {
      type: String,
      default: '',
    },
    imageSearchProviderLink: {
      type: String,
      default: '',
    },
    cropArea: {
      type: CropArea,
      default: null,
    },
    searchString: {
      type: String,
      default: '',
    },
    activeFileDialogTab: {
      type: String,
      default: FILE_DIALOG_TABS.IMAGE_BANK,
      validator: (value) => Object.keys(FILE_DIALOG_TABS).includes(value),
    },
    imageBankTabIdChain: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onImageSearch(search) {
      this.$emit('onImageSearch', search);
    },
    onLoadMoreSearchImages() {
      this.$emit('onLoadMoreSearchImages');
    },
    onImageSearchSourceUpdated(imageSource) {
      this.$emit('onImageSearchSourceUpdated', imageSource);
    },
    onActiveFileDialogTabUpdated(tab) {
      this.$emit('onActiveFileDialogTabUpdated', tab);
    },
    onImageBankTabIdChainUpdated(tabIdChain) {
      this.$emit('onImageBankTabIdChainUpdated', tabIdChain);
    },
  },
};
