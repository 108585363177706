import { MARKS } from '@/helpers/const';
import circleIcon from '@/assets/icons/circle.svg';
import triangleIcon from '@/assets/icons/triangle.svg';
import squareIcon from '@/assets/icons/square.svg';
import polygonIcon from '@/assets/icons/polygon.svg';
import firstCustomIcon from '@/assets/icons/firstCustom.svg';
import secondCustomIcon from '@/assets/icons/secondCustom.svg';
import thirdCustomIcon from '@/assets/icons/thirdCustom.svg';
import fourthCustomIcon from '@/assets/icons/fourthCustom.svg';

export default {
  methods: {
    getMarkImageSource(markType) {
      switch (markType) {
        case MARKS.CIRCLE:
          return circleIcon;
        case MARKS.TRIANGLE:
          return triangleIcon;
        case MARKS.SQUARE:
          return squareIcon;
        case MARKS.POLYGON:
          return polygonIcon;
        case MARKS.FIRST_CUSTOM:
          return firstCustomIcon;
        case MARKS.SECOND_CUSTOM:
          return secondCustomIcon;
        case MARKS.THIRD_CUSTOM:
          return thirdCustomIcon;
        case MARKS.FOURTH_CUSTOM:
          return fourthCustomIcon;
        default:
          throw new RangeError();
      }
    },
  },
};
