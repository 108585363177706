const X = 'x';
const Y = 'y';
const WIDTH = 'width';
const HEIGHT = 'height';

export default class CropArea {
  constructor(parameters) {
    const {
      x,
      y,
      width,
      height,
    } = parameters;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }

  static fromApi(object = {}) {
    return new CropArea(
      {
        x: object[X],
        y: object[Y],
        width: object[WIDTH],
        height: object[HEIGHT],
      },
    );
  }

  toApi() {
    return {
      [X]: Math.round(this.x),
      [Y]: Math.round(this.y),
      [WIDTH]: Math.round(this.width),
      [HEIGHT]: Math.round(this.height),
    };
  }
}
