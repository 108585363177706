import { SESSION_RESET_STATE, UPDATE_SESSION } from '@/store/modules/session/mutationTypes';
import getInitialState from '@/store/modules/session/getInitialState';

export default ({
  [UPDATE_SESSION](state, session) {
    state.userId = session.user.id;
    state.userRole = session.user.role;
    state.userFirstName = session.user.firstName;
    state.userLastName = session.user.lastName;
    state.userName = session.user.login;
  },
  [SESSION_RESET_STATE](state) {
    Object.assign(state, getInitialState());
  },
});
