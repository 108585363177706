const TYPE = 'type';
const MESSAGE = 'message';
const DATA = 'data';

export default class Error {
  constructor(parameters) {
    const {
      type,
      message,
      data,
    } = parameters;
    this.type = type;
    this.message = message;
    this.data = data;
  }

  static fromApi(object = {}) {
    return new Error(
      {
        type: object[TYPE],
        message: object[MESSAGE],
        data: object[DATA],
      },
    );
  }
}
