import {
  CHANGE_ACTIVE_FILE_DIALOG_TAB,
  CHANGE_IMAGE_BANK_TAB_ID_CHAIN,
  CHANGE_SHOW_MY_BOOKS,
} from '@/store/modules/userSettings/actionTypes';
import {
  SET_ACTIVE_FILE_DIALOG_TAB,
  SET_IMAGE_BANK_TAB_ID_CHAIN,
  SET_SHOW_MY_BOOKS,
} from '@/store/modules/userSettings/mutationTypes';

export default ({
  async [CHANGE_SHOW_MY_BOOKS]({ commit }, value) {
    commit(SET_SHOW_MY_BOOKS, value);
  },
  async [CHANGE_ACTIVE_FILE_DIALOG_TAB]({ commit }, value) {
    commit(SET_ACTIVE_FILE_DIALOG_TAB, value);
  },
  async [CHANGE_IMAGE_BANK_TAB_ID_CHAIN]({ commit }, value) {
    commit(SET_IMAGE_BANK_TAB_ID_CHAIN, value);
  },
});
