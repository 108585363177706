import { IMAGE_SOURCE } from '@/helpers/const';

export default () => ({
  [IMAGE_SOURCE.IMAGE_SEARCH]: {
    search: '',
    images: [],
    nextPageToken: null,
    areImagesLoading: false,
  },
  [IMAGE_SOURCE.WORKSPACE]: {
    search: '',
    images: [],
    nextPageToken: null,
    areImagesLoading: false,
  },
  [IMAGE_SOURCE.PERSONAL]: {
    search: '',
    images: [],
    nextPageToken: null,
    areImagesLoading: false,
  },
  imageSource: IMAGE_SOURCE.IMAGE_SEARCH,
});
