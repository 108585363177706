export default {
  computed: {
    agoFormatedDate() {
      const created = this.getDateFromString(this.created);
      if (created) {
        const today = new Date();
        const days = parseInt((today - created) / (1000 * 60 * 60 * 24), 10);
        if (days > 0) {
          return created.toLocaleString();
        }
        const hours = parseInt((Math.abs(today - created) / (1000 * 60 * 60)) % 24, 10);
        // eslint-disable-next-line max-len
        const minutes = parseInt((Math.abs(today.getTime() - created.getTime()) / (1000 * 60)) % 60, 10);
        // eslint-disable-next-line max-len
        const seconds = parseInt((Math.abs(today.getTime() - created.getTime()) / (1000)) % 60, 10);
        let result = '';
        if (hours > 0) {
          result = `${hours} ${this.getLocalize('dateFormat.hoursAgo')}`;
        } else if (minutes > 0) {
          result = `${minutes} ${this.getLocalize('dateFormat.minutesAgo')}`;
        } else if (seconds > 0) {
          result = `${seconds} ${this.getLocalize('dateFormat.secondsAgo')}`;
        } else {
          result = this.getLocalize('dateFormat.justNow');
        }
        return result;
      }
      return '';
    },
  },
  methods: {
    getDateFromString(d) {
      const timestamp = Date.parse(d);
      if (Number.isNaN(timestamp) === false) {
        return new Date(timestamp);
      }
      return undefined;
    },
  },
};
