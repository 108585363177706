<template>
  <div
    ref="comments"
    class="cw__comments"
  >
    <comments-widget-comment
      v-for="comment of comments"
      :key="comment.id"
      :text="comment.text"
      :emoji-name="comment.smile"
      :emojis="emojis"
      :author="comment.author"
      :created="comment.created"
      :class="getCommentClass(comment.id, comment.userId)"
      :get-localize="getLocalize"
      :allow-delete="canDelete(comment.userId)"
      :allow-reply="canReply(comment.userId)"
      :selected="isCommentSelected(comment.id)"
      @onCommentDeleted="onCommentDeleted(comment.id)"
      @onCommentReplied="onCommentReplied(comment.id)"
    />
  </div>
</template>

<script>
import _pull from 'lodash/pull';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import CommentsWidgetComment from './CWComment.vue';

/**
 * @emit onCommentDeleted - comment deleted
 * @emit onCommentReplied - comment replied
 */
export default {
  name: 'CommentsWidgetComments',
  components: {
    CommentsWidgetComment,
  },
  props: {
    comments: {
      type: Array,
      default: () => [],
      required: false,
    },
    userId: {
      type: Number,
      default: -1,
      required: false,
    },
    getLocalize: {
      type: Function,
      default: (key) => key,
      required: false,
    },
    allowDeleteMySelf: {
      type: Boolean,
      default: false,
      required: false,
    },
    allowDeleteAny: {
      type: Boolean,
      default: false,
      required: false,
    },
    allowReplyMySelf: {
      type: Boolean,
      default: false,
      required: false,
    },
    allowReplyAny: {
      type: Boolean,
      default: false,
      required: false,
    },
    allowReplyOther: {
      type: Boolean,
      default: false,
      required: false,
    },
    emojis: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    selectedComments: [],
  }),
  computed: {
    haveComments() {
      return this.comments && this.comments.length;
    },
  },
  methods: {
    getCommentClass(commentId, commentAuthorId) {
      const base = commentAuthorId === this.userId ? 'cw__comment_author' : 'cw__comment';
      const additional = this.isCommentSelected(commentId) ? ' cw__comment_selected' : '';
      return `${base}${additional}`;
    },
    onCommentDeleted(commentId) {
      this.$emit('onCommentDeleted', commentId);
    },
    onCommentReplied(commentId) {
      this.$emit('onCommentReplied', commentId);
    },
    canDelete(commentAuthorId) {
      return this.allowDeleteAny || (this.allowDeleteMySelf && commentAuthorId === this.userId);
    },
    canReply(commentAuthorId) {
      return this.allowReplyAny
        || (this.allowReplyMySelf && commentAuthorId === this.userId)
        || (this.allowReplyOther && commentAuthorId !== this.userId);
    },
    selectComment(commentId) {
      this.selectedComments = [...this.selectedComments, commentId];
    },
    unSelectComment(commentId) {
      _pull(this.selectedComments, commentId);
    },
    selectAllComments() {
      this.selectedComments = _map(this.comments, (comment) => comment.id);
    },
    unSelectAllComments() {
      this.selectedComments = [];
    },
    isCommentSelected(commentId) {
      return _includes(this.selectedComments, commentId);
    },
    scrollToBottom() {
      const div = this.$refs.comments;
      div.scrollTop = div.scrollHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/commentsWidget.scss";
</style>
