import { MESSAGES_ENQUEUE_TOAST_MESSAGE } from '@/store/modules/messages/actionTypes';

export default {
  methods: {
    async toggleToast(message, ...tasks) {
      const resultTask = Promise.all(tasks);
      const toastTask = new Promise((resolve) => {
        resultTask.finally(() => resolve());
      });
      await this.$store.dispatch(MESSAGES_ENQUEUE_TOAST_MESSAGE, { message, tasks: [toastTask] });
      const result = await resultTask;
      return result;
    },
    async displayToast(message) {
      await this.$store.dispatch(
        MESSAGES_ENQUEUE_TOAST_MESSAGE,
        { message, showCloseButton: true },
      );
    },
  },
};
