<template>
  <div class="answer-box">
    <validation-provider
      v-slot="{errors}"
      :rules="{required: true, min: 1, max: 25, is_not: notRightAnswer, excluded: notWrongAnswers }"
      class="answer-input_wrong"
    >
      <default-input
        ref="input"
        :value="wrongAnswer"
        :label="getLocalize('book.wrong-answer')"
        :validation="errors"
        :maxlength="25"
        clearable
        @input="onAnswerUpdated"
      />
    </validation-provider>
    <validation-provider
      v-if="showUnitSelector"
      v-slot="{errors}"
      :rules="{required: true, min: 1, max: 10 }"
      class="answer-input_selector"
    >
      <md-autocomplete
        ref="autocomplete"
        :class="errors && errors.length && 'md-invalid'"
        :value="wrongAnswerUnit"
        :md-options="unitNames"
        :md-input-maxlength="10"
        md-dense
        :md-fuzzy-search="false"
        autocomplete="off"
        @input="onAnswerUnitUpdated"
        @md-opened="onOpenAutocompleteOptions"
      >
        <label>{{ getLocalize('book.unit') }}</label>
        <span
          v-for="error of errors"
          :key="error"
          class="md-error"
        >
          {{ error }}
        </span>
      </md-autocomplete>
    </validation-provider>
  </div>
</template>

<script>
import _get from 'lodash/get';
import { extend, ValidationProvider } from 'vee-validate';
import {
  max, min, required, is_not as isNot, excluded,
} from 'vee-validate/dist/rules';
import _invoke from 'lodash/invoke';
import DefaultInput from '@/components/inputs/input.vue';
import localizationMixin from '@/mixins/localizationMixin';
import unitMixin from '@/mixins/unitMixin';

/**
 * @emit onWrongAnswerUpdated
 * @emit onWrongAnswerUnitUpdated
 * @emit onOpenAutocompleteOptions
 */
export default {
  name: 'WrongAnswerBox',
  components: {
    DefaultInput,
    ValidationProvider,
  },
  mixins: [
    localizationMixin,
    unitMixin,
  ],
  props: {
    wrongAnswer: {
      type: String,
      default: '',
      required: false,
    },
    wrongAnswerUnit: {
      type: String,
      default: '',
      required: false,
    },
    rightAnswer: {
      type: String,
      default: '',
      required: false,
    },
    rightAnswerUnit: {
      type: String,
      default: '',
      required: false,
    },
    unitGroup: {
      type: Object,
      default: () => {},
    },
    otherWrongAnswers: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    showUnitSelector() {
      return !!_get(this, 'unitGroup.units.length');
    },
    unitNames() {
      return _invoke(this, 'unitGroup.units.map', (i) => i.name) || [];
    },
    notRightAnswer() {
      if (this.checkAnswerOnlyNumber(this.rightAnswer)
        && this.rightAnswerUnit
        && this.wrongAnswerUnit
      ) {
        const number = this.convertAnswerNumber(
          this.rightAnswer,
          this.rightAnswerUnit,
          this.wrongAnswerUnit,
          this.unitGroup,
        );

        return `${number}`;
      }
      return this.rightAnswer;
    },
    notWrongAnswers() {
      return this.otherWrongAnswers
        .filter(({ unit }) => !this.wrongAnswerUnit || unit === this.wrongAnswerUnit)
        .map(({ answer }) => answer);
    },
  },
  created() {
    extend('required', {
      ...required,
      message: this.getLocalize('validation.required'),
    });
    extend('min', {
      ...min,
      message: this.getLocalize('validation.min'),
    });
    extend('max', {
      ...max,
      message: this.getLocalize('validation.max'),
    });
    extend('is_not', {
      ...isNot,
      message: this.getLocalize('validation.isNotRightAnswer'),
    });
    extend('excluded', {
      ...excluded,
      message: this.getLocalize('validation.isNotWrongAnswer'),
    });

    this.$nextTick(() => {
      if (this.$refs.autocomplete) {
        this.$refs.autocomplete.filterByString = () => this.$refs.autocomplete.mdOptions;
      }
    });
  },
  methods: {
    onAnswerUpdated(answer) {
      this.$emit('onWrongAnswerUpdated', answer);
    },
    onAnswerUnitUpdated(answerUnit) {
      if (answerUnit !== undefined) {
        this.$emit('onWrongAnswerUnitUpdated', answerUnit);
      }
    },
    onOpenAutocompleteOptions() {
      this.$emit('onOpenAutocompleteOptions');
    },
    hideAutocompleteOptions() {
      this.$refs.autocomplete.hideOptions();
      this.$refs.autocomplete.showMenu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/wrongAnswerBox.scss";
</style>
