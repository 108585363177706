import _get from 'lodash/get';
import getInitialState from '@/store/modules/notifications/getInitialState';
import { EVET_TYPES } from '@/helpers/const';
import mutations from './mutations';
import actions from './actions';

export default ({
  state: getInitialState,
  getters: {
    showNotifications: (notificationState, getters, state) => {
      const smileCommentsAvailable = _get(state, 'workspace.smileCommentsAvailable');
      const textCommentsAvailable = _get(state, 'workspace.textCommentsAvailable');
      const notifications = _get(notificationState, 'notifications') || [];

      if (smileCommentsAvailable === false && textCommentsAvailable === false) {
        return notifications.filter((item) => item.type !== EVET_TYPES.NEW_COMMENT);
      }

      return notifications;
    },
  },
  mutations: {
    ...mutations,
  },
  actions: {
    ...actions,
  },
});
