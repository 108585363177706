import { UPDATE_WORKSPACE, WORKSPACE_RESET_STATE } from '@/store/modules/workspace/mutationTypes';
import getInitialState from '@/store/modules/workspace/getInitialState';

export default ({
  [UPDATE_WORKSPACE](state, workspace) {
    state.id = workspace.id;
    state.name = workspace.name;
    state.showImageSearchInFileDialog = workspace.showImageSearchInFileDialog;
    state.showCameraInFileDialog = workspace.showCameraInFileDialog;
    state.showUploadFileInFileDialog = workspace.showUploadFileInFileDialog;
    state.showWorkspaceImageInFileDialog = workspace.showWorkspaceImageInFileDialog;
    state.showUserImageInFileDialog = workspace.showUserImageInFileDialog;
    state.smileCommentsAvailable = workspace.smileCommentsAvailable;
    state.textCommentsAvailable = workspace.textCommentsAvailable;
  },
  [WORKSPACE_RESET_STATE](state) {
    Object.assign(state, getInitialState());
  },
});
