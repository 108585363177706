import Vue from 'vue';
import Vuex from 'vuex';
import workspace from '@/store/modules/workspace';
import imageSearch from '@/store/modules/imageSearch';
import session from '@/store/modules/session';
import notifications from '@/store/modules/notifications';
import messages from '@/store/modules/messages';
import userSettings from '@/store/modules/userSettings';
import actions from '@/store/actions';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    workspace,
    imageSearch,
    session,
    notifications,
    messages,
    userSettings,
  },
  actions: {
    ...actions,
  },
});
