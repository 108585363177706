const ID = 'id';
const WORKSPACE_ID = 'workspaceId';
const LOGIN = 'login';
const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';
const ROLE = 'role';

export default class SessionUser {
  constructor(parameters) {
    const {
      id,
      workspaceId,
      login,
      firstName,
      lastName,
      role,
    } = parameters;
    this.id = id;
    this.workspaceId = workspaceId;
    this.login = login;
    this.firstName = firstName;
    this.lastName = lastName;
    this.role = role;
  }

  static fromApi(object = {}) {
    return new SessionUser(
      {
        id: object[ID],
        workspaceId: object[WORKSPACE_ID],
        login: object[LOGIN],
        firstName: object[FIRST_NAME],
        lastName: object[LAST_NAME],
        role: object[ROLE],
      },
    );
  }

  toApi() {
    return {
      [ID]: this.id,
      [WORKSPACE_ID]: this.workspaceId,
      [LOGIN]: this.login,
      [FIRST_NAME]: this.firstName,
      [LAST_NAME]: this.lastName,
      [ROLE]: this.role,
    };
  }
}
