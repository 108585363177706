import Vue from 'vue';
import VueMaterial from 'vue-material';
import VueEllipseProgress from 'vue-ellipse-progress';
import i18n from './i18n';
import 'vue-material/dist/vue-material.min.css';
import '@/assets/styles/themes/material.scss';
import 'vue-advanced-cropper/dist/style.css';
import App from './App.vue';
import router from './router';
import store from './store';
import 'moment/locale/ru';
import 'moment/locale/da';

Vue.use(VueEllipseProgress);

Vue.use(VueMaterial);

Vue.material.locale.dateFormat = 'dd/MM/yyyy';

// change multiple options
Vue.material = {
  ...Vue.material,
  locale: {
    ...Vue.material.locale,
    dateFormat: 'dd/MM/yyyy',
    firstDayOfAWeek: 1,
  },
};

Vue.config.productionTip = false;

Vue.config.errorHandler = (err) => {
  if (process.env.NODE_ENV !== 'production') {
    // Hide vue-material warning
    // https://github.com/vuematerial/vue-material/issues/1863
    // https://github.com/vuematerial/vue-material/issues/2285
    if (err.message !== 'slot[0].text is undefined'
      && err.message !== 'this.$el.validity is undefined') {
      console.error(err.message);
    }
  }
};

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
