import _get from 'lodash/get';
import _map from 'lodash/map';
import _invokeMap from 'lodash/invokeMap';
import _invoke from 'lodash/invoke';
import _flatMap from 'lodash/flatMap';
import _shuffle from 'lodash/shuffle';
import _trim from 'lodash/trim';
import CropArea from '@/api/models/CropArea';
import MarkCollection from '@/api/models/MarkCollection';
import CustomMarkName from '@/api/models/CustomMarkName';
import Attempt from '@/api/models/Attempt';
import Comment from '@/api/models/Comment';

const ID = 'id';
const NUMBER = 'ordinalNumber';
const TEXT = 'text';
const RIGHT_ANSWER = 'rightAnswer';
const FIRST_WRONG_ANSWER = 'firstWrongAnswer';
const SECOND_WRONG_ANSWER = 'secondWrongAnswer';
const THIRD_WRONG_ANSWER = 'thirdWrongAnswer';
const RIGHT_ANSWER_UNIT = 'rightAnswerUnitMeasure';
const FIRST_WRONG_ANSWER_UNIT = 'firstWrongAnswerUnitMeasure';
const SECOND_WRONG_ANSWER_UNIT = 'secondWrongAnswerUnitMeasure';
const THIRD_WRONG_ANSWER_UNIT = 'thirdWrongAnswerUnitMeasure';
const HINT = 'prompt';
const IMAGE_SOURCE = 'pictureUrl';
const VISIBLE_AREA = 'visibleArea';
const AUDIO_SOURCE = 'audioUrl';
const CURRENT_EDIT_STEP = 'currentEditStep';
const MARK_COLLECTIONS = 'marks';
const ATTEMPTS = 'attempts';
const COMMENTS = 'comments';

export default class Page {
  constructor(parameters) {
    const {
      id,
      number,
      exerciseText,
      rightAnswer,
      firstWrongAnswer,
      secondWrongAnswer,
      thirdWrongAnswer,
      rightAnswerUnit,
      firstWrongAnswerUnit,
      secondWrongAnswerUnit,
      thirdWrongAnswerUnit,
      promptAnswer,
      imageSource,
      visibleArea,
      audioSource,
      currentEditStep,
      attempts,
      comments,
      orderOfAnswers,
    } = parameters;
    this.id = id;
    this.number = number;
    this.exerciseText = exerciseText;
    this.rightAnswer = rightAnswer;
    this.firstWrongAnswer = firstWrongAnswer;
    this.secondWrongAnswer = secondWrongAnswer;
    this.thirdWrongAnswer = thirdWrongAnswer;
    this.rightAnswerUnit = rightAnswerUnit;
    this.firstWrongAnswerUnit = firstWrongAnswerUnit;
    this.secondWrongAnswerUnit = secondWrongAnswerUnit;
    this.thirdWrongAnswerUnit = thirdWrongAnswerUnit;
    this.promptAnswer = promptAnswer;
    this.imageSource = imageSource;
    this.visibleArea = visibleArea && new CropArea(visibleArea);
    this.audioSource = audioSource;
    this.currentEditStep = currentEditStep;
    this.markCollections = _invoke(
      parameters,
      'markCollections.map',
      (item) => new MarkCollection(item),
    ) || [];
    this.attempts = attempts;
    this.comments = comments;
    this.orderOfAnswers = orderOfAnswers || _shuffle([1, 2, 3, 4]);
  }

  get rightAnswerWithUnit() {
    return _trim(this.rightAnswerUnit) ? `${this.rightAnswer} ${this.rightAnswerUnit}` : this.rightAnswer;
  }

  get firstWrongAnswerWithUnit() {
    return _trim(this.firstWrongAnswerUnit) ? `${this.firstWrongAnswer} ${this.firstWrongAnswerUnit}` : this.firstWrongAnswer;
  }

  get secondWrongAnswerWithUnit() {
    return _trim(this.secondWrongAnswerUnit) ? `${this.secondWrongAnswer} ${this.secondWrongAnswerUnit}` : this.secondWrongAnswer;
  }

  get thirdWrongAnswerWithUnit() {
    return _trim(this.thirdWrongAnswerUnit) ? `${this.thirdWrongAnswer} ${this.thirdWrongAnswerUnit}` : this.thirdWrongAnswer;
  }

  get rightAnswers() {
    return [this.rightAnswerWithUnit];
  }

  get wrongAnswers() {
    return [
      this.firstWrongAnswerWithUnit,
      this.secondWrongAnswerWithUnit,
      this.thirdWrongAnswerWithUnit,
    ];
  }

  get shuffleAnswers() {
    return this.orderOfAnswers.map((number) => {
      switch (number) {
        case 1:
          return this.rightAnswerWithUnit;
        case 2:
          return this.firstWrongAnswerWithUnit;
        case 3:
          return this.secondWrongAnswerWithUnit;
        case 4:
          return this.thirdWrongAnswerWithUnit;
        default:
          throw new RangeError();
      }
    });
  }

  get marks() {
    return _flatMap(
      this.markCollections,
      (item) => _get(item, 'marks') || [],
    );
  }

  get customMarkNames() {
    return _map(
      this.markCollections,
      (item) => new CustomMarkName({
        name: item.name,
        type: item.type,
      }),
    );
  }

  static fromApi(object = {}) {
    return new Page(
      {
        id: object[ID],
        number: object[NUMBER],
        exerciseText: object[TEXT],
        rightAnswer: object[RIGHT_ANSWER],
        firstWrongAnswer: object[FIRST_WRONG_ANSWER],
        secondWrongAnswer: object[SECOND_WRONG_ANSWER],
        thirdWrongAnswer: object[THIRD_WRONG_ANSWER],
        rightAnswerUnit: object[RIGHT_ANSWER_UNIT],
        firstWrongAnswerUnit: object[FIRST_WRONG_ANSWER_UNIT],
        secondWrongAnswerUnit: object[SECOND_WRONG_ANSWER_UNIT],
        thirdWrongAnswerUnit: object[THIRD_WRONG_ANSWER_UNIT],
        promptAnswer: object[HINT],
        imageSource: object[IMAGE_SOURCE],
        visibleArea: object[VISIBLE_AREA] && CropArea.fromApi(object[VISIBLE_AREA]),
        audioSource: object[AUDIO_SOURCE],
        currentEditStep: object[CURRENT_EDIT_STEP],
        markCollections: _invoke(
          object,
          [MARK_COLLECTIONS, 'map'],
          MarkCollection.fromApi,
        ) || [],
        attempts: _invoke(
          object,
          [ATTEMPTS, 'map'],
          (item) => {
            const attempt = Attempt.fromApi(item);
            return attempt.unit ? `${attempt.answer} ${attempt.unit}` : attempt.answer;
          },
        ) || [],
        comments: _invoke(
          object,
          [COMMENTS, 'map'],
          Comment.fromApi,
        ),
      },
    );
  }

  toApi() {
    return {
      [ID]: this.id,
      [NUMBER]: this.number,
      [TEXT]: this.exerciseText,
      [RIGHT_ANSWER]: this.rightAnswer,
      [FIRST_WRONG_ANSWER]: this.firstWrongAnswer,
      [SECOND_WRONG_ANSWER]: this.secondWrongAnswer,
      [THIRD_WRONG_ANSWER]: this.thirdWrongAnswer,
      [RIGHT_ANSWER_UNIT]: this.rightAnswerUnit,
      [FIRST_WRONG_ANSWER_UNIT]: this.firstWrongAnswerUnit,
      [SECOND_WRONG_ANSWER_UNIT]: this.secondWrongAnswerUnit,
      [THIRD_WRONG_ANSWER_UNIT]: this.thirdWrongAnswerUnit,
      [IMAGE_SOURCE]: this.imageSource,
      [VISIBLE_AREA]: _invoke(this.visibleArea, 'toApi'),
      [AUDIO_SOURCE]: this.audioSource,
      [CURRENT_EDIT_STEP]: this.currentEditStep,
      [HINT]: this.promptAnswer,
      [MARK_COLLECTIONS]: _invokeMap(this.markCollections, 'toApi'),
    };
  }
}
