import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _get from 'lodash/get';
import _some from 'lodash/some';
import _invoke from 'lodash/invoke';
import sessionMixin from '@/mixins/sessionMixin';
import { API } from '@/api/api';

export default {
  mixins: [sessionMixin],
  data: () => ({
    maxCommentLength: Number.parseInt(process.env.VUE_APP_COMMENTS_WIDGET_MAX_LENGTH, 10),
  }),
  computed: {
    showCW() {
      const comments = _invoke(this.$route.query, 'comments.toString') || 'false';
      return comments.toLowerCase() === 'true';
    },
    smileCommentsAvailable() {
      return this.$store.state.workspace.smileCommentsAvailable;
    },
    textCommentsAvailable() {
      return this.$store.state.workspace.textCommentsAvailable;
    },
    pageComments() {
      return this.getPageComments(this.book, this.page, this.isViewFrontPage);
    },
    allowDeleteAnyComments() {
      return this.canUserDeleteAnyComments(this.book);
    },
    allowReplyOnComment() {
      return this.canUserReplyOnComment(this.book);
    },
    commentActivityAvailable() {
      return this.book && (this.isViewFrontPage || this.page);
    },
  },
  methods: {
    showCWUpdated(newValue) {
      this.$router.replace({
        ...this.$route,
        query: {
          ...this.$route.query,
          comments: newValue,
        },
      });
    },
    formatComments(comments) {
      const commentsMap = _map(comments || [], (comment) => {
        const replies = this.formatComments(comment.replies);
        return [comment, ...replies];
      });
      return _reduce(commentsMap, (result, item) => [...result, ...item], []);
    },
    getPageComments(book, page, isViewFrontPage) {
      if (isViewFrontPage) {
        return this.formatComments(_get(book, 'comments', []));
      }
      const result = this.formatComments(_get(page, 'comments', []));
      return result;
    },
    isBookAuthor(book) {
      return (book && book.userId === this.sessionUserId);
    },
    canUserDeleteAnyComments(book) {
      return this.isBookAuthor(book) || this.isTeacher;
    },
    canUserReplyOnComment(book) {
      return this.isBookAuthor(book) || this.isTeacher;
    },
    async onCommentDeleted(commentId) {
      if (this.commentActivityAvailable) {
        const response = await API.CommentsService.deleteComment(
          this.book.id,
          this.isViewFrontPage ? 'frontpage' : this.page.id,
          commentId,
        );
        if (response.success) {
          await this.updateDataAfterCommentActivity();
        } else {
          this.displayToast(this.getLocalize('errors.somethingWrong'));
        }
      }
    },
    async onCommentTextAdded(text, parentCommentId) {
      if (this.commentActivityAvailable && text && text.trim().length > 0) {
        const response = await API.CommentsService.addComment(
          this.book.id,
          this.isViewFrontPage ? 'frontpage' : this.page.id,
          { parentCommentId, text },
        );
        await this.processResponse(response);
      }
    },
    async onCommentEmojiAdded(emojiName, parentCommentId) {
      if (this.commentActivityAvailable && emojiName && emojiName.trim().length > 0) {
        const response = await API.CommentsService.addComment(
          this.book.id,
          this.isViewFrontPage ? 'frontpage' : this.page.id,
          { parentCommentId, smile: emojiName },
        );
        await this.processResponse(response);
      }
    },
    async processResponse(response) {
      if (response.success) {
        await this.updateDataAfterCommentActivity();
        _invoke(this.$refs, 'commentsWidget.scrollToBottom');
      } else if (response.error && response.error.errors) {
        if (_some(response.error.errors, (e) => e.type === 'PageCommentsOutsideOfAllowLimit')) {
          await this.displayToast(this.getLocalize('errors.tooMuchComments'));
        } else {
          await this.displayToast(this.getLocalize('errors.somethingWrong'));
        }
      } else {
        await this.displayToast(this.getLocalize('errors.somethingWrong'));
      }
    },
  },
};
