<template>
  <md-button
    :class="classList"
    :disabled="isDisabled"
    v-on="$listeners"
  >
    <div
      :class="contentClasses"
      class="b-button-wrap"
    >
      <md-icon v-if="icon && icon.length > 0">
        {{ icon }}
      </md-icon>
      <md-icon
        v-else-if="iconSrc && iconSrc.length > 0"
        :md-src="iconLink(iconSrc)"
      />
      <p>{{ title }}</p>
    </div>
  </md-button>
</template>

<script>
export default {
  name: 'CustomButton',
  props: {
    type: {
      type: String,
      default: 'primary-filled',
    },
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    iconSrc: {
      type: String,
      default: '',
    },
    block: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'medium',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
    showShadow: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    classList() {
      return {
        'b-button--filled': this.type === 'primary-filled' || this.type === 'filled',
        'b-button--filled-white': this.type === 'white-filled',
        'b-button--outlined': this.type === 'outlined',
        'b-button--small': this.size === 'small',
        'b-button--medium': this.size === 'medium',
        // 'b-button--large': this.size === 'large',
        'b-button--block': this.block,
        'b-button--rounded': this.rounded,
        shadow: this.showShadow,
      };
    },
    contentClasses() {
      return {
        'b-button-wrap--small': this.size === 'small',
        'b-button-wrap--medium': this.size === 'medium',
      };
    },
  },
  methods: {
    iconLink(name) {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`@/assets/icons/${name}.svg`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/customButton.scss";
</style>
