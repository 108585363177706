const AUTHOR = 'Author';
const PAGE_ID = 'PageId';
const BOOK_ID = 'BookId';
const COMMENT_ID = 'CommentId';

export default class NotificationDetails {
  constructor(parameters) {
    const {
      author,
      pageId,
      bookId,
      commentId,
    } = parameters;
    this.author = author;
    this.pageId = pageId;
    this.bookId = bookId;
    this.commentId = commentId;
  }

  static fromApi(object = {}) {
    return new NotificationDetails(
      {
        author: object[AUTHOR],
        pageId: object[PAGE_ID],
        bookId: object[BOOK_ID],
        commentId: object[COMMENT_ID],
      },
    );
  }

  toApi() {
    return {
      [AUTHOR]: this.author,
      [PAGE_ID]: this.pageId,
      [BOOK_ID]: this.bookId,
      [COMMENT_ID]: this.commentId,
    };
  }
}
