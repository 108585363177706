import _get from 'lodash/get';
import {
  IMAGE_SEARCH_IMAGE_SOURCE_UPDATED,
  IMAGE_SEARCH_LOAD_MORE_IMAGES,
  IMAGE_SEARCH_SEARCHING,
} from '@/store/modules/imageSearch/actionTypes';
import { FILE_DIALOG_TABS } from '@/helpers/const';
import {
  CHANGE_ACTIVE_FILE_DIALOG_TAB,
  CHANGE_IMAGE_BANK_TAB_ID_CHAIN,
} from '@/store/modules/userSettings/actionTypes';

export default {
  data: () => ({
    uploadSizeImageMaxKB: Number.parseInt(process.env.VUE_APP_UPLOAD_SIZE_IMAGE_MAX_KB, 10),
    uploadImageExtensions: JSON.parse(process.env.VUE_APP_UPLOAD_IMAGE_EXTENSIONS),
    imageBankSource: process.env.VUE_APP_IMAGE_BANK_SOURCE,
    imageSearchProviderName: process.env.VUE_APP_IMAGE_SEARCH_PROVIDER_NAME,
    imageSearchProviderLink: process.env.VUE_APP_IMAGE_SEARCH_PROVIDER_LINK,
  }),
  computed: {
    showImageSearchTab() {
      return _get(this.$store, 'state.workspace.showImageSearchInFileDialog', false);
    },
    showCameraTab() {
      return _get(this.$store, 'state.workspace.showCameraInFileDialog', false);
    },
    showUploadFileTab() {
      return _get(this.$store, 'state.workspace.showUploadFileInFileDialog', false);
    },
    showWorkspaceImageTab() {
      return _get(this.$store, 'state.workspace.showWorkspaceImageInFileDialog', false);
    },
    showUserImageTab() {
      return _get(this.$store, 'state.workspace.showUserImageInFileDialog', false);
    },
    activeFileDialogTab() {
      return _get(this.$store, 'state.userSettings.activeFileDialogTab', FILE_DIALOG_TABS.IMAGE_BANK);
    },
    imageBankTabIdChain() {
      return _get(this.$store, 'state.userSettings.imageBankTabIdChain', []);
    },
    foundImages() {
      const { imageSource } = this.$store.state.imageSearch;
      return this.$store.state.imageSearch[imageSource].images;
    },
    searchString() {
      const { imageSource } = this.$store.state.imageSearch;
      return this.$store.state.imageSearch[imageSource].search;
    },
  },
  methods: {
    onImageSearch(search) {
      const { imageSource } = this.$store.state.imageSearch;
      if (search !== this.$store.state.imageSearch[imageSource].search) {
        this.$store.dispatch(IMAGE_SEARCH_SEARCHING, search);
      }
    },
    onLoadMoreSearchImages() {
      this.$store.dispatch(IMAGE_SEARCH_LOAD_MORE_IMAGES);
    },
    onImageSearchSourceUpdated(imageSource) {
      this.$store.dispatch(IMAGE_SEARCH_IMAGE_SOURCE_UPDATED, imageSource);
    },
    onActiveFileDialogTabUpdated(tab) {
      this.$store.dispatch(CHANGE_ACTIVE_FILE_DIALOG_TAB, tab);
    },
    onImageBankTabIdChainUpdated(tabIdChain) {
      this.$store.dispatch(CHANGE_IMAGE_BANK_TAB_ID_CHAIN, tabIdChain);
    },
  },
};
