<template>
  <md-speed-dial
    ref="speedDial"
    class="md-bottom-right"
    :style="{ marginTop: `-${clientHeight}px` }"
    md-direction="top"
    md-event="click"
  >
    <md-speed-dial-target
      style="margin: auto;"
      :class="isOpen ? 'fab_open' : 'md-primary'"
      @click="open"
    >
      <md-icon class="md-morph-initial">
        add
      </md-icon>
      <md-icon class="md-morph-final fab__icon_open">
        close
      </md-icon>
    </md-speed-dial-target>

    <md-speed-dial-content>
      <md-button
        v-if="pageNumber > 0"
        class="md-icon-button md-raised center template-miniatures__nav"
        @click="onBackPage"
      >
        <md-icon class="main-theme-icon">
          expand_less
        </md-icon>
      </md-button>
      <div class="template-miniatures">
        <templateMiniature
          v-for="book of sliceBooks"
          :key="book.id"
          :color="book.backgroundColor"
          :image="book.imageSource"
          :title="book.title"
          @click.native="onClickBook(book.id)"
        />
      </div>
      <md-button
        v-if="isNextPage"
        class="md-icon-button md-raised center template-miniatures__nav"
        @click="onNextPage"
      >
        <md-icon class="main-theme-icon">
          expand_more
        </md-icon>
      </md-button>
      <md-button
        class="md-icon-button md-primary add-book"
        @click="$router.push({ name: pageRoutes.CREATE_BOOK })"
      >
        <md-icon>add</md-icon>
      </md-button>
    </md-speed-dial-content>
  </md-speed-dial>
</template>

<script>
import _includes from 'lodash/includes';
import _slice from 'lodash/slice';
import { APP_PAGES } from '@/helpers/const';
import TemplateMiniature from '../templateSwitch/TemplateMiniature.vue';

/**
 * @emit onClickBook
 */
export default {
  name: 'FabTemplateBooks',
  components: {
    TemplateMiniature,
  },
  props: {
    templateBooks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pageRoutes: APP_PAGES,
      pageNumber: 0,
      pageCount: 3,
      isOpen: false,
      clientHeight: 0,
    };
  },
  computed: {
    sliceBooks() {
      return _slice(
        this.templateBooks,
        this.pageNumber * this.pageCount,
        (this.pageNumber + 1) * this.pageCount,
      );
    },
    isNextPage() {
      return this.pageNumber < this.templateBooks.length / this.pageCount - 1;
    },
  },
  mounted() {
    this.clientHeight = this.$el.clientHeight;
  },
  methods: {
    open() {
      this.$nextTick(() => {
        this.isOpen = _includes(this.$refs.speedDial.$el.classList, 'md-active');
        if (this.isOpen) {
          this.$emit('open');
        }
        this.clientHeight = this.$el.clientHeight;
      });
    },
    onClickBook(bookId) {
      this.$emit('onClickBook', bookId);
    },
    onBackPage() {
      if (this.pageNumber > 0) {
        this.pageNumber -= 1;
        this.$nextTick(() => {
          this.clientHeight = this.$el.clientHeight;
        });
      }
    },
    onNextPage() {
      if (this.isNextPage) {
        this.pageNumber += 1;
        this.$nextTick(() => {
          this.clientHeight = this.$el.clientHeight;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/fabTemplateBooks.scss";
</style>
