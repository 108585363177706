<template>
  <vue-aspect-ratio
    ar="3:2"
    width="100%"
  >
    <div class="image-selector">
      <image-viewer
        class="image-selector__image"
        :class="imagePlaceholderClass"
        :image-alt="imageAlt"
        :image-source="imageSource"
        :crop-area="cropArea"
        :marks="marks"
        :coefficient-mark-size="coefficientMarkSize"
      />
      <div class="image-selector__actions">
        <md-button
          v-if="!readOnly && imageSource && allowAddMarks"
          class="md-icon-button md-raised"
          @click="onMarkDialogShow"
        >
          <md-icon class="main-theme-icon">
            category
          </md-icon>
        </md-button>
        <md-button
          v-if="!readOnly && imageSource"
          class="md-icon-button md-raised"
          @click="onCropDialogShow"
        >
          <md-icon class="main-theme-icon">
            crop
          </md-icon>
        </md-button>
        <md-button
          v-if="!readOnly"
          class="md-icon-button md-raised"
          @click="onFileDialogShow"
        >
          <md-icon class="main-theme-icon">
            image
          </md-icon>
        </md-button>
      </div>
      <file-dialog
        :get-localize="getLocalize"
        :image-bank-source="imageBankSource"
        :show-dialog="showFileDialog"
        :upload-image-extensions="uploadImageExtensions"
        :upload-size-image-max-k-b="uploadSizeImageMaxKB"
        :show-image-search-tab="showImageSearchTab"
        :show-camera-tab="showCameraTab"
        :show-upload-file-tab="showUploadFileTab"
        :show-user-image-tab="showUserImageTab"
        :show-workspace-image-tab="showWorkspaceImageTab"
        :found-images="foundImages"
        :image-search-provider-name="imageSearchProviderName"
        :image-search-provider-link="imageSearchProviderLink"
        :search-string="searchString"
        :active-file-dialog-tab="activeFileDialogTab"
        :image-bank-tab-id-chain="imageBankTabIdChain"
        @onDialogClosed="onFileDialogClosed"
        @onFileSelected="onFileSelected"
        @onImageSourceSelected="onImageSourceSelected"
        @onImageSearch="onImageSearch"
        @onLoadMoreSearchImages="onLoadMoreSearchImages"
        @onImageSearchSourceUpdated="onImageSearchSourceUpdated"
        @onActiveFileDialogTabUpdated="onActiveFileDialogTabUpdated"
        @onImageBankTabIdChainUpdated="onImageBankTabIdChainUpdated"
      />
      <crop-dialog
        :get-localize="getLocalize"
        :show-dialog="showCropDialog"
        :image-source="cropImageSource"
        :crop-area="resetCropArea ? undefined : cropArea"
        :marks="marks"
        @onDialogClosed="onCropDialogClosed"
        @onUpdated="onCropAreaUpdated"
      />
      <mark-dialog
        :get-localize="getLocalize"
        :show-dialog="showMarkDialog"
        :image-source="imageSource"
        :crop-area="cropArea"
        :marks="marks"
        :custom-marks="customMarks"
        :coefficient-mark-size="coefficientMarkSize"
        @onDialogClosed="onMarkDialogClosed"
        @onUpdated="onMarkUpdated"
        @onCustomMarkUpdated="onCustomMarkUpdated"
      />
    </div>
  </vue-aspect-ratio>
</template>

<script>
import VueAspectRatio from 'vue-aspect-ratio';
import FileDialog from '@/components/fileDialog/FileDialog.vue';
import fileDialogForwardingMixin from '@/mixins/fileDialogForwardingMixin';
import CropDialog from '@/components/cropDialog/CropDialog.vue';
import ImageViewer from '@/components/imageViewer/ImageViewer.vue';
import MarkDialog from '@/components/markDialog/MarkDialog.vue';

/**
 * @emit onFileSelected - image updated
 * @emit onImageSourceSelected - image source was updated
 * @emit onImageSearch - search images
 * @emit onLoadMoreSearchImages - load more images
 * @emit onImageSearchSourceUpdated - image source updated
 * @emit onCropAreaUpdated - crop area was updated
 * @emit onMarksUpdated - marks was updated
 * @emit onCustomMarkUpdated - custom mark was updated
 * @emit onActiveFileDialogTabUpdated - activeFileDialogTab was updated
 * @emit onImageBankTabIdChainUpdated - imageBankTabIdChain was updated
 */
export default {
  name: 'ImageSelector',
  components: {
    ImageViewer,
    MarkDialog,
    CropDialog,
    FileDialog,
    VueAspectRatio,
  },
  mixins: [
    fileDialogForwardingMixin,
  ],
  props: {
    imageSource: {
      type: String,
      default: '',
      required: false,
    },
    imageAlt: {
      type: String,
      default: '',
      required: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    getLocalize: {
      type: Function,
      default: (key) => key,
      required: false,
    },
    loadedImageSource: {
      type: String,
      default: '',
      required: false,
    },
    allowAddMarks: {
      type: Boolean,
      default: true,
    },
    marks: {
      type: Array,
      default: () => [],
    },
    customMarks: {
      type: Array,
      default: () => [],
    },
    coefficientMarkSize: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    showFileDialog: false,
    showCropDialog: false,
    showMarkDialog: false,
    cropImageSource: null,
    resetCropArea: false,
  }),
  computed: {
    imagePlaceholderClass() {
      return {
        'image-placeholder': !this.imageSource,
      };
    },
  },
  watch: {
    imageSource(value) {
      this.cropImageSource = value;
    },
    loadedImageSource(value) {
      if (value && this.showFileDialog) {
        this.cropImageSource = value;
        this.showCropDialog = true;
        this.resetCropArea = true;
      }
    },
  },
  methods: {
    onFileDialogClosed() {
      this.showFileDialog = false;
    },
    onFileDialogShow() {
      this.showFileDialog = true;
    },
    onCropDialogClosed() {
      this.showCropDialog = false;
      this.resetCropArea = false;
    },
    onCropDialogShow() {
      this.cropImageSource = this.imageSource;
      this.showCropDialog = true;
    },
    onMarkDialogShow() {
      this.showMarkDialog = true;
    },
    onMarkDialogClosed() {
      this.showMarkDialog = false;
    },
    onFileSelected(file) {
      this.$emit('onFileSelected', file);
    },
    onImageSourceSelected(imageSource) {
      this.showCropDialog = true;
      this.resetCropArea = true;
      this.cropImageSource = imageSource;
    },
    onCropAreaUpdated(cropArea) {
      this.showCropDialog = false;
      this.resetCropArea = false;
      this.showFileDialog = false;
      this.$emit('onImageSourceSelected', this.cropImageSource);
      this.$emit('onCropAreaUpdated', cropArea);
    },
    onMarkUpdated(marks) {
      this.showMarkDialog = false;
      this.$emit('onMarksUpdated', marks);
    },
    onCustomMarkUpdated(customMark) {
      this.$emit('onCustomMarkUpdated', customMark);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/imageSelector.scss";
</style>
