<template>
  <vue-aspect-ratio
    ar="3:4"
    class="responsive-book"
  >
    <div class="working-area">
      <div class="working-area__top">
        <task-navigation
          :total-steps="totalSteps"
          :selected-step="selectedStep"
          :available-steps="availableSteps"
          :get-localize="getLocalize"
          :all-completed="creationCompleted"
          @onStepSelected="onStepSelected"
        />
        <div class="working-area__tip">
          <img
            src="@/assets/images/teacher.svg"
            class="working-area__tip-teacher"
          >
          <div class="working-area__tip-body">
            <span class="working-area__tip-text"> {{ stepHintText }}</span>
            <md-button
              class="md-icon-button working-area__tip-audio"
              @click="playAudio()"
            >
              <md-icon>
                record_voice_over
              </md-icon>
            </md-button>
          </div>
        </div>
      </div>
      <div
        :key="currentStepNumber"
        class="working-area__wrap"
      >
        <div
          v-if="creationCompleted"
          class="working-area__final"
        >
          <img
            class="working-area__final-image"
            src="@/assets/images/complete.svg"
          >
          <div class="working-area__final-buttons">
            <custom-button
              :title="getLocalize('book.createNextPage')"
              icon="note_add"
              class="md-raised md-primary working-area__final-button"
              type="custom"
              @click="onAddNewPageClick"
            />
          </div>
        </div>
        <validation-observer
          v-slot="{ valid }"
          ref="pageItemsWrap"
          class="working-area__steps"
        >
          <template v-if="creationCompleted" />
          <template v-else-if="currentStepNumber === 1">
            <image-selector
              class="image-selector_edit"
              :get-localize="getLocalize"
              :image-source="page.imageSource"
              :image-bank-source="imageBankSource"
              :upload-image-extensions="uploadImageExtensions"
              :upload-size-image-max-k-b="uploadSizeImageMaxKB"
              :show-image-search-tab="showImageSearchTab"
              :show-camera-tab="showCameraTab"
              :show-upload-file-tab="showUploadFileTab"
              :show-user-image-tab="showUserImageTab"
              :show-workspace-image-tab="showWorkspaceImageTab"
              :found-images="foundImages"
              :loaded-image-source="loadedImageSource"
              :image-search-provider-name="imageSearchProviderName"
              :image-search-provider-link="imageSearchProviderLink"
              :crop-area="page.visibleArea"
              :marks="marks"
              :custom-marks="customMarks"
              :coefficient-mark-size="coefficientMarkSize"
              :search-string="searchString"
              :active-file-dialog-tab="activeFileDialogTab"
              :image-bank-tab-id-chain="imageBankTabIdChain"
              @onFileSelected="onImageFileUpdated"
              @onImageSourceSelected="onImageSourceUpdated"
              @onImageSearch="onImageSearch"
              @onLoadMoreSearchImages="onLoadMoreSearchImages"
              @onImageSearchSourceUpdated="onImageSearchSourceUpdated"
              @onCropAreaUpdated="onCropAreaUpdated"
              @onMarksUpdated="onMarksUpdated"
              @onCustomMarkUpdated="onCustomMarkUpdated"
              @onActiveFileDialogTabUpdated="onActiveFileDialogTabUpdated"
              @onImageBankTabIdChainUpdated="onImageBankTabIdChainUpdated"
            />
            <validation-provider
              v-slot="{invalid, validated}"
              :rules="{ required: true }"
              name="image"
            >
              <input
                v-show="false"
                v-model="page.imageSource"
              >
              <md-field
                v-if="invalid && validated"
                class="md-invalid"
                :md-counter="false"
              >
                <span class="md-error">
                  {{ getLocalize('validation.noImage') }}
                </span>
              </md-field>
            </validation-provider>
          </template>
          <template v-else-if="currentStepNumber === 2">
            <validation-provider
              v-slot="{errors}"
              :rules="{requiredExerciseText: !page.audioSource, maxStylizedText: 160 }"
              name="question"
            >
              <stylized-text-area
                v-model="page.exerciseText"
                background-text-color="#00F"
                is-allow-text-styles
                text-color="#000"
                :max-length="160"
                :less-characters-for-counter-to-red="30"
                class="text-styles_edit"
                :colors-sets="colorsSets"
                :get-localize="getLocalize"
                include-enter
              />
              <recorder
                :audio-source="page.audioSource"
                :get-localize="getLocalize"
                :is-disabled="isDisabledRecorder"
                @onAudioFileUpdated="onAudioFileUpdated"
                @onAudioFileDeleted="onAudioFileDeleted"
              />
              <md-field
                v-if="errors.length"
                class="md-invalid"
                :md-counter="false"
              >
                <span
                  class="md-error"
                >
                  {{ errors[0] }}
                </span>
              </md-field>
            </validation-provider>
          </template>
          <template v-else-if="currentStepNumber === 3">
            <div class="answer-box">
              <validation-provider
                v-slot="{errors}"
                :rules="{required: true, min: 1, max: 25, }"
                name="correct-answer"
                class="answer-input_right-answer"
              >
                <default-input
                  ref="rightAnswerInput"
                  :value="page.rightAnswer"
                  :label="getLocalize('book.correct-answer')"
                  :validation="errors"
                  :maxlength="25"
                  class="answer-input_right"
                  @input="onRightAnswerUpdated"
                />
                <md-autocomplete
                  class="answer-input_selector"
                  :md-options="allUnitNames"
                  :value="page.rightAnswerUnit"
                  :md-input-maxlength="10"
                  md-dense
                  autocomplete="off"
                  @input="onRightAnswerUnitUpdated"
                >
                  <label>{{ getLocalize('book.unit') }}</label>
                </md-autocomplete>
              </validation-provider>
            </div>
          </template>
          <template v-else-if="currentStepNumber === 4">
            <random-wrong-answers
              v-if="showRandomWrongAnswers"
              :disabled-values="randomDisabledValues"
              :exclude-values="randomExcludeValues"
              :right-answer="page.rightAnswer"
              :right-answer-unit="page.rightAnswerUnit"
              :read-only="randomWrongAnswersReadOnly"
              :unit-group="unitsForRandom"
              :get-localize="getLocalize"
              :round="addUnit ? 1 : 0.66"
              @onWrongAnswersSelected="onWrongAnswersSelected"
            />
            <wrong-answer-box
              ref="firstWrongAnswer"
              :wrong-answer="page.firstWrongAnswer"
              :wrong-answer-unit="page.firstWrongAnswerUnit"
              :unit-group="unitsForRandom"
              :right-answer="page.rightAnswer"
              :right-answer-unit="page.rightAnswerUnit"
              :other-wrong-answers="otherWrongAnswersForFirst"
              @onWrongAnswerUpdated="onFirstWrongAnswerUpdated"
              @onWrongAnswerUnitUpdated="onFirstWrongAnswerUnitUpdated"
              @onOpenAutocompleteOptions="closeOtherAutocompleteOptions(1)"
            />
            <wrong-answer-box
              ref="secondWrongAnswer"
              :wrong-answer="page.secondWrongAnswer"
              :wrong-answer-unit="page.secondWrongAnswerUnit"
              :unit-group="unitsForRandom"
              :right-answer="page.rightAnswer"
              :right-answer-unit="page.rightAnswerUnit"
              :other-wrong-answers="otherWrongAnswersForSecond"
              @onWrongAnswerUpdated="onSecondWrongAnswerUpdated"
              @onWrongAnswerUnitUpdated="onSecondWrongAnswerUnitUpdated"
              @onOpenAutocompleteOptions="closeOtherAutocompleteOptions(2)"
            />
            <wrong-answer-box
              ref="thirdWrongAnswer"
              :wrong-answer="page.thirdWrongAnswer"
              :wrong-answer-unit="page.thirdWrongAnswerUnit"
              :unit-group="unitsForRandom"
              :right-answer="page.rightAnswer"
              :right-answer-unit="page.rightAnswerUnit"
              :other-wrong-answers="otherWrongAnswersForThird"
              @onWrongAnswerUpdated="onThirdWrongAnswerUpdated"
              @onWrongAnswerUnitUpdated="onThirdWrongAnswerUnitUpdated"
              @onOpenAutocompleteOptions="closeOtherAutocompleteOptions(3)"
            />
          </template>
          <template v-else-if="currentStepNumber === 5">
            <div class="answer-box">
              <validation-provider
                v-slot="{errors}"
                :rules="{ maxStylizedText: 75 }"
                name="hint"
              >
                <stylized-text-area
                  v-model="page.promptAnswer"
                  :label="getLocalize('book.hint')"
                  background-text-color="#00F"
                  is-allow-text-styles
                  text-color="#000"
                  :max-length="75"
                  :less-characters-for-counter-to-red="15"
                  :validation="errors"
                  class="text-styles_hint text-styles_edit"
                  :colors-sets="colorsSets"
                  :get-localize="getLocalize"
                />
              </validation-provider>
            </div>
          </template>
          <div
            v-if="!creationCompleted"
            class="complete-step"
          >
            <md-button
              class="complete-step-button md-icon-button md-raised center"
              :class="{ 'md-primary': valid }"
              @click="completeStep"
            >
              <md-icon :class="{ 'main-theme-icon': !valid }">
                done
              </md-icon>
            </md-button>
          </div>
        </validation-observer>
      </div>
    </div>
  </vue-aspect-ratio>
</template>

<script>
/**
 * @emit onStepSelected (pageIndex, stepIndex) - step for page was selected
 * @emit onStepCompleted (stepNumber, page) - step for page was completed and validated
 * @emit onImageFileUpdated - image updated
 * @emit onImageSearch - search images
 * @emit onLoadMoreSearchImages - load more images
 * @emit onImageSearchSourceUpdated - image source updated
 * @emit onCropAreaUpdated - crop area was updated
 * @emit onMarksUpdated - marks was updated
 * @emit onCustomMarkUpdated - custom mark was updated
 * @emit onAudioFileUpdated - audio file updated
 * @emit onAudioFileDeleted - audio file deleted
 * @emit onRightAnswersUpdated - right answers updated
 * @emit onRightAnswerUnitsUpdated - right answer units updated
 * @emit onWrongAnswersUpdated - wrong answers updated
 * @emit onWrongAnswerUnitsUpdated - wrong answer units updated
 * @emit onAddNewPageClick - was click on button add page
 */
import _trim from 'lodash/trim';
import _zipWith from 'lodash/zipWith';
import _invoke from 'lodash/invoke';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { max, min, required } from 'vee-validate/dist/rules';
import VueAspectRatio from 'vue-aspect-ratio';
import localizationMixin from '@/mixins/localizationMixin';
import StylizedTextArea from '@/components/stylizedTextArea/StylizedTextArea.vue';
import DefaultInput from '@/components/inputs/input.vue';
import ImageSelector from '@/components/imageSelector/ImageSelector.vue';
import fileDialogDataMixin from '@/mixins/fileDialogDataMixin';
import Recorder from '@/components/recorder/recorder.vue';
import { getTextLengthFromHtml } from '@/helpers/functions';
import TaskNavigation from '@/components/navigation/TaskNavigation.vue';
import RandomWrongAnswers from '@/components/randomWrongAnswers/RandomWrongAnswers.vue';
import CustomButton from '@/components/buttons/CustomButton.vue';
import unitMixin from '@/mixins/unitMixin';
import WrongAnswerBox from '@/components/wrongAnswerBox/WrongAnswerBox.vue';

export default {
  name: 'CreatePage',
  components: {
    WrongAnswerBox,
    RandomWrongAnswers,
    ImageSelector,
    StylizedTextArea,
    DefaultInput,
    ValidationObserver,
    ValidationProvider,
    Recorder,
    TaskNavigation,
    CustomButton,
    VueAspectRatio,
  },
  mixins: [
    localizationMixin,
    fileDialogDataMixin,
    unitMixin,
  ],
  props: {
    currentStepNumber: {
      type: Number,
      required: true,
    },
    page: {
      type: Object,
      required: true,
    },
    creationCompleted: {
      type: Boolean,
      default: false,
      required: false,
    },
    loadedImageSource: {
      type: String,
      default: '',
      required: false,
    },
    marks: {
      type: Array,
      default: () => [],
    },
    customMarks: {
      type: Array,
      default: () => [],
    },
    coefficientMarkSize: {
      type: Number,
      default: 1,
    },
    totalSteps: {
      type: Number,
      default: 1,
      required: false,
    },
    selectedStep: {
      type: Number,
      default: 0,
      required: false,
    },
    availableSteps: {
      type: Number,
      default: 0,
      required: false,
    },
    isDisabledRecorder: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    maxPageSteps: 5,
    colorsSets: JSON.parse(process.env.VUE_APP_FRONT_PAGE_COLORS),
  }),
  computed: {
    randomDisabledValues: {
      deep: true,
      get() {
        return this.page.wrongAnswers.filter((item) => item !== '');
      },
    },
    randomExcludeValues: {
      deep: true,
      get() {
        return this.page.rightAnswers;
      },
    },
    stepHintText() {
      if (this.creationCompleted) {
        return this.getLocalize('editPage.completeHint');
      }
      if (this.currentStepNumber > 0 && this.currentStepNumber <= this.maxPageSteps) {
        return this.getLocalize(`editPage.step${this.currentStepNumber}Hint`);
      }
      return '';
    },
    unitGroups() {
      return this.getLocalize('editPage.units') || [];
    },
    allUnitNames() {
      return this.unitGroups.reduce((accumulator, currentValue) => {
        accumulator.push(...this.getUnitGroupNames(currentValue));
        return accumulator;
      }, []);
    },
    showRandomWrongAnswers() {
      return this.checkAnswerOnlyNumber(this.page.rightAnswer);
    },
    addUnit() {
      return !!_trim(this.page.rightAnswerUnit);
    },
    unitsForRandom() {
      if (this.addUnit) {
        const unitGroup = this.unitGroups.find((g) => this.testExistUnits(
          this.page.rightAnswerUnit,
          this.getUnitGroupNames(g),
        ));

        return unitGroup || {
          units: [
            {
              name: this.page.rightAnswerUnit,
              coefficient: 1,
            },
          ],
        };
      }

      return {};
    },
    randomWrongAnswersReadOnly() {
      return !!(_trim(this.page.firstWrongAnswer)
        && _trim(this.page.secondWrongAnswer)
        && _trim(this.page.thirdWrongAnswer));
    },
    otherWrongAnswersForFirst() {
      const answers = this.zipWrongAnswers;
      return [answers[1], answers[2]];
    },
    otherWrongAnswersForSecond() {
      const answers = this.zipWrongAnswers;
      return [answers[0], answers[2]];
    },
    otherWrongAnswersForThird() {
      const answers = this.zipWrongAnswers;
      return [answers[0], answers[1]];
    },
    zipWrongAnswers() {
      const answers = [
        this.page.firstWrongAnswer,
        this.page.secondWrongAnswer,
        this.page.thirdWrongAnswer,
      ];
      const units = [
        this.page.firstWrongAnswerUnit,
        this.page.secondWrongAnswerUnit,
        this.page.thirdWrongAnswerUnit,
      ];
      return _zipWith(answers, units, (answer, unit) => ({ answer, unit }));
    },
  },
  created() {
    extend('required', {
      ...required,
      message: this.getLocalize('validation.required'),
    });
    extend('requiredExerciseText', {
      validate: (value) => getTextLengthFromHtml(value.toString()) > 0,
      message: this.getLocalize('validation.requiredExerciseText'),
    });
    extend('min', {
      ...min,
      message: this.getLocalize('validation.min'),
    });
    extend('max', {
      ...max,
      message: this.getLocalize('validation.max'),
    });
    extend('maxStylizedText', {
      params: ['length'],
      validate: (value, { length }) => getTextLengthFromHtml(value.toString()) <= length,
      message: this.getLocalize('validation.maxStylizedText'),
    });
  },
  methods: {
    playAudio() {
      const lang = this.getLocale();
      const name = this.creationCompleted ? 'complete' : `step_${this.currentStepNumber}`;
      // eslint-disable-next-line import/no-dynamic-require,global-require
      const mp3 = new Audio(require(`@/assets/audio/pages/${name}_${lang}.mp3`));
      mp3.play();
    },
    async validateSteps(step) {
      switch (step) {
        default: {
          if (step < 0 || step > this.maxPageSteps) {
            return false;
          }
          const valid = await this.$refs.pageItemsWrap.validate();
          return valid;
        }
      }
    },
    async completeStep() {
      // validate step
      const valid = await this.validateSteps(this.currentStepNumber);
      // when ok - mark step as completed
      if (valid) {
        this.$emit('onStepCompleted', this.currentStepNumber, this.page);
      }
    },
    onImageFileUpdated(file) {
      this.$emit('onImageFileUpdated', file);
    },
    onImageSourceUpdated(imageSource) {
      this.page.imageSource = imageSource;
    },
    async validateCurrentStep() {
      const valid = await this.validateSteps(this.currentStepNumber);
      return valid;
    },
    onCropAreaUpdated(cropArea) {
      this.$emit('onCropAreaUpdated', cropArea);
    },
    onMarksUpdated(marks) {
      this.$emit('onMarksUpdated', marks);
    },
    onCustomMarkUpdated(customMark) {
      this.$emit('onCustomMarkUpdated', customMark);
    },
    onAudioFileUpdated(file) {
      this.$emit('onAudioFileUpdated', file);
    },
    onAudioFileDeleted() {
      this.$emit('onAudioFileDeleted');
    },
    onStepSelected(stepIndex) {
      this.$emit('onStepSelected', stepIndex);
    },
    onRightAnswerUpdated(rightAnswer) {
      this.$emit('onRightAnswersUpdated', [rightAnswer]);
    },
    onRightAnswerUnitUpdated(rightAnswerUnit) {
      if (rightAnswerUnit !== undefined) {
        this.$emit('onRightAnswerUnitsUpdated', [rightAnswerUnit]);
      }
    },
    onFirstWrongAnswerUpdated(firstWrongAnswer) {
      this.$emit('onWrongAnswersUpdated', [
        firstWrongAnswer,
        this.page.secondWrongAnswer,
        this.page.thirdWrongAnswer,
      ]);
    },
    onSecondWrongAnswerUpdated(secondWrongAnswer) {
      this.$emit('onWrongAnswersUpdated', [
        this.page.firstWrongAnswer,
        secondWrongAnswer,
        this.page.thirdWrongAnswer,
      ]);
    },
    onThirdWrongAnswerUpdated(thirdWrongAnswer) {
      this.$emit('onWrongAnswersUpdated', [
        this.page.firstWrongAnswer,
        this.page.secondWrongAnswer,
        thirdWrongAnswer,
      ]);
    },
    onFirstWrongAnswerUnitUpdated(firstWrongUnit) {
      this.$emit('onWrongAnswerUnitsUpdated', [
        firstWrongUnit,
        this.page.secondWrongAnswerUnit,
        this.page.thirdWrongAnswerUnit,
      ]);
    },
    onSecondWrongAnswerUnitUpdated(secondWrongUnit) {
      this.$emit('onWrongAnswerUnitsUpdated', [
        this.page.firstWrongAnswerUnit,
        secondWrongUnit,
        this.page.thirdWrongAnswerUnit,
      ]);
    },
    onThirdWrongAnswerUnitUpdated(thirdWrongUnit) {
      this.$emit('onWrongAnswerUnitsUpdated', [
        this.page.firstWrongAnswerUnit,
        this.page.secondWrongAnswerUnit,
        thirdWrongUnit,
      ]);
    },
    onWrongAnswersSelected(wrongAnswer) {
      const { number, unit } = wrongAnswer;
      let {
        firstWrongAnswer, secondWrongAnswer, thirdWrongAnswer,
        firstWrongAnswerUnit, secondWrongAnswerUnit, thirdWrongAnswerUnit,
      } = this.page;

      if (!_trim(this.page.firstWrongAnswer)) {
        firstWrongAnswer = number;
        firstWrongAnswerUnit = unit;
      } else if (!_trim(this.page.secondWrongAnswer)) {
        secondWrongAnswer = number;
        secondWrongAnswerUnit = unit;
      } else if (!_trim(this.page.thirdWrongAnswer)) {
        thirdWrongAnswer = number;
        thirdWrongAnswerUnit = unit;
      }

      this.$emit('onWrongAnswersUpdated', [
        firstWrongAnswer,
        secondWrongAnswer,
        thirdWrongAnswer,
      ]);

      if (unit) {
        this.$emit('onWrongAnswerUnitsUpdated', [
          firstWrongAnswerUnit,
          secondWrongAnswerUnit,
          thirdWrongAnswerUnit,
        ]);
      }
    },
    onAddNewPageClick() {
      this.$emit('onAddNewPageClick');
    },
    closeOtherAutocompleteOptions(currentWrongAnswerNumber) {
      if (currentWrongAnswerNumber !== 1) {
        _invoke(this.$refs, 'firstWrongAnswer.hideAutocompleteOptions');
      }
      if (currentWrongAnswerNumber !== 2) {
        _invoke(this.$refs, 'secondWrongAnswer.hideAutocompleteOptions');
      }
      if (currentWrongAnswerNumber !== 3) {
        _invoke(this.$refs, 'thirdWrongAnswer.hideAutocompleteOptions');
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/desktop/components/createPage.scss";
@import "~@/assets/styles/desktop/components/responsiveBook.scss";
</style>
