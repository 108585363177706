const ID = 'id';
const TYPE = 'type';
const X = 'x';
const Y = 'y';

export default class Mark {
  constructor(parameters) {
    const {
      id,
      type,
      x,
      y,
    } = parameters;
    this.id = id;
    this.type = type;
    this.x = x;
    this.y = y;
  }

  static fromApi(object = {}) {
    return new Mark({
      id: object[ID],
      type: object[TYPE],
      x: object[X],
      y: object[Y],
    });
  }

  toApi() {
    return {
      [ID]: this.id,
      [TYPE]: this.type,
      [X]: Math.round(this.x),
      [Y]: Math.round(this.y),
    };
  }
}
