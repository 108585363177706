<template>
  <div class="tn">
    <task-navigation-step
      v-for="(step,index) of totalSteps"
      :key="step"
      :number="step"
      :icon="getIcon(index)"
      :actvie="index === selectedStep"
      :class="getClass(index)"
      :tooltip="getTooltipForStep(index)"
      @click.native="goToStep(index)"
    />
  </div>
</template>

<script>
import TaskNavigationStep from '@/components/navigation/TaskNavigationStep.vue';

/**
 * @emit onStepSelected (index) - step was clicked
 */
export default {
  name: 'TaskNavigation',
  components: {
    TaskNavigationStep,
  },
  props: {
    totalSteps: {
      type: Number,
      default: 1,
      required: false,
    },
    selectedStep: {
      type: Number,
      default: 0,
      required: false,
    },
    availableSteps: {
      type: Number,
      default: 0,
      required: false,
    },
    getLocalize: {
      type: Function,
      default: (key) => key,
      required: false,
    },
    allCompleted: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    goToStep(index) {
      this.$emit('onStepSelected', index);
    },
    getIcon(index) {
      switch (index) {
        case 0:
          return 'image';
        case 1:
          return 'notes';
        case 2:
          return 'check_circle';
        case 3:
          return 'cancel';
        case 4:
          return 'emoji_objects';
        default:
          return 'emoji_objects';
      }
    },
    getClass(index) {
      return {
        tn__step_completed: index < this.selectedStep || this.allCompleted,
        tn__step_active: index === this.selectedStep && !this.allCompleted,
        tn__step_disabled: index > this.availableSteps && !this.allCompleted,
      };
    },
    getTooltipForStep(index) {
      if (index > this.availableSteps) {
        return this.getLocalize('stepNavigation.disabledStepTooltip');
      }

      switch (index) {
        case 0: return this.getLocalize('stepNavigation.firstStepTooltip');
        case 1: return this.getLocalize('stepNavigation.secondStepTooltip');
        case 2: return this.getLocalize('stepNavigation.thirdStepTooltip');
        case 3: return this.getLocalize('stepNavigation.fourthStepTooltip');
        case 4: return this.getLocalize('stepNavigation.fifthStepTooltip');
        default: return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/taskNavigation.scss";
</style>
