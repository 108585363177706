const USER_TOKEN = 'USER_TOKEN';
const TOKEN_EXPIRES_IN = 'TOKEN_EXPIRES_IN';
const USERNAME = 'USERNAME';
const USER_ROLE = 'USER_ROLE';

function getToken() {
  return {
    token: localStorage.getItem(USER_TOKEN) || null,
    expiresIn: localStorage.getItem(TOKEN_EXPIRES_IN) || null,
  };
}

function isAuthenticated() {
  const tokenInfo = getToken();
  return !!tokenInfo.token;
}

function setToken(token, expiresIn) {
  localStorage.setItem(USER_TOKEN, token);
  localStorage.setItem(TOKEN_EXPIRES_IN, expiresIn);
}

function getUserInfo() {
  return {
    username: localStorage.getItem(USERNAME) || null,
    role: localStorage.getItem(USER_ROLE) || null,
  };
}

function setUserInfo(username, role) {
  localStorage.setItem(USERNAME, username);
  localStorage.setItem(USER_ROLE, role);
}

function clear() {
  localStorage.removeItem(USER_TOKEN);
  localStorage.removeItem(TOKEN_EXPIRES_IN);
  localStorage.removeItem(USERNAME);
  localStorage.removeItem(USER_ROLE);
}

export default {
  isAuthenticated,
  getToken,
  setToken,
  getUserInfo,
  setUserInfo,
  clear,
};
