export const LOCALE_NAME_IN_COOKIE = 'PAGE_LANG';

export const APP_PAGES = Object.freeze({
  SIGN_IN: 'SignIn',
  SOLVE_BOOK: 'SolveBook',
  SOLVE_BOOK_BY_ID: 'SolveBookById',
  SOLVE_FRONT_PAGE: 'SolveFrontPage',
  SOLVE_END_PAGE: 'SolveEndPage',
  EDIT_PAGES: 'EditPages',
  EDIT_FRONT_PAGE: 'EditFrontPage',
  EDIT_PAGES_BY_ID: 'EditPagesById',
  WORKSPACE: 'Workspace',
  CREATE_BOOK: 'CreateBook',
  SETTINGS: 'Settings',
});

export const IMAGE_SOURCE = Object.freeze({
  WORKSPACE: 'WorkSpace',
  IMAGE_SEARCH: 'ImageSearch',
  PERSONAL: 'Personal',
});

export const PREDEFINED_MARKS = Object.freeze({
  CIRCLE: 'Circle',
  TRIANGLE: 'Triangle',
  SQUARE: 'Square',
  POLYGON: 'Polygon',
});

export const CUSTOM_MARKS = Object.freeze({
  FIRST_CUSTOM: 'FirstCustom',
  SECOND_CUSTOM: 'SecondCustom',
  THIRD_CUSTOM: 'ThirdCustom',
  FOURTH_CUSTOM: 'FourthCustom',
});

export const MARKS = Object.freeze({
  ...PREDEFINED_MARKS,
  ...CUSTOM_MARKS,
});

export const USER_ROLE = Object.freeze({
  STUDENT: 'Student',
  TEACHER: 'Teacher',
  ADMIN: 'Admin',
});

export const BOOK_SOURCE = Object.freeze({
  PREDEFINED: 'Predefined',
  GLOBAL_PREDEFINED: 'GlobalPredefined',
  TEMPLATE: 'Template',
  USER_BOOK: 'UserBook',
  ALL: 'All',
});

export const AUTHORIZATION_HEADER = 'authorization';
export const TOKEN_EXPIRES_HEADER = 'authorization_expires';

export const NUMBER_REGEXP_TEMPLATE_FOR_RANDOM_ANSWER = '^\\s*\\d+(?:[.,]\\d*)?';

export const NUMBER_REGEXP_THIRD_PRECISION = /((?:\s|^)\d+[.,]\d{1,3})\d*(?=\s|$)/g;

export const EVET_TYPES = Object.freeze({
  NEW_PAGE: 'AddNewPage',
  NEW_COMMENT: 'AddCommentToPage',
  PAGE_READY: 'PageReady',
  BOOK_READY: 'BookReady',
});

export const STATUS_CODE = Object.freeze({
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
});

export const FILE_DIALOG_TABS = Object.freeze({
  IMAGE_BANK: 'IMAGE_BANK',
  IMAGE_SEARCH: 'IMAGE_SEARCH',
  CAMERA: 'CAMERA',
  UPLOAD_FILE: 'UPLOAD_FILE',
  WORKSPACE_IMAGE: 'WORKSPACE_IMAGE',
  USER_IMAGE: 'USER_IMAGE',
});
