import _invoke from 'lodash/invoke';

export default {
  methods: {
    existPageText(page) {
      return !!_invoke(
        page,
        'exerciseText.replace',
        /<(.|\n)*?>/g,
        '',
      );
    },
  },
};
