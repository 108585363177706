import _invoke from 'lodash/invoke';
import _get from 'lodash/get';
import axios from 'axios';
import userStore from '@/storages/userStorage';
import { AUTHORIZATION_HEADER, TOKEN_EXPIRES_HEADER } from '@/helpers/const';
import { getBaseUrl } from '@/api/utils';

function createSession() {
  const session = axios.create({
    baseURL: getBaseUrl(),
    withCredentials: true,
  });

  session.interceptors.request.use(
    (config) => {
      const tokenInfo = userStore.getToken();
      if (tokenInfo) {
        // eslint-disable-next-line no-param-reassign
        config.headers[AUTHORIZATION_HEADER] = tokenInfo.token;
      }
      return config;
    },
  );

  return session;
}

class _HttpClient {
  constructor() {
    this.session = createSession();

    this.session.interceptors.response.use((response) => response, (error) => {
      const originalRequest = error.config;
      if (_get(error, 'response.status') === 401) {
        // eslint-disable-next-line no-underscore-dangle
        if (!!error.response.headers[AUTHORIZATION_HEADER] && !originalRequest._retry) {
          // eslint-disable-next-line no-underscore-dangle
          originalRequest._retry = true;
          const token = error.response.headers[AUTHORIZATION_HEADER];
          const expiresIn = error.response.headers[TOKEN_EXPIRES_HEADER];
          userStore.setToken(token, expiresIn);
          return this.session(originalRequest);
        }

        userStore.clear();
        _invoke(this, 'unauthorizedListener');
      }

      return Promise.reject(error);
    });
  }

  setUnauthorizedListener = (listener) => {
    this.unauthorizedListener = listener;
  };

  getRequest = async (
    url,
    data,
    headers = {},
    type = 'application/json;charset=UTF-8',
    responseType = 'text',
  ) => new Promise((resolve, reject) => {
    this.session
      .get(url, {
        params: {
          ...data,
        },
        responseType,
        headers: {
          'Content-type': type,
          ...headers,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

  postRequest = async (
    url,
    data,
    headers = {},
    type = 'application/json;charset=UTF-8',
    responseType = 'text',
  ) => new Promise((resolve, reject) => {
    this.session
      .post(url, data, {
        responseType,
        headers: {
          'Content-type': type,
          ...headers,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  putRequest = async (
    url,
    data,
    headers = {},
    type = 'application/json;charset=UTF-8',
    responseType = 'text',
  ) => new Promise((resolve, reject) => {
    this.session
      .put(url, data,
        {
          responseType,
          headers: {
            'Content-type': type,
            ...headers,
          },
        })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  deleteRequest = async (
    url,
    headers = {},
    type = 'application/json;charset=UTF-8',
    responseType = 'text',
  ) => new Promise((resolve, reject) => {
    this.session
      .delete(url,
        {
          responseType,
          headers: {
            'Content-type': type,
            ...headers,
          },
        })
      .then((response) => {
        if (response.status === 204) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const HttpClient = new _HttpClient();

export default HttpClient;
