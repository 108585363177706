<template>
  <md-tabs
    class="image-bank"
    :class="{'image-bank__sub_category' : subCategory}"
    :md-active-tab="headTabIdChain"
    @md-changed="onActiveTabUpdated"
  >
    <template
      slot="md-tab"
      slot-scope="{ tab }"
    >
      <div class="image-bank__tab">
        <img
          v-if="tab.icon"
          :src="tab.icon"
          :alt="tab.label"
          class="image-bank__tab-icon"
        >
        <span
          v-if="tab.label"
          :class="{ 'image-bank__tab-hidden-label': tab.icon }"
        >
          {{ tab.label }}
        </span>
      </div>
    </template>
    <md-tab
      v-for="category of categories"
      :id="getCategoryId(category)"
      :key="getCategoryId(category)"
      ref="tabs"
      :md-icon="category.hideIcon ? undefined : iconCategorySource(category.name, category.icon)"
      :md-label="category.label"
    >
      <image-bank
        v-if="viewSubImageBank(category)"
        :categories="category.subCategories"
        :image-bank-source="imageBankSource"
        :sub-category="true"
        :tab-id-chain="tailTabIdChain"
        @onImageSourceSelected="onImageSourceSelected"
        @onTabIdChainUpdated="onTabIdChainUpdated"
      />
      <image-list
        v-else-if="!category.subCategories"
        :images="smallImages(category)"
        @onImageSelected="onImageSelected(category.name, $event)"
      />
    </md-tab>
  </md-tabs>
</template>

<script>
import _head from 'lodash/head';
import _tail from 'lodash/tail';
import ImageList from '@/components/imageList/ImageList.vue';

/**
 * @emit onImageSourceSelected - image was selected
 * @emit onTabIdChainUpdated - tabIdChain was selected
 */
export default {
  name: 'ImageBank',
  components: { ImageList },
  props: {
    imageBankSource: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    subCategory: {
      type: Boolean,
      default: false,
    },
    tabIdChain: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    viewTabIds: new Set(),
    actionTabId: null,
  }),
  computed: {
    headTabIdChain() {
      const head = _head(this.tabIdChain);
      if (this.categories.some((category) => this.getCategoryId(category) === head)) {
        return head;
      }

      return undefined;
    },
    tailTabIdChain() {
      return _tail(this.tabIdChain);
    },
  },
  methods: {
    onTabIdChainUpdated(tabIdChain) {
      this.$emit('onTabIdChainUpdated', [this.actionTabId, ...tabIdChain]);
    },
    onActiveTabUpdated(tabId) {
      if (!tabId) return;

      this.actionTabId = tabId;
      this.viewTabIds.add(tabId);
      this.$forceUpdate();

      const index = this.categories
        .findIndex((category) => this.getCategoryId(category) === tabId);
      if (index === -1) return;

      if (this.actionTabId !== this.headTabIdChain
        && !this.viewSubImageBank(this.categories[index])) {
        this.$emit('onTabIdChainUpdated', [this.actionTabId]);
      }

      this.$refs.tabs[index].$el.style.height = `${this.$el.clientHeight - 48}px`;
      setTimeout(() => {
        this.$refs.tabs[index].$el.parentElement.parentElement.style.height = `${this.$el.clientHeight - 48}px`;
      }, 0);
    },
    viewSubImageBank(category) {
      return category.subCategories
        && category.subCategories.length
        && this.viewTabIds.has(this.getCategoryId(category));
    },
    iconCategorySource(categoryName, iconName = 'icon') {
      return `${this.imageBankSource}${categoryName}/${iconName.replace(/^(\w+)$/, '$1.svg')}`;
    },
    imageSmallCategorySource(categoryName, imageNumber) {
      return `${this.imageBankSource}${categoryName}/small/${imageNumber}.jpg`;
    },
    imageMediumCategorySource(categoryName, imageNumber) {
      return `${this.imageBankSource}${categoryName}/medium/${imageNumber}.jpg`;
    },
    onImageSelected(categoryName, image) {
      const imageSource = this.imageMediumCategorySource(categoryName, image.number);
      this.$emit('onImageSourceSelected', imageSource);
    },
    onImageSourceSelected(imageSource) {
      this.$emit('onImageSourceSelected', imageSource);
    },
    getCategoryId(category) {
      return `${category.name}_${category.icon}`;
    },
    smallImages(category) {
      if (Array.isArray(category.images)) {
        return category.images.map((image) => ({
          id: image.number,
          source: this.imageSmallCategorySource(category.name, image.number),
          number: image.number,
          title: image.label,
        }));
      }

      const count = category.imageCount || 0;
      return [...new Array(count).keys()].map((i) => ({
        id: i + 1,
        source: this.imageSmallCategorySource(category.name, i + 1),
        number: i + 1,
        title: '',
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/imageBank.scss";
</style>
