const TYPE = 'type';
const NAME = 'name';

export default class CustomMarkName {
  constructor(parameters) {
    const {
      type,
      name,
    } = parameters;
    this.type = type;
    this.name = name;
  }

  static fromApi(object = {}) {
    return new CustomMarkName(
      {
        type: object[TYPE],
        name: object[NAME],
      },
    );
  }

  toApi() {
    return {
      [TYPE]: this.type,
      [NAME]: this.name,
    };
  }
}
