<template>
  <div
    :class="classList"
    class="input-wrap"
  >
    <md-field
      :class="messageClass"
      :style="mdFieldStyle"
      :md-counter="false"
      :md-inline="inline"
      :md-clearable="clearable"
    >
      <label>{{ label }}</label>
      <md-input
        v-model="localValue"
        :disabled="onlyRead"
        :maxlength="maxlength"
        :style="styles"
        v-on="$listeners"
        @input="handleInput"
      />
      <span
        v-for="error of validation"
        :key="error"
        class="md-error"
      >
        {{ error }}
      </span>
    </md-field>
  </div>
</template>

<script>
export default {
  name: 'CustomInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    locked: {
      type: Boolean,
      default: false,
    },
    smallField: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Array,
      default: () => [],
    },
    onlyRead: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    styles: {
      type: [String, Object],
      default: '',
    },
    inline: {
      type: Boolean,
      default: false,
    },
    mdFieldStyle: {
      type: [String, Object],
      default: '',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  computed: {
    classList() {
      return {
        'input-wrap--small': this.smallField,
      };
    },
    messageClass() {
      return {
        'md-invalid': this.validation && this.validation.length > 0,
      };
    },
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.localValue = newValue;
      }
    },
  },
  methods: {
    handleInput() {
      this.$emit('input', this.localValue);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/input.scss";
</style>
