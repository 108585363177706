<template>
  <app-dialog
    :show-dialog="showDialog"
    :get-localize="getLocalize"
    cancel-icon="close"
    save-icon="crop"
    @onDialogClosed="onDialogClosed"
    @onSave="onSave"
  >
    <cropper
      ref="cropper"
      class="crop-dialog__cropper"
      background-class="cropper-background"
      :src="imageSource"
      :canvas="false"
      :resize-image="false"
      :default-size="defaultSize"
      :default-position="defaultPosition"
      :default-visible-area="defaultVisibleArea"
      :stencil-props="{ aspectRatio }"
      image-restriction="none"
    />
  </app-dialog>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import CropArea from '@/api/models/CropArea';
import AppDialog from '@/components/appDialog/AppDialog.vue';

/**
 * @emit onUpdated - image source was cropped
 * @emit onDialogClosed - dialog closed
 */
export default {
  name: 'CropDialog',
  components: { AppDialog, Cropper },
  props: {
    imageSource: {
      type: String,
      default: '',
      required: false,
    },
    showDialog: {
      type: Boolean,
      required: true,
    },
    cropArea: {
      type: CropArea,
      default: null,
    },
    marks: {
      type: Array,
      default: () => [],
    },
    getLocalize: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      aspectRatio: 3 / 2,
    };
  },
  methods: {
    onDialogClosed() {
      this.$emit('onDialogClosed');
    },
    onSave() {
      const {
        coordinates: {
          left: x,
          top: y,
          height,
          width,
        },
      } = this.$refs.cropper.getResult();

      this.$emit('onUpdated', {
        x,
        y,
        height,
        width,
      });
    },
    defaultSize({ imageSize, visibleArea }) {
      if (this.cropArea && this.cropArea.height > 0 && this.cropArea.width > 0) {
        return {
          width: this.cropArea.width,
          height: this.cropArea.width / 1.5,
        };
      }

      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height,
      };
    },
    defaultPosition({ imageSize }) {
      if (this.cropArea && this.cropArea.height > 0 && this.cropArea.width > 0) {
        return {
          left: this.cropArea.x,
          top: this.cropArea.y,
        };
      }

      const maxImageWidth = Math.min(imageSize.width, imageSize.height * this.aspectRatio);
      const maxImageHeight = Math.min(imageSize.height, imageSize.width / this.aspectRatio);

      const startLeft = imageSize.width / 2 - maxImageWidth / 2;
      const startTop = imageSize.height / 2 - maxImageHeight / 2;

      return {
        left: startLeft + maxImageWidth * 0.02,
        top: startTop + maxImageHeight * 0.02,
        width: maxImageWidth * 0.96,
        height: maxImageHeight * 0.96,
      };
    },
    defaultVisibleArea({ boundaries, imageSize }) {
      if (!(boundaries
        && boundaries.width > 0
        && boundaries.height > 0)) {
        if (!(imageSize
          && imageSize.width > 0
          && imageSize.height > 0
        )) {
          return {
            left: 0,
            top: 0,
            width: imageSize.width,
            height: imageSize.height,
          };
        }
        return {
          left: 0,
          top: 0,
          width: 0,
          height: 0,
        };
      }

      const coefficientWidth = imageSize.width / boundaries.width;
      const coefficientHeight = imageSize.height / boundaries.height;
      const fitsInWidth = coefficientWidth >= coefficientHeight;
      const left = fitsInWidth
        ? 0
        : this.calculateIndent(imageSize.width, boundaries.width, coefficientHeight);
      const top = fitsInWidth
        ? this.calculateIndent(imageSize.height, boundaries.height, coefficientWidth)
        : 0;

      return {
        left,
        top,
        width: imageSize.width - left * 2,
        height: imageSize.height,
      };
    },
    calculateIndent(naturalSize, size, coefficient) {
      // eslint-disable-next-line no-mixed-operators
      return (naturalSize / coefficient - size) * coefficient / 2;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/cropDialog.scss";
</style>
