import _flatMap from 'lodash/flatMap';
import _get from 'lodash/get';
import _invoke from 'lodash/invoke';
import Mark from '@/api/models/Mark';
import request from '@/api/httpClient';

export class MarksService {
  static async getMarks(bookId, pageId) {
    try {
      const response = await request.getRequest(
        `/books/${bookId}/pages/${pageId}/marks`,
        {},
        {},
      );
      const marks = _flatMap(
        _get(response, 'data'),
        (item) => _invoke(
          item,
          ['marks', 'map'],
          Mark.fromApi,
        ) || [],
      );
      return { success: true, data: marks };
    } catch (e) {
      return { success: false, data: { error: '' } };
    }
  }

  static async createMark(bookId, pageId, mark) {
    try {
      const response = await request.postRequest(
        `/books/${bookId}/pages/${pageId}/marks`,
        mark.toApi(),
        {},
      );
      const newMark = Mark.fromApi(response.data);
      return { success: true, data: newMark };
    } catch (e) {
      console.error(e);
      return { success: false, data: { error: '' } };
    }
  }

  static async updateMark(bookId, pageId, markId, mark) {
    try {
      const response = await request.putRequest(
        `/Books/${bookId}/pages/${pageId}/marks/${markId}`,
        mark.toApi(),
        { 'Content-Type': 'multipart/form-data' },
      );
      const updatedMark = Mark.fromApi(response.data);
      return { success: true, data: updatedMark };
    } catch (e) {
      return { success: false, data: { error: '' } };
    }
  }

  static async deleteMark(bookId, pageId, markId) {
    try {
      await request.deleteRequest(
        `/Books/${bookId}/pages/${pageId}/marks/${markId}`,
      );
      return { success: true };
    } catch (e) {
      return { success: false, data: { error: '' } };
    }
  }
}
