import getInitialState from '@/store/modules/messages/getInitialState';
import mutations from './mutations';
import actions from './actions';

export default ({
  state: getInitialState,
  mutations: {
    ...mutations,
  },
  actions: {
    ...actions,
  },
});
