import { LOGOUT } from '@/store/actionTypes';
import { IMAGE_SEARCH_RESET_STATE } from '@/store/modules/imageSearch/mutationTypes';
import { NOTIFICATION_RESET_STATE } from '@/store/modules/notifications/mutationTypes';
import { SESSION_RESET_STATE } from '@/store/modules/session/mutationTypes';
import { WORKSPACE_RESET_STATE } from '@/store/modules/workspace/mutationTypes';
import { MESSAGES_RESET_STATE } from '@/store/modules/messages/mutationTypes';
import { USER_SETTINGS_RESET_STATE } from '@/store/modules/userSettings/mutationTypes';

export default ({
  [LOGOUT]({ commit }) {
    commit(IMAGE_SEARCH_RESET_STATE);
    commit(NOTIFICATION_RESET_STATE);
    commit(SESSION_RESET_STATE);
    commit(WORKSPACE_RESET_STATE);
    commit(MESSAGES_RESET_STATE);
    commit(USER_SETTINGS_RESET_STATE);
  },
});
