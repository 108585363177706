import { BooksService } from '@/api/resources/BooksService';
import { PagesService } from '@/api/resources/PagesService';
import { AccountService } from '@/api/resources/AccountService';
import { MediaService } from '@/api/resources/MediaService';
import { WorkspaceService } from '@/api/resources/WorkspaceService';
import { MarksService } from '@/api/resources/MarksService';
import { AttemptsService } from '@/api/resources/AttemptsService';
import { CommentsService } from '@/api/resources/CommentsService';
import { SessionService } from '@/api/resources/SessionService';
import { NotificationsService } from '@/api/resources/NotificationsService';
import { SpeechService } from '@/api/resources/SpeechService';

export const API = {
  AccountService,
  BooksService,
  PagesService,
  MediaService,
  WorkspaceService,
  MarksService,
  AttemptsService,
  CommentsService,
  SessionService,
  NotificationsService,
  SpeechService,
};
