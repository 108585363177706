<template>
  <div class="placeholder-message">
    <md-icon class="placeholder-message__icon">
      {{ icon }}
    </md-icon>
    <span class="placeholder-message__text">
      {{ message }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'PlaceholderMessage',
  props: {
    icon: {
      type: String,
      default: '',
      required: true,
    },
    message: {
      type: String,
      default: '',
      required: true,
    },
    getLocalize: {
      type: Function,
      default: (key) => key,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/placeholderMessage.scss";
</style>
