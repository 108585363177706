<template>
  <div class="template-switch">
    <md-switch
      v-model="localValue"
      class="template-switch__switch md-primary"
      :disabled="readOnly"
    >
      <md-icon class="main-theme-icon template-switch__icon">
        collections_bookmark
      </md-icon>
      <span class="template-switch__label">
        {{ title }}
      </span>
    </md-switch>
  </div>
</template>

<script>
/**
 * @emit change - value was updated
 */
export default {
  name: 'TemplateSwitch',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.localValue = newValue;
    },
    localValue(newValue) {
      this.$emit('change', newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/templateSwitch.scss";
</style>
