import cookies from 'js-cookie';
import { LOCALE_NAME_IN_COOKIE } from './const';

const domain = process.env.VUE_APP_FM_COOKIE_DOMAIN;

function setLocaleInCookieOnYear(locale) {
  const value = `c=${locale}|uic=${locale}`;
  cookies.set(LOCALE_NAME_IN_COOKIE, value, {
    expires: 365,
    domain,
    secure: true,
  });
}

function getLocaleFromCookie() {
  const value = cookies.get(LOCALE_NAME_IN_COOKIE);
  if (!value) return undefined;

  const match = value.match(/c=(\w+)(?:-[A-Z]+)?\|uic=\1/);
  if (!match) return undefined;

  const [, locale] = match;
  return locale;
}

export default {
  setLocaleInCookieOnYear,
  getLocaleFromCookie,
};
