import student1 from '@/assets/images/student1.svg';
import student2 from '@/assets/images/student2.svg';
import student3 from '@/assets/images/student3.svg';

export default {
  methods: {
    studentSource(pageIndex) {
      switch (pageIndex % 3) {
        case 0:
          return student1;
        case 1:
          return student2;
        default:
          return student3;
      }
    },
  },
};
