import request from '@/api/httpClient';

export class SpeechService {
  static async getUrl(text, language) {
    try {
      const response = await request.getRequest(
        '/Speech',
        {
          text,
          responseType: 'Url',
          language,
        },
        {},
      );
      return { success: true, data: response.data };
    } catch (e) {
      return { success: false, data: { error: '' } };
    }
  }
}
