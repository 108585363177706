<template>
  <app-dialog
    :show-dialog="showDialog"
    :get-localize="getLocalize"
    class="app-dialog_marks"
    cancel-icon="close"
    save-icon="category"
    @onDialogClosed="onDialogClosed"
    @onSave="onSave"
  >
    <div class="mark-dialog">
      <div
        ref="imageBox"
        class="mark-dialog__image_box"
      >
        <div class="mark-dialog__crop_box">
          <image-viewer
            ref="image"
            :style="imageStyle"
            :image-source="imageSource"
            :image-alt="imageAlt"
            :crop-area="cropArea"
            @click.native="onAddMark"
            @onLoadedImage="onUpdateSize"
          />
          <vue-drag-resize
            v-for="(mark, index) in localMarks"
            :key="`${mark.x},${mark.y}`"
            style="cursor: move"
            :is-active="true"
            :is-resizable="false"
            :parent-limitation="true"
            :w="markSize"
            :h="markSize"
            :x="mark.x"
            :y="mark.y"
            :parent-w="parentWidth"
            :parent-h="parentHeight"
            @dragstop="onDragStop(index, $event)"
            @clicked="onDragStart"
          >
            <img
              style="display: block;"
              :src="getMarkImageSource(mark.type)"
            >
          </vue-drag-resize>
        </div>
      </div>
    </div>
    <confirm-dialog
      :show-dialog="showDeleteAllDialog"
      :title-text="getLocalize('markDialog.deleteAllMarksDialogTitle')"
      :confirm-button-text="getLocalize('markDialog.confirmButtonTitle')"
      :cancel-button-text="getLocalize('markDialog.cancelButtonTitle')"
      cancel-icon="close"
      confirm-icon="delete"
      @onConfirm="showDeleteAllDialog = false; onDeleteAllMarks();"
      @onCancel="showDeleteAllDialog = false"
    />
    <template v-slot:footer>
      <div class="mark-dialog__marks">
        <div
          v-for="predefinedMarkType of predefinedMarkTypes"
          :key="predefinedMarkType"
          class="mark-dialog__mark"
          :class="{ selected: selectedMark === predefinedMarkType }"
          @click="selectMark(predefinedMarkType)"
        >
          <md-badge :md-content="getMarkCount(predefinedMarkType)">
            <img
              class="icon"
              :src="getMarkImageSource(predefinedMarkType)"
            >
          </md-badge>
          <div class="name">
            {{ getLocalize(`markDialog.${predefinedMarkType.toLowerCase()}`) }}
          </div>
        </div>
        <div
          v-for="customMarkType of customMarkTypes"
          :key="customMarkType"
          class="mark-dialog__mark"
          :class="{ selected: selectedMark === customMarkType }"
          @click="selectMark(customMarkType)"
        >
          <md-badge :md-content="getMarkCount(customMarkType)">
            <img
              class="icon icon_custom"
              :src="getMarkImageSource(customMarkType)"
            >
          </md-badge>
          <input
            class="mark-dialog__custom-mark"
            :value="getCustomMarkTypeName(customMarkType)"
            @blur="onCustomMarkUpdated($event, customMarkType)"
          >
        </div>
      </div>
    </template>
    <template v-slot:buttons>
      <div class="mark-dialog__footer-modes">
        <custom-button
          :title="getLocalize('markDialog.deleteAllMarksButtonTitle')"
          icon="delete_forever"
          class="md-raised md-accent btn-delete-marks"
          type="custom"
          @click="onShowDeleteAllDialog"
        />
        <SwitchTwoLabel
          v-model="modeDeleteMark"
          :on-label="getLocalize('markDialog.deleteMarkMode')"
          on-icon="delete"
          :off-label="getLocalize('markDialog.addMarkMode')"
          off-icon="add_circle"
        />
      </div>
    </template>
  </app-dialog>
</template>

<script>
import VueDragResize from 'vue-drag-resize';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _round from 'lodash/round';
import CustomButton from '@/components/buttons/CustomButton.vue';
import ImageViewer from '@/components/imageViewer/ImageViewer.vue';
import CropArea from '@/api/models/CropArea';
import { CUSTOM_MARKS, PREDEFINED_MARKS } from '@/helpers/const';
import AppDialog from '@/components/appDialog/AppDialog.vue';
import ConfirmDialog from '@/components/confirmDialog/ConfirmDialog.vue';
import Mark from '@/api/models/Mark';
import CustomMarkName from '@/api/models/CustomMarkName';
import getMarkImageSource from '@/mixins/getMarkImageSource';
import SwitchTwoLabel from '@/components/switchTwoLabel/SwitchTwoLabel.vue';

/**
 * @emit onUpdated - marks was updated
 * @emit onCustomMarkUpdated - custom mark was updated
 * @emit onDialogClosed - dialog closed
 */
export default {
  name: 'MarkDialog',
  components: {
    SwitchTwoLabel,
    AppDialog,
    ConfirmDialog,
    ImageViewer,
    VueDragResize,
    CustomButton,
  },
  mixins: [getMarkImageSource],
  props: {
    imageSource: {
      type: String,
      default: '',
      required: false,
    },
    imageAlt: {
      type: String,
      default: '',
      required: false,
    },
    marks: {
      type: Array,
      required: true,
    },
    customMarks: {
      type: Array,
      default: () => [],
    },
    showDialog: {
      type: Boolean,
      required: true,
    },
    cropArea: {
      type: CropArea,
      default: null,
    },
    getLocalize: {
      type: Function,
      required: true,
    },
    coefficientMarkSize: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    customMarkTypes: CUSTOM_MARKS,
    predefinedMarkTypes: PREDEFINED_MARKS,
    selectedMark: null,
    localMarks: [],
    parentWidth: 0,
    parentHeight: 0,
    markSize: 20,
    modeDeleteMark: false,
    showDeleteAllDialog: false,
    imageMaxHeight: 0,
    dragStartTime: null,
  }),
  computed: {
    imageStyle() {
      return {
        'max-height': this.imageMaxHeight ? `${this.imageMaxHeight}px` : '',
      };
    },
  },
  watch: {
    showDialog(newValue, oldValue) {
      if (newValue && !oldValue) {
        window.addEventListener('resize', this.onUpdateSize);
        this.localMarks = [];
      } else if (!newValue && oldValue) {
        window.removeEventListener('resize', this.onUpdateSize);
      }
    },
  },
  methods: {
    onDialogClosed() {
      this.$emit('onDialogClosed');
    },
    onUpdateSize() {
      this.imageMaxHeight = this.$refs.imageBox.clientHeight;
      setTimeout(() => {
        this.parentHeight = this.$refs.image.$el.clientHeight;
        this.parentWidth = this.$refs.image.$el.clientWidth;
        this.markSize = this.parentWidth * this.coefficientMarkSize;

        const cWidth = this.cropArea.width / (this.parentWidth || 1);
        const cHeight = this.cropArea.height / (this.parentHeight || 1);
        this.localMarks = this.marks.map((mark) => ({
          ...mark,
          x: (mark.x - this.cropArea.x) / cWidth,
          y: (mark.y - this.cropArea.y) / cHeight,
        }));
      }, 0);
    },
    onSave() {
      const imageWidth = this.$refs.image.$el.clientWidth || 1;
      const imageHeight = this.$refs.image.$el.clientHeight || 1;
      const cWidth = this.cropArea.width / imageWidth;
      const cHeight = this.cropArea.height / imageHeight;
      const marks = this.localMarks.map((mark) => new Mark({
        ...mark,
        x: mark.x * cWidth + this.cropArea.x,
        y: mark.y * cHeight + this.cropArea.y,
      }));

      this.$emit('onUpdated', marks);
    },
    onShowDeleteAllDialog() {
      if (this.localMarks && this.localMarks.length) {
        this.showDeleteAllDialog = true;
      }
    },
    selectMark(markType) {
      this.selectedMark = markType;
    },
    getMarkCount(markType) {
      return this.localMarks.filter((mark) => mark.type === markType).length;
    },
    getCustomMarkTypeName(markType) {
      return _get(_find(this.customMarks, (item) => item.type === markType), 'name');
    },
    onAddMark(e) {
      if (!this.modeDeleteMark && this.selectedMark && e.currentTarget === e.target) {
        this.localMarks.push({
          x: e.layerX - this.markSize / 2,
          y: e.layerY - this.markSize / 2,
          type: this.selectedMark,
        });
      }
    },
    onDragStart() {
      this.dragStartTime = new Date();
    },
    isClickMark(index, left, top) {
      const mark = this.localMarks[index];
      return _round(mark.x) === _round(left)
        && _round(mark.y) === _round(top)
        && (new Date() - this.dragStartTime < 200);
    },
    onDragStop(index, { left, top }) {
      if (this.isClickMark(index, left, top)) {
        this.deleteMark(index);
      } else {
        this.localMarks[index].x = left;
        this.localMarks[index].y = top;
      }
    },
    onDeleteAllMarks() {
      this.localMarks = [];
    },
    deleteMark(index) {
      if (this.modeDeleteMark) {
        this.localMarks = this.localMarks.filter((mark, i) => i !== index);
      }
    },
    onCustomMarkUpdated(event, type) {
      const name = event.target.value;
      if (name !== this.getCustomMarkTypeName(type)) {
        const customMark = new CustomMarkName({
          type,
          name,
        });
        this.$emit('onCustomMarkUpdated', customMark);
      }
    },
    onAddModeMark() {
      this.modeDeleteMark = false;
    },
    onDeleteModeMark() {
      this.modeDeleteMark = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/markDialog.scss";
</style>
