<template>
  <vue-aspect-ratio
    ar="3:4"
    class="responsive-book"
  >
    <checkered-background
      :background-color="backgroundColor"
      :line-color="backgroundTint"
      class="back-page"
    >
      <div class="back-page__box-logo">
        <img
          :src="logoLink"
          alt=""
          class="back-page__logo"
        >
      </div>
      <div
        class="back-page__date"
        :style="dateStyle"
      >
        {{ formatDate }}
      </div>
    </checkered-background>
  </vue-aspect-ratio>
</template>

<script>
import VueAspectRatio from 'vue-aspect-ratio';
import moment from 'moment';
import CheckeredBackground from '@/components/checkeredBackground/CheckeredBackground.vue';

export default {
  name: 'BackPage',
  components: {
    CheckeredBackground,
    VueAspectRatio,
  },
  props: {
    backgroundColor: {
      type: String,
      default: '',
      required: false,
    },
    backgroundTint: {
      type: String,
      default: '',
      required: false,
    },
    date: {
      type: String,
      required: true,
    },
    dateFormat: {
      type: String,
      default: 'LL',
    },
    locale: {
      type: String,
      required: true,
    },
  },
  computed: {
    dateStyle() {
      const color = this.backgroundTint.replace(/(.+?,.+?,.+?,).+/, '$1 1)');
      return {
        color,
      };
    },
    formatDate() {
      moment.locale(this.locale);
      const date = moment(this.date);
      if (date.isValid()) {
        return date.format(this.dateFormat);
      }

      return '';
    },
    logoLink() {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`@/assets/${process.env.VUE_APP_LOGO_NAME}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/backPage.scss";
@import "~@/assets/styles/desktop/components/responsiveBook.scss";
</style>
