import request from '@/api/httpClient';
import Image from '@/api/models/Image';
import cookie from '@/helpers/cookie';
import { parseErrors } from '@/api/utils';

export class MediaService {
  static async getImages(search, sourceType, nextPageToken) {
    try {
      const locale = cookie.getLocaleFromCookie();
      const requestTask = request.getRequest(
        '/Media/images',
        {
          query: search,
          source: sourceType,
          paginationToken: nextPageToken,
          language: locale,
        },
        {},
      ).then((response) => {
        const images = response.data.data.map(Image.fromApi);
        return {
          success: true,
          data: {
            images,
            nextPageToken: response.data.token,
          },
        };
      });
      return await parseErrors(requestTask);
    } catch (e) {
      return { success: false };
    }
  }

  static async uploadImage(file) {
    try {
      const formData = new FormData();
      formData.append('file', file, file.name);

      const requestTask = request.postRequest(
        '/Media/images',
        formData,
        { 'Content-Type': 'multipart/form-data' },
      ).then((response) => ({ success: true, data: response.data }));
      return await parseErrors(requestTask);
    } catch (e) {
      console.error(e);
      return { success: false };
    }
  }

  static async uploadAudio(file) {
    try {
      const formData = new FormData();
      formData.append('file', file, file.name);

      const requestTask = request.postRequest(
        '/Media/audios',
        formData,
        { 'Content-Type': 'multipart/form-data' },
      ).then((response) => ({ success: true, data: response.data }));
      return await parseErrors(requestTask);
    } catch (e) {
      console.error(e);
      return { success: false };
    }
  }
}
