<template>
  <div class="text-styles">
    <div
      ref="divText"
      :contenteditable="!onlyRead"
      class="text-styles__text"
      :class="{
        'text-styles__text_solve': onlyRead,
        'text-styles__text_notask': selectText,
        'text-styles__text_padding': paddingRight,
      }"
      @input="handleInput"
      @keydown="onKeyDown"
      @paste="onPasteText"
    />
    <div
      v-if="maxLength || showTextStylesBlock"
      class="text-styles__footer"
    >
      <template v-if="showTextStylesBlock">
        <div class="text-styles__styler">
          <md-button
            class="md-icon-button md-primary text-styles__toggle"
            :class="{ active: showStyler }"
            @click="toggleStyler"
          >
            <md-icon>title</md-icon>
          </md-button>
          <div
            v-if="showTextStylesBlock && showStyler"
            class="text-styles__actions"
          >
            <div
              :title="$t('stylizedTextArea.ttlBold')"
              class="text-styles__action"
              @click="execBold"
            >
              <md-icon class="text-styles__action-icon">
                format_bold
              </md-icon>
            </div>
            <div
              :title="$t('stylizedTextArea.ttlItalic')"
              class="text-styles__action"
              @click="execItalic"
            >
              <md-icon class="text-styles__action-icon">
                format_italic
              </md-icon>
            </div>
            <div
              :title="$t('stylizedTextArea.ttlUnderline')"
              class="text-styles__action"
              @click="execUnderline"
            >
              <md-icon class="text-styles__action-icon">
                format_underlined
              </md-icon>
            </div>
            <div
              :title="$t('stylizedTextArea.ttlTextColor')"
              class="text-styles__action"
            >
              <div class="text-styles__action-dropdown">
                <div class="text-styles__action-group">
                  <md-icon
                    class="text-styles__action-icon line-color"
                    :style="iconTextColorStyles"
                    @click.native="execTextColor"
                  >
                    format_color_text
                  </md-icon>
                  <md-icon
                    class="text-styles__action-dropdown-icon"
                    @click.native="showTextColorDialog"
                  >
                    expand_more
                  </md-icon>
                </div>
              </div>
            </div>
            <div
              :title="$t('stylizedTextArea.ttlBackgroundTextColor')"
              class="text-styles__action"
            >
              <div class="text-styles__action-dropdown">
                <div class="text-styles__action-group">
                  <md-icon
                    class="text-styles__action-icon line-color"
                    :style="iconBackgroundTextColorStyles"
                    @click.native="execBackgroundTextColor"
                  >
                    border_color
                  </md-icon>
                  <md-icon
                    class="text-styles__action-dropdown-icon"
                    @click.native="showBackgroundTextColorDialog"
                  >
                    expand_more
                  </md-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <color-dialog
        :colors-sets="colorsSets"
        :get-localize="getLocalize"
        :show-dialog="showTextColor"
        :value="localTextColor"
        @onColorSelected="onTextColorUpdated"
        @onDialogClosed="showTextColor = false"
      />
      <color-dialog
        :colors-sets="colorsSets"
        :get-localize="getLocalize"
        :show-dialog="showBackgroundTextColor"
        :value="localBackgroundTextColor"
        @onColorSelected="onBackgroundTextColorUpdated"
        @onDialogClosed="showBackgroundTextColor = false"
      />
      <div
        v-if="maxLength"
        class="text-styles__counter"
        :class="textStylesCounterClass"
      >
        {{ localTextLength }} / {{ maxLength }}
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @emits input - value was updated
 */
import { getTextLengthFromHtml } from '@/helpers/functions';
import ColorDialog from '@/components/colorDialog/ColorDialog.vue';

export default {
  name: 'StylizedTextArea',
  components: { ColorDialog },
  props: {
    value: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: 'black',
    },
    backgroundTextColor: {
      type: String,
      default: '#00FF00',
    },
    isAllowTextStyles: {
      type: Boolean,
      default: false,
    },
    onlyRead: {
      type: Boolean,
      default: false,
    },
    lessCharactersForCounterToRed: {
      type: Number,
      default: null,
    },
    maxLength: {
      type: Number,
      default: null,
    },
    selectText: {
      type: Boolean,
      default: false,
    },
    paddingRight: {
      type: Boolean,
      default: false,
    },
    colorsSets: {
      type: Array,
      default: () => [],
      required: false,
    },
    includeEnter: {
      type: Boolean,
      default: false,
    },
    keyEnterLength: {
      type: Number,
      default: 35,
    },
    getLocalize: {
      type: Function,
      default: (key) => key,
      required: false,
    },
  },
  data() {
    return {
      localValue: this.value,
      showStyler: false,
      showTextColor: false,
      showBackgroundTextColor: false,
      localTextColor: this.textColor,
      localBackgroundTextColor: this.backgroundTextColor,
    };
  },
  computed: {
    localTextLength() {
      return getTextLengthFromHtml(this.localValue, this.keyEnterLength);
    },
    showTextStylesBlock() {
      return !this.onlyRead && this.isAllowTextStyles;
    },
    textStylesCounterClass() {
      if (this.maxLength
          && this.lessCharactersForCounterToRed
          && this.maxLength - this.localTextLength < this.lessCharactersForCounterToRed) {
        return 'text-styles__counter-red';
      }

      return '';
    },
    iconBackgroundTextColorStyles() {
      return {
        'background-image': `linear-gradient(to top, ${this.localBackgroundTextColor} 30%, #000 30%, #000 100%)`,
      };
    },
    iconTextColorStyles() {
      return {
        'background-image': `linear-gradient(to top, ${this.localTextColor} 30%, #000 30%, #000 100%)`,
      };
    },
  },
  watch: {
    value(newValue) {
      if (newValue !== this.localValue) {
        this.$refs.divText.innerHTML = newValue;
        this.localValue = newValue;
      }
    },
  },
  mounted() {
    this.$refs.divText.innerHTML = this.value;
  },
  methods: {
    onKeyDown(e) {
      const keyBackspace = e.keyCode === 8;
      const keyDelete = e.keyCode === 46;
      const deleting = keyBackspace || keyDelete;
      const keyEnter = e.keyCode === 13;
      const preventKeyEnter = !this.includeEnter && keyEnter;
      const keyTab = e.keyCode === 9;

      if (preventKeyEnter || keyTab || !this.isRangeInElement(this.$refs.divText)) {
        e.preventDefault();
      } else if (!deleting) {
        const selection = document.getSelection();
        const range = selection.getRangeAt(0);
        const rangeLength = range.toString().length;

        const newCharLength = keyEnter ? this.keyEnterLength : 1;

        if (this.maxLength
          && this.maxLength < (this.localTextLength - rangeLength + newCharLength)) {
          e.preventDefault();
        }
      }
    },
    onPasteText(e) {
      const paste = (e.clipboardData || window.clipboardData).getData('text');
      const newText = paste.replace(/[\t\n]/g, ' ');

      if (this.isRangeInElement(this.$refs.divText)) {
        const selection = document.getSelection();
        const range = selection.getRangeAt(0);
        const rangeLength = range.toString().length;
        const pastedLength = this.maxLength
          ? this.maxLength - (this.localTextLength - rangeLength)
          : Infinity;
        const pastedText = newText.slice(0, pastedLength);

        if (pastedText) {
          selection.deleteFromDocument();
          selection.getRangeAt(0).insertNode(document.createTextNode(pastedText));
          selection.collapseToEnd();
          this.localValue = this.$refs.divText.innerHTML;
          this.$emit('input', this.localValue);
        }
      }

      e.preventDefault();
    },
    handleInput(e) {
      const newValue = this.isAllowTextStyles ? e.target.innerHTML : e.target.innerText;
      this.$emit('input', newValue);
      this.localValue = newValue;
    },
    execBold() {
      this.execCommand('bold');
    },
    execItalic() {
      this.execCommand('italic');
    },
    execUnderline() {
      this.execCommand('underline');
    },
    execTextColor() {
      this.execCommand('styleWithCSS', false, true);
      this.execCommand('foreColor', false, this.localTextColor);
      this.execCommand('styleWithCSS', false, false);
    },
    execBackgroundTextColor() {
      this.execCommand('styleWithCSS', false, true);
      this.execCommand('hiliteColor', false, this.localBackgroundTextColor);
      this.execCommand('styleWithCSS', false, false);
    },
    showTextColorDialog() {
      this.showTextColor = true;
    },
    showBackgroundTextColorDialog() {
      this.showBackgroundTextColor = true;
    },
    execCommand(commandId, showUI = false, value = null) {
      if (!this.isCollapsedSelection() && !this.isRangeInElement(this.$refs.divText)) return;
      document.execCommand(commandId, showUI, value);
    },
    isCollapsedSelection() {
      const selection = document.getSelection();

      return selection.isCollapsed;
    },
    isRangeInElement(element) {
      const selection = document.getSelection();

      const range = selection.getRangeAt(0);
      const { commonAncestorContainer } = range;
      return element.contains(commonAncestorContainer);
    },
    toggleStyler() {
      this.showStyler = !this.showStyler;
    },
    onTextColorUpdated(color) {
      this.localTextColor = color;
      this.execTextColor();
    },
    onBackgroundTextColorUpdated(color) {
      this.localBackgroundTextColor = color;
      this.execBackgroundTextColor();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/stylizedTextArea.scss";
</style>
