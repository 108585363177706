import NotificationDetails from '@/api/models/NotificationDetails';

const ID = 'id';
const EVENT = 'event';
const MESSAGE = 'message';
const TYPE = 'type';
const NEW = 'new';
const CREATION_DATE = 'createDate';
const ADDITIONAL_DATA = 'data';

export default class Notification {
  constructor(parameters) {
    const {
      id,
      event,
      message,
      type,
      isNew,
      created,
      details,
    } = parameters;
    this.id = id;
    this.event = event;
    this.message = message;
    this.type = type;
    this.isNew = isNew;
    this.created = created;
    this.details = details && new NotificationDetails(details);
  }

  static fromApi(object = {}) {
    return new Notification(
      {
        id: object[ID],
        event: object[EVENT],
        message: object[MESSAGE],
        type: object[TYPE],
        isNew: object[NEW],
        created: object[CREATION_DATE],
        details: object[ADDITIONAL_DATA] && NotificationDetails.fromApi(object[ADDITIONAL_DATA]),
      },
    );
  }

  toApi() {
    return {
      [EVENT]: this.event,
      [MESSAGE]: this.message,
      [TYPE]: this.type,
      [NEW]: this.isNew,
      [CREATION_DATE]: this.created,
      [ADDITIONAL_DATA]: this.details,
    };
  }
}
