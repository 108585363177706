export default class SignInApiModel {
  constructor(parameters) {
    const {
      login,
      password,
    } = parameters;
    this.login = login;
    this.password = password;
  }
}
