<template>
  <md-button
    class="md-icon-button md-raised md-primary audio-button"
    :disabled="isPlay"
    @click="onStartPlay"
  >
    <md-icon>play_arrow</md-icon>
  </md-button>
</template>

<script>
/**
 * @emit onPlay - playback has begun
 * @emit onAudioEnd - playback has stopped because the end of the media was reached
 */
export default {
  name: 'PlayAudio',
  props: {
    audioSrc: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isPlay: false,
      audio: new Audio(),
    };
  },
  computed: {
    isDisabled() {
      return !this.audioSrc || this.isPlay;
    },
  },
  watch: {
    audioSrc: {
      immediate: true,
      handler(newValue) {
        this.audio.src = newValue;
      },
    },
  },
  beforeDestroy() {
    this.audio.pause();
  },
  mounted() {
    this.audio.addEventListener('ended', this.onEndedPlay);
  },
  methods: {
    onStartPlay() {
      this.isPlay = true;
      this.emitPlaying();
      this.audio.play();
    },
    onEndedPlay() {
      this.isPlay = false;
      this.emitEnded();
    },
    emitPlaying() {
      this.$emit('onPlay');
    },
    emitEnded() {
      this.$emit('onAudioEnd');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
