import _get from 'lodash/get';
import { NUMBER_REGEXP_TEMPLATE_FOR_RANDOM_ANSWER } from '@/helpers/const';

export default {
  data() {
    return {
      numberRegexp: new RegExp(NUMBER_REGEXP_TEMPLATE_FOR_RANDOM_ANSWER),
      onlyNumberRegexp: new RegExp(`${NUMBER_REGEXP_TEMPLATE_FOR_RANDOM_ANSWER}\\s*$`),
    };
  },
  methods: {
    getAnswerNumberOrOne(answer = '') {
      const matched = answer.match(this.numberRegexp);
      const [numberMatch = ''] = matched || [];
      const number = +numberMatch.replace(',', '.');
      return number === 0 ? 1 : number;
    },
    checkAnswerOnlyNumber(answer = '') {
      return this.onlyNumberRegexp.test(answer);
    },
    testExistUnits(answerUnit, units = []) {
      return units.includes(answerUnit);
    },
    getUnitGroupNames(unitGroup) {
      return unitGroup.units.map((i) => i.name);
    },
    findUnitByName(unitName, unitGroup) {
      const unitNameLowerCase = unitName.toLowerCase();
      return unitGroup.units.find((i) => i.name.toLowerCase() === unitNameLowerCase);
    },
    convertAnswerNumber(number, fromUnitName, toUnitName, unitGroup) {
      const fromCoefficient = _get(this.findUnitByName(fromUnitName, unitGroup), 'coefficient', 1);
      const toCoefficient = _get(this.findUnitByName(toUnitName, unitGroup), 'coefficient', 1);

      // eslint-disable-next-line no-mixed-operators
      return number / fromCoefficient * toCoefficient;
    },
  },
};
