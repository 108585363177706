<template>
  <div
    ref="box"
    class="box"
    @scroll="checkEndOfListReached"
  >
    <div
      ref="imageList"
      class="image-list"
    >
      <div
        v-for="image of images"
        :key="image.id"
        :style="{ 'background-image': `url(${image.source})` }"
        class="image-list__image"
        @click="onImageSelected(image)"
      >
        <span
          v-if="image.title"
          class="image-list__image-title"
        >
          {{ image.title }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>

/**
 * @emit onImageSelected - image was selected
 * @emit onEndOfListReached - end of list reached
 */
export default {
  name: 'ImageList',
  props: {
    images: {
      type: Array,
      required: true,
    },
    bottomIndent: {
      type: Number,
      default: 100,
      validator(value) {
        return value >= 0;
      },
    },
  },
  watch: {
    images() {
      setTimeout(() => {
        this.checkEndOfListReached();
      }, 0);
    },
  },
  mounted() {
    this.checkEndOfListReached();
  },
  methods: {
    onImageSelected(image) {
      this.$emit('onImageSelected', image);
    },
    checkEndOfListReached() {
      const boxBottom = this.$refs.box.getBoundingClientRect().bottom;
      const imageListBottom = this.$refs.imageList.getBoundingClientRect().bottom;

      if (imageListBottom - boxBottom <= this.bottomIndent) {
        this.$emit('onEndOfListReached');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/imageList.scss";
</style>
