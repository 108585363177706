<template>
  <header class="header">
    <md-toolbar
      md-elevation="0"
      class="header__wrap md-secondary"
    >
      <div class="header__logo-box">
        <logo :src="logoLink" />
      </div>
      <div class="header__message">
        {{ message }}
      </div>
      <div class="md-toolbar-section">
        <notifications
          :events="notifications"
          :get-localize="getLocalize"
          @onEventClicked="onEventClicked"
          @onReadAll="onReadAll"
        />
        <md-button
          class="md-icon-button"
          @click="showNavigation = true"
        >
          <md-icon>menu</md-icon>
        </md-button>
      </div>
    </md-toolbar>
    <md-drawer
      class="md-right"
      :md-active.sync="showNavigation"
      md-swipeable
      md-fixed
    >
      <md-toolbar
        class="md-transparent"
        md-elevation="0"
      >
        <span v-if="fullName">{{ fullName }}</span>
        <logo
          v-else
          :src="menuLogoLink"
        />
        <div class="md-toolbar-section-end">
          <md-button
            class="md-icon-button md-dense"
            @click="showNavigation = false"
          >
            <md-icon>close</md-icon>
          </md-button>
        </div>
      </md-toolbar>
      <md-divider />
      <md-list class="md-drawer__list">
        <md-list-item
          v-if="showWorkspace"
          :disabled="$route.name === pageRoutes.WORKSPACE"
          @click="showNavigation = false; $router.push({ name: pageRoutes.WORKSPACE })"
        >
          <md-icon>library_books</md-icon>
          <span class="md-list-item-text">
            {{ getLocalize('buttonBookShelves.btnGoWorkspace') }}
          </span>
        </md-list-item>
        <md-list-item
          v-if="showSettings"
          :disabled="$route.name === pageRoutes.SETTINGS"
          @click="showNavigation = false; $router.push({ name: pageRoutes.SETTINGS })"
        >
          <md-icon>tune</md-icon>
          <span class="md-list-item-text">
            {{ getLocalize('buttonSettings.btnGoSettings') }}
          </span>
        </md-list-item>
        <md-list-item>
          <md-field>
            <img
              class="locale-select__icon"
              :src="getFlagSrc(curLocale)"
              alt=""
            >
            <md-select v-model="curLocale">
              <md-option
                v-for="(locale, index) in listOfLocals"
                :key="index"
                :value="locale"
              >
                <div class="locale_option">
                  <img
                    class="locale_option__icon"
                    :src="getFlagSrc(locale)"
                    alt=""
                  >
                  {{ getLocalize(`locales.${locale}`) }}
                </div>
              </md-option>
            </md-select>
          </md-field>
        </md-list-item>
        <div class="md-drawer__list-spring" />
        <md-list-item
          v-if="showClassList"
          @click="onClassList"
        >
          <md-icon>ballot</md-icon>
          <span class="md-list-item-text">
            {{ getLocalize('menu.classList') }}
          </span>
        </md-list-item>
        <md-list-item
          v-if="showLogout"
          @click="onLogout"
        >
          <md-icon>logout</md-icon>
          <span class="md-list-item-text">
            {{ getLocalize('menu.logout') }}
          </span>
        </md-list-item>
        <hr>
        <md-list-item @click="onWriteReader">
          <img
            class="header__powered-by"
            alt="Powered by WriteReader"
            src="~@/assets/powered_by.svg"
          >
        </md-list-item>
      </md-list>
    </md-drawer>
  </header>
</template>

<script>
import _last from 'lodash/last';
import cookie from '@/helpers/cookie';
import { APP_PAGES } from '@/helpers/const';
import Logo from '@/components/logo/Logo.vue';
import Notifications from '@/components/notifications/Notifications.vue';
import notificationsMixin from '@/mixins/notificationsMixin';
import sessionMixin from '@/mixins/sessionMixin';
import flagOfRussia from '@/assets/icons/flag_of_russia.svg';
import flagOfUS from '@/assets/icons/flag_of_the_united_states.svg';
import flagOfDenmark from '@/assets/icons/flag_of_denmark.svg';

/**
 * @emit onLogout - click on logout
 * @emit onClassList - click on class list
 * @emit onWriteReader - click on powered by WriteReader
 */
export default {
  name: 'Header',
  components: {
    Logo,
    Notifications,
  },
  mixins: [
    notificationsMixin,
    sessionMixin,
  ],
  props: {
    message: {
      type: String,
      default: '',
    },
    showLogout: {
      type: Boolean,
      default: true,
    },
    showClassList: {
      type: Boolean,
      default: true,
    },
    showSettings: {
      type: Boolean,
      default: false,
    },
    showWorkspace: {
      type: Boolean,
      default: false,
    },
    getLocalize: {
      type: Function,
      default: (key) => key,
      required: false,
    },
  },
  data: () => ({
    showNavigation: false,
    showSidepanel: false,
    listOfLocals: [],
    curLocale: '',
    inputText: '',
    rates: '',
    pageRoutes: APP_PAGES,
  }),
  computed: {
    fullName() {
      const firstName = this.sessionFirstName;
      const lastName = this.sessionLastName;

      if (firstName && lastName) {
        return `${lastName} ${firstName}`;
      }
      if (firstName) {
        return firstName;
      }
      if (lastName) {
        return lastName;
      }
      return '';
    },
    logoLink() {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`@/assets/${process.env.VUE_APP_LOGO_NAME}`);
    },
    menuLogoLink() {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`@/assets/${process.env.VUE_APP_MENU_LOGO_NAME}`);
    },
  },
  watch: {
    curLocale(locale) {
      this.$i18n.locale = locale;
      cookie.setLocaleInCookieOnYear(locale);
    },
  },
  created() {
    this.listOfLocals = Object.keys(this.$i18n.messages);
    this.curLocale = this.$i18n.locale;
  },
  methods: {
    getFlagSrc(locale) {
      switch (locale) {
        case 'ru':
          return flagOfRussia;
        case 'en':
          return flagOfUS;
        case 'da':
          return flagOfDenmark;
        default:
          return undefined;
      }
    },
    onEventClicked(event) {
      if (!event) {
        return;
      }
      this.readNotification(event.id);
      const { bookId, pageId, commentId } = event.details;
      if (!bookId) {
        return;
      }
      if (pageId) {
        this.$router.push({
          name: APP_PAGES.SOLVE_BOOK_BY_ID,
          params: { bookId, pageId },
          query: { comments: !!commentId },
        });
      } else {
        this.$router.push({
          name: APP_PAGES.SOLVE_FRONT_PAGE,
          params: { bookId },
          query: { comments: !!commentId },
        });
      }
    },
    onReadAll() {
      if (!this.notifications || this.notifications.length === 0) {
        return;
      }
      const last = _last(this.notifications);
      this.readNotification(last.id);
    },
    onLogout() {
      this.showNavigation = false;
      this.$emit('onLogout');
    },
    onClassList() {
      this.showNavigation = false;
      this.$emit('onClassList');
    },
    onWriteReader() {
      this.showNavigation = false;
      this.$emit('onWriteReader');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/header.scss";
</style>
