<template>
  <div class="image-search">
    <div
      v-if="displaySearchInput"
      class="image-search__header"
    >
      <div class="image-search__wrap">
        <custom-input
          v-model="localSearch"
          :label="searchInputPlaceholder"
          :inline="true"
          :md-field-style="searchStyles"
          class="image-search__wrap-input"
          @keypress.enter="onSearch"
        />
        <custom-button
          type="white-filled"
          icon="search"
          :title="searchButtonTitle"
          class="image-search__wrap-button"
          @click="onSearch"
        />
      </div>
      <div
        v-if="providerName"
        class="image-search__provider"
      >
        {{ providerMessage }}
        <a
          v-if="providerLink"
          class="image-search__provider_link"
          :href="providerLink"
          target="_blank"
        >
          {{ providerName }}
        </a>
        <span
          v-else
          class="image-search__provider_link"
        >
          {{ providerName }}
        </span>
      </div>
    </div>
    <image-list
      v-if="images.length"
      class="image-search__image_box"
      :images="imageSmall"
      @onEndOfListReached="onLoadMoreImages"
      @onImageSelected="onImageSelected"
    />
    <div
      v-if="!images.length"
      class="image-search__empty"
    >
      {{ emptyImageListMessage }}
    </div>
  </div>
</template>

<script>
import CustomInput from '@/components/inputs/input.vue';
import CustomButton from '@/components/buttons/CustomButton.vue';
import ImageList from '@/components/imageList/ImageList.vue';

/**
 * @emit onImageSearch - search images
 * @emit onLoadMoreImages - load more images
 * @emit onImageSourceSelected - image was selected
 */
export default {
  name: 'ImageSearch',
  components: { ImageList, CustomButton, CustomInput },
  props: {
    search: {
      type: String,
      default: '',
    },
    images: {
      type: Array,
      required: true,
    },
    emptyImageListMessage: {
      type: String,
      required: true,
    },
    searchButtonTitle: {
      type: String,
      default: '',
    },
    searchInputPlaceholder: {
      type: String,
      default: '',
    },
    displaySearchInput: {
      type: Boolean,
      default: true,
    },
    providerName: {
      type: String,
      default: '',
    },
    providerLink: {
      type: String,
      default: '',
    },
    providerMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      localSearch: this.search,
      searchStyles: {
        'margin-bottom': '8px',
      },
    };
  },
  computed: {
    imageSmall() {
      return this.images.map((image) => ({
        id: image.source,
        alt: image.originalName,
        source: image.sourceSmall || image.source,
      }));
    },
  },
  mounted() {
    if (!this.displaySearchInput && !this.images.length) {
      this.$emit('onLoadMoreImages');
    }
  },
  methods: {
    onSearch() {
      if (this.localSearch) {
        this.$emit('onImageSearch', this.localSearch);
      }
    },
    onLoadMoreImages() {
      if (this.images.length) {
        this.$emit('onLoadMoreImages');
      }
    },
    onImageSelected(image) {
      const index = this.imageSmall.findIndex((item) => item === image);
      this.$emit('onImageSourceSelected', this.images[index].source);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/imageSearch.scss";
</style>
