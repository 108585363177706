<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="book-page__hint">
    <img
      class="hint__image hint__image_student1"
      :src="studentSource"
    >
    <div class="hint__text">
      <span v-html="text" />
      <md-button
        class="md-icon-button md-primary"
        @click="onPageHintToSpeech"
      >
        <md-icon>
          record_voice_over
        </md-icon>
      </md-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hint',
  props: {
    studentSource: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: '',
      required: false,
    },
  },
  methods: {
    onPageHintToSpeech() {
      this.$emit('onPageHintToSpeech');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/bookPage.scss";
</style>
