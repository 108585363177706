import {
  MESSAGES_ENQUEUE_TOAST_MESSAGE,
  MESSAGES_DEQUEUE_TOAST_MESSAGE,
  MESSAGES_SET_HEADER_MESSAGE,
} from '@/store/modules/messages/actionTypes';
import {
  MESSAGES_PUSH_TOAST_MESSAGE,
  MESSAGES_SHIFT_TOAST_MESSAGE,
  MESSAGES_UPDATE_HEADER_MESSAGE,
} from '@/store/modules/messages/mutationTypes';

export default ({
  async [MESSAGES_ENQUEUE_TOAST_MESSAGE]({ commit }, message) {
    commit(MESSAGES_PUSH_TOAST_MESSAGE, message);
  },
  async [MESSAGES_DEQUEUE_TOAST_MESSAGE]({ commit }) {
    commit(MESSAGES_SHIFT_TOAST_MESSAGE);
  },
  async [MESSAGES_SET_HEADER_MESSAGE]({ commit }, message) {
    commit(MESSAGES_UPDATE_HEADER_MESSAGE, message);
  },
});
