<template>
  <div class="tn__step">
    <span class="tn__step-num">{{ number }}</span>
    <md-icon class="tn__step-icon">
      {{ icon }}
    </md-icon>
    <md-tooltip
      v-if="tooltip"
      md-direction="top"
    >
      {{ tooltip }}
    </md-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      default: 1,
      required: false,
    },
    icon: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/taskNavigation.scss";
</style>
