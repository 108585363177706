<template>
  <vue-aspect-ratio
    ar="3:4"
    class="responsive-book"
  >
    <div class="book-page">
      <checkered-background
        background-color="#FFF"
        class="book-page__content"
        line-color="#ccc"
      >
        <div
          v-if="!showEmptyPage"
          :style="getVisibleName(showPageImage)"
          class="book-page__img"
          :class="{ placeholder: !pageImageSource, view: viewImage }"
        >
          <play-audio
            v-if="pageTextAudioSource"
            :audio-src="pageTextAudioSource"
          />
          <mark-counter
            v-if="showMarkCounter"
            :marks="marks"
          >
            <image-viewer
              :image-alt="pageImageAlt"
              :image-source="pageImageSource"
              :crop-area="pageArea"
              :marks="marks"
              :show-marks="showMarks"
              :coefficient-mark-size="coefficientMarkSize"
            />
          </mark-counter>
          <image-viewer
            v-else
            :image-alt="pageImageAlt"
            :image-source="pageImageSource"
            :crop-area="pageArea"
            :marks="marks"
            :show-marks="showMarks"
            :coefficient-mark-size="coefficientMarkSize"
          />
        </div>
        <div
          v-if="!showEmptyPage"
          :style="getVisibleName(showPageText)"
          class="book-page__text-box"
        >
          <md-button
            v-if="pageText"
            class="md-icon-button book-page__tts md-primary"
            @click="onPageTextToSpeech"
          >
            <md-icon>
              record_voice_over
            </md-icon>
          </md-button>
          <stylized-text-area
            :value="pageText"
            background-text-color="#00F"
            class="book-page__text-style"
            only-read
            text-color="$black"
            :select-text="selectPageText"
            :padding-right="showTextToSpeech"
          />
          <div />
        </div>
        <div
          v-if="!showEmptyPage"
          class="book-page__answers"
        >
          <template
            v-if="viewOnly"
          >
            <answer
              v-for="(rightAnswer, i) of rightAnswers"
              :key="`r${i}`"
              :style="getVisibleName(showRightAnswers)"
              :text="rightAnswer"
              class="answer answer_right"
            />
            <answer
              v-for="(wrongAnswer, i) of wrongAnswers"
              :key="`w${i}`"
              :style="getVisibleName(showWrongAnswers)"
              :text="wrongAnswer"
              class="answer answer_wrong"
            />
          </template>
          <template
            v-else
          >
            <answer
              v-for="answ of answers"
              :key="answ.value"
              :text="answ.value"
              class="answer"
              :class="getAnwerClass(answ.cls, allowSelectAnswer, selectedAnswer == answ.value)"
              @click.native="selectAnswer(answ)"
            />
          </template>
        </div>
        <div class="book-page__footer">
          <hint
            :style="getVisibleName(showPageHint)"
            :text="pageHint"
            :student-source="studentSource"
            @onPageHintToSpeech="onPageHintToSpeech"
          />
          <div
            v-if="!showEmptyPage"
            class="book-page__page-number"
          >
            <p>{{ pageNumber }}</p>
          </div>
        </div>
      </checkered-background>
    </div>
  </vue-aspect-ratio>
</template>

<script>
import VueAspectRatio from 'vue-aspect-ratio';
import Answer from '@/components/answer/Answer.vue';
import Hint from '@/components/hint/Hint.vue';
import CheckeredBackground from '@/components/checkeredBackground/CheckeredBackground.vue';
import PlayAudio from '@/components/playAudio/PlayAudio.vue';
import StylizedTextArea from '@/components/stylizedTextArea/StylizedTextArea.vue';
import ImageViewer from '@/components/imageViewer/ImageViewer.vue';
import CropArea from '@/api/models/CropArea';
import MarkCounter from '@/components/markCounter/MarkCounter.vue';

/**
 * @emit onAnswerSelected - answer was selected
 */
export default {
  name: 'BookPage',
  components: {
    MarkCounter,
    ImageViewer,
    Answer,
    CheckeredBackground,
    PlayAudio,
    StylizedTextArea,
    Hint,
    VueAspectRatio,
  },
  props: {
    pageImageSource: {
      type: String,
      default: '',
      required: false,
    },
    pageImageAlt: {
      type: String,
      default: '',
      required: false,
    },
    pageArea: {
      type: CropArea,
      default: null,
    },
    pageText: {
      type: String,
      default: '',
      required: false,
    },
    pageTextAudioSource: {
      type: String,
      default: '',
      required: false,
    },
    rightAnswers: {
      type: Array,
      default: () => [],
      required: false,
    },
    wrongAnswers: {
      type: Array,
      default: () => [],
      required: false,
    },
    pageHint: {
      type: String,
      default: '',
      required: false,
    },
    pageNumber: {
      type: Number,
      default: 1,
      required: false,
    },
    showPageImage: {
      type: Boolean,
      default: true,
    },
    showPageText: {
      type: Boolean,
      default: true,
    },
    showRightAnswers: {
      type: Boolean,
      default: true,
    },
    showWrongAnswers: {
      type: Boolean,
      default: true,
    },
    showPageHint: {
      type: Boolean,
      default: true,
    },
    showEmptyPage: {
      type: Boolean,
      default: false,
    },
    showMarks: {
      type: Boolean,
      default: true,
    },
    marks: {
      type: Array,
      default: () => [],
    },
    coefficientMarkSize: {
      type: Number,
      default: 1,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
    attempts: {
      type: Array,
      default: () => [],
    },
    allowSelectAnswer: {
      type: Boolean,
      default: true,
    },
    showMarkCounter: {
      type: Boolean,
      default: false,
    },
    studentSource: {
      type: String,
      default: '',
    },
    selectPageText: {
      type: Boolean,
      default: false,
    },
    shuffleAnswers: {
      type: Array,
      default: () => [],
    },
    showTextToSpeech: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    selectedAnswer: null,
    viewImage: false,
  }),
  computed: {
    answers() {
      const answers = this.shuffleAnswers
        .map((answer) => this.formatAnswer(answer, this.rightAnswers.includes(answer)));
      return this.checkAnswers(
        answers,
        this.attempts,
      );
    },
  },
  created() {
    setTimeout(() => {
      this.viewImage = true;
    }, 0);
  },
  methods: {
    selectAnswer(answer) {
      if (!this.attempts.includes(answer.value) && this.allowSelectAnswer) {
        this.selectedAnswer = answer.value;
        this.$emit('onAnswerSelected', answer.value, answer.right);
      }
    },
    checkAnswers(answers, attempts) {
      return (answers || []).map((a) => ({
        ...a,
        cls: attempts.includes(a.value) ? this.getAnswerTypeClass(a.right) : '',
      }));
    },
    formatAnswer(answer, right) {
      return {
        right,
        value: answer,
      };
    },
    getAnswerTypeClass(right) {
      return right ? 'answer_right' : 'answer_wrong';
    },
    getVisibleName(isVisible) {
      return `visibility: ${isVisible ? 'visible' : 'hidden'}`;
    },
    getAnwerClass(defaultClass, allowSelectAnswer, answerSelected) {
      return `${defaultClass} ${!allowSelectAnswer ? 'answer_disabled' : ''} ${answerSelected ? 'selected' : ''}`;
    },
    onPageTextToSpeech() {
      this.$emit('onPageTextToSpeech');
    },
    onPageHintToSpeech() {
      this.$emit('onPageHintToSpeech');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/bookPage.scss";
@import "~@/assets/styles/desktop/components/responsiveBook.scss";
</style>
