import { LOAD_NOTIFICATIONS, LOAD_NOTIFICATIONS_PAGE, READ_NOTIFICATION } from '@/store/modules/notifications/actionTypes';
import { UPDATE_NOTIFICATIONS, ADD_NOTIFICATIONS } from '@/store/modules/notifications/mutationTypes';
import { API } from '@/api/api';

export default ({
  async [LOAD_NOTIFICATIONS]({ commit, dispatch }) {
    commit(UPDATE_NOTIFICATIONS, []);
    dispatch(LOAD_NOTIFICATIONS_PAGE);
  },
  async [LOAD_NOTIFICATIONS_PAGE]({ commit, dispatch }, token) {
    const response = await API.NotificationsService.getNotifications(token);
    if (response.success) {
      commit(ADD_NOTIFICATIONS, response.data);
      const newToken = response.token;
      if (newToken && newToken.length) {
        dispatch(LOAD_NOTIFICATIONS_PAGE, newToken);
      }
    }
  },
  async [READ_NOTIFICATION]({ dispatch }, notificationId) {
    const response = await API.NotificationsService.markNotificationRead(notificationId);
    if (response.success) {
      dispatch(LOAD_NOTIFICATIONS);
    }
  },
});
