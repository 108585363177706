import _invoke from 'lodash/invoke';
import _get from 'lodash/get';
import { API } from '@/api/api';
import localizationMixin from '@/mixins/localizationMixin';
import toastMixin from '@/mixins/toastMixin';
import { getTextFromHtml } from '@/helpers/functions';

export default {
  mixins: [
    localizationMixin,
    toastMixin,
  ],
  data: () => ({
    textToSpeechCache: {},
  }),
  methods: {
    async textToSpeech(text) {
      const t = getTextFromHtml(text);
      const { locale } = this.$i18n;

      if (_get(this.textToSpeechCache, [locale, t])) {
        _invoke(this.textToSpeechCache, [locale, t, 'play']);
      } else {
        const [response] = await this.toggleToast(
          this.getLocalize('toast.loading'),
          API.SpeechService.getUrl(t, locale),
        );

        if (response.success) {
          const audio = new Audio();
          audio.src = response.data.url;
          if (!this.textToSpeechCache[locale]) {
            this.textToSpeechCache[locale] = {};
          }
          this.textToSpeechCache[locale][t] = audio;
          await audio.play();
        }
      }
    },
  },
};
