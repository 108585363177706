const ID = 'id';
const BOOK_ID = 'bookId';
const PAGE_ID = 'pageId';
const ANSWER = 'answer';
const UNIT = 'unitMeasure';
const SUCCEED = 'succeed';
const CREATION_DATE = 'createDate';

export default class Attempt {
  constructor(parameters) {
    const {
      id,
      bookId,
      pageId,
      answer,
      unit,
      succeed,
      created,
    } = parameters;
    this.id = id;
    this.bookId = bookId;
    this.pageId = pageId;
    this.answer = answer;
    this.unit = unit;
    this.succeed = succeed;
    this.created = created;
  }

  static fromApi(object = {}) {
    return new Attempt(
      {
        id: object[ID],
        answer: object[ANSWER],
        unit: object[UNIT],
        succeed: object[SUCCEED],
        created: object[CREATION_DATE],
      },
    );
  }

  toApi() {
    return {
      [BOOK_ID]: this.bookId,
      [PAGE_ID]: this.pageId,
      [ANSWER]: this.answer,
      [UNIT]: this.unit,
    };
  }
}
