import _defaults from 'lodash/defaults';
import request from '@/api/httpClient';
import Book from '@/api/models/Book';
import { BOOK_SOURCE } from '@/helpers/const';
import { parseErrors } from '@/api/utils';

const defaultParams = {
  type: BOOK_SOURCE.ALL,
  pageToken: null,
};

export class BooksService {
  static async getBooks(params = {}) {
    try {
      const p = _defaults(params, defaultParams);
      const requestTask = request.getRequest(
        '/Books',
        {
          type: p.type,
          paginationToken: p.pageToken,
          order: 'CreateDate',
          direction: 'desc',
        },
        {},
      ).then((response) => {
        const books = response.data.data.map(Book.fromApi);
        return { success: true, data: books, token: response.data.token };
      });
      return await parseErrors(requestTask);
    } catch (e) {
      return { success: false };
    }
  }

  static async getBook(id) {
    try {
      const requestTask = request.getRequest(
        `/Books/${id}`,
        {},
        {},
      ).then((response) => {
        const book = Book.fromApi(response.data);
        return { success: true, data: book };
      });
      return await parseErrors(requestTask);
    } catch (e) {
      return { success: false };
    }
  }

  static async createBook(book) {
    try {
      const requestTask = request.postRequest(
        '/Books',
        book.toApi(),
        {},
      ).then((response) => {
        const newBook = Book.fromApi(response.data);
        return { success: true, data: newBook };
      });
      return await parseErrors(requestTask);
    } catch (e) {
      return { success: false };
    }
  }

  static async updateBook(id, book) {
    try {
      const requestTask = request.putRequest(
        `/Books/${id}`,
        book.toApi(),
        { 'Content-Type': 'multipart/form-data' },
      ).then((response) => {
        const updatedBook = Book.fromApi(response.data);
        return { success: true, data: updatedBook };
      });
      return await parseErrors(requestTask);
    } catch (e) {
      return { success: false };
    }
  }

  static async deleteBook(id) {
    try {
      const requestTask = request.deleteRequest(`/Books/${id}`).then(() => ({ success: true })).then(() => ({ success: true }));
      return await parseErrors(requestTask);
    } catch (e) {
      return { success: false };
    }
  }
}
