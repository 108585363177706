import request from '@/api/httpClient';
import Session from '@/api/models/Session';

export class SessionService {
  static async getSession() {
    try {
      const response = await request.getRequest(
        '/Session',
        {},
        {},
      );
      const data = Session.fromApi(response.data);
      return { success: true, data };
    } catch (e) {
      return { success: false, data: { error: '' } };
    }
  }
}
