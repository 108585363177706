<template>
  <div class="ntf__event">
    <md-icon class="ntf__event-icon md-primary">
      {{ icon }}
    </md-icon>
    <div class="ntf__event-body">
      <span class="ntf__event-text">
        <span
          class="ntf__event-link"
          @click="onClick"
        >
          {{ link }}</span>{{ text }}
      </span>
      <span class="ntf__event-time">
        {{ agoFormatedDate }} {{ byUser }}
      </span>
    </div>
  </div>
</template>

<script>
import dateFormatMixin from '@/mixins/dateFormatMixin';

/**
 * @emit onClick - event link clicked
 */

export default {
  name: 'Event',
  mixins: [dateFormatMixin],
  props: {
    icon: {
      type: String,
      default: 'post_add',
      required: true,
    },
    link: {
      type: String,
      default: '',
      required: true,
    },
    text: {
      type: String,
      default: '',
      required: true,
    },
    created: {
      type: String,
      default: '',
      required: true,
    },
    user: {
      type: String,
      default: '',
      required: true,
    },
    getLocalize: {
      type: Function,
      default: (key) => key,
      required: false,
    },
  },
  computed: {
    byUser() {
      return this.user && this.user.length ? `${this.getLocalize('notifications.by')} ${this.user}` : '';
    },
  },
  methods: {
    onClick() {
      this.$emit('onClick');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/notifications.scss";
</style>
