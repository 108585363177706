<template>
  <vue-aspect-ratio
    ar="3:4"
    class="responsive-book"
  >
    <checkered-background
      :background-color="backgroundColor"
      :line-color="backgroundTint"
      class="front-page"
      :class="{ 'read': readOnly }"
    >
      <div class="front-page-box">
        <md-button
          class="md-icon-button md-raised color-button"
          :class="{ hidden: readOnly }"
          @click="onColorDialogShow"
        >
          <md-icon class="main-theme-icon front-page__color-icon" />
        </md-button>
        <div
          v-if="readOnly"
          class="front-page-info"
        >
          <p
            v-if="title"
            class="front-page-info_title"
          >
            {{ title }}
          </p>
          <p
            v-else
            class="front-page-info_title hidden"
          >
            title
          </p>
          <p
            v-if="author"
            class="front-page-info_author"
          >
            {{ author }}
          </p>
          <p
            v-else
            class="front-page-info_author hidden"
          >
            author
          </p>
        </div>
        <div
          v-else
          class="front-page-info"
        >
          <validation-observer ref="formFrontPage">
            <validation-provider
              v-slot="{errors}"
              :rules="{ max: 50 }"
              name="title"
            >
              <default-input
                :label="getLocalize('frontPage.title')"
                :maxlength="50"
                :only-read="readOnly"
                :styles="inputTitleStyle"
                :validation="errors"
                :value="title"
                spellcheck="false"
                @input="onTitleUpdated($event)"
              />
            </validation-provider>
            <validation-provider
              v-slot="{errors}"
              :rules="{ max: 50 }"
              name="author"
            >
              <default-input
                :label="getLocalize('frontPage.author')"
                :maxlength="50"
                :only-read="readOnly"
                :styles="inputAuthorStyle"
                :validation="errors"
                :value="author"
                spellcheck="false"
                @input="onAuthorUpdated($event)"
              />
            </validation-provider>
          </validation-observer>
        </div>
        <md-button class="md-icon-button md-raised color-button hidden">
          <md-icon class="main-theme-icon front-page__color-icon" />
        </md-button>
      </div>
      <image-selector
        class="front-page-picture"
        :get-localize="getLocalize"
        :image-source="imageSource"
        :read-only="readOnly"
        :image-bank-source="imageBankSource"
        :upload-image-extensions="uploadImageExtensions"
        :upload-size-image-max-k-b="uploadSizeImageMaxKB"
        :show-image-search-tab="showImageSearchTab"
        :show-camera-tab="showCameraTab"
        :show-upload-file-tab="showUploadFileTab"
        :show-user-image-tab="showUserImageTab"
        :show-workspace-image-tab="showWorkspaceImageTab"
        :found-images="foundImages"
        :loaded-image-source="loadedImageSource"
        :image-search-provider-name="imageSearchProviderName"
        :image-search-provider-link="imageSearchProviderLink"
        :crop-area="cropArea"
        :allow-add-marks="false"
        :search-string="searchString"
        :active-file-dialog-tab="activeFileDialogTab"
        :image-bank-tab-id-chain="imageBankTabIdChain"
        @onFileSelected="onImageFileUpdated"
        @onImageSourceSelected="onImageSourceUpdated"
        @onImageSearch="onImageSearch"
        @onLoadMoreSearchImages="onLoadMoreSearchImages"
        @onImageSearchSourceUpdated="onImageSearchSourceUpdated"
        @onCropAreaUpdated="onCropAreaUpdated"
        @onActiveFileDialogTabUpdated="onActiveFileDialogTabUpdated"
        @onImageBankTabIdChainUpdated="onImageBankTabIdChainUpdated"
      />
    </checkered-background>
  </vue-aspect-ratio>
</template>

<script>
import _upperFirst from 'lodash/upperFirst';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { max } from 'vee-validate/dist/rules';
import VueAspectRatio from 'vue-aspect-ratio';
import CheckeredBackground from '@/components/checkeredBackground/CheckeredBackground.vue';
import DefaultInput from '@/components/inputs/input.vue';
import ImageSelector from '@/components/imageSelector/ImageSelector.vue';
import fileDialogForwardingMixin from '@/mixins/fileDialogForwardingMixin';

extend('max', max);

/**
 * @emit onTitleUpdated - title updated
 * @emit onAuthorUpdated - author updated
 * @emit onValidUpdated - valid form state updated
 * @emit onImageFileUpdated - image updated
 * @emit onImageSourceUpdated - image source was updated
 * @emit onImageSearch - search images
 * @emit onLoadMoreSearchImages - load more images
 * @emit onImageSearchSourceUpdated - image source updated
 * @emit onCropAreaUpdated - crop area was updated
 * @emit onColorDialogShow - click on color button
 * @emit onActiveFileDialogTabUpdated - activeFileDialogTab was updated
 * @emit onImageBankTabIdChainUpdated - imageBankTabIdChain was updated
 */
export default {
  name: 'FrontPage',
  components: {
    ImageSelector,
    CheckeredBackground,
    DefaultInput,
    ValidationProvider,
    ValidationObserver,
    VueAspectRatio,
  },
  mixins: [
    fileDialogForwardingMixin,
  ],
  props: {
    title: {
      type: String,
      default: '',
      required: false,
    },
    author: {
      type: String,
      default: '',
      required: false,
    },
    backgroundColor: {
      type: String,
      default: '',
      required: false,
    },
    backgroundTint: {
      type: String,
      default: '',
      required: false,
    },
    imageSource: {
      type: String,
      default: '',
      required: false,
    },
    imageAlt: {
      type: String,
      default: '',
      required: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    loadedImageSource: {
      type: String,
      default: '',
      required: false,
    },
    getLocalize: {
      type: Function,
      default: (key) => key,
      required: false,
    },
  },
  data: () => ({
    showFileDialog: false,
  }),
  computed: {
    inputTitleStyle() {
      const fontSize = 23 - (this.title.length / 6);
      return {
        fontSize: `${fontSize}px !important`,
        width: '100%',
      };
    },
    inputAuthorStyle() {
      const fontSize = 16 - (this.author.length / 40);
      return {
        fontSize: `${fontSize}px !important`,
        width: '100%',
      };
    },
  },
  watch: {
    async title() {
      await this.validation();
    },
    async author() {
      await this.validation();
    },
  },
  async mounted() {
    if (this.title || this.author) {
      await this.validation();
    }
  },
  methods: {
    onFileDialogClosed() {
      this.showFileDialog = false;
    },
    onFileDialogShow() {
      this.showFileDialog = true;
    },
    onColorDialogShow() {
      this.$emit('onColorDialogShow');
    },
    onTitleUpdated(title) {
      if (title.length === 1) {
        this.$emit('onTitleUpdated', _upperFirst(title));
      } else {
        this.$emit('onTitleUpdated', title);
      }
    },
    onAuthorUpdated(author) {
      if (author.length === 1) {
        this.$emit('onAuthorUpdated', _upperFirst(author));
      } else {
        this.$emit('onAuthorUpdated', author);
      }
    },
    onImageFileUpdated(file) {
      this.$emit('onImageFileUpdated', file);
    },
    onImageSourceUpdated(imageSource) {
      this.$emit('onImageSourceUpdated', imageSource);
    },
    onCropAreaUpdated(cropArea) {
      this.$emit('onCropAreaUpdated', cropArea);
    },
    async validation() {
      if (!this.readOnly) {
        const valid = await this.$refs.formFrontPage.validate();
        this.$emit('onValidUpdated', valid);
      }
    },
    async validate() {
      const valid = await this.$refs.formFrontPage.validate();
      return valid;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/components/frontPage.scss";
@import "~@/assets/styles/desktop/components/responsiveBook.scss";
</style>
