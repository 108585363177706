import { APP_PAGES } from '@/helpers/const';
import UserStorage from '@/storages/userStorage';
import { API } from '@/api/api';
import { LOGOUT } from '@/store/actionTypes';
import { getBaseUrl } from '@/api/utils';

export default {
  data: () => ({
    baseApiUrl: getBaseUrl(),
    showLoginForm: process.env.VUE_APP_FM_SHOW_LOGIN_FORM === 'true',
  }),
  computed: {
    showClassList() {
      return this.$route.name !== APP_PAGES.SIGN_IN && !this.showLoginForm;
    },
  },
  methods: {
    openClassList() {
      const { token } = UserStorage.getToken();
      API.AccountService.logout();
      this.$store.dispatch(LOGOUT);

      const url = new URL('api/authservice', this.baseApiUrl);
      url.searchParams.set('token', `${token}`.replace('bearer ', ''));
      window.location.href = url;
    },
  },
};
