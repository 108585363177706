<template>
  <div :style="underlayClass">
    <slot />
  </div>
</template>

<script>
import _reduce from 'lodash/reduce';

export default {
  name: 'CheckeredBackground',
  props: {
    backgroundColor: {
      type: String,
      required: true,
    },
    lineColor: {
      type: String,
      default: 'black',
    },
    linesCountHorizontally: {
      type: Number,
      default: 30,
    },
    linesCountVertically: {
      type: Number,
      default: 45,
    },
    cellSize: {
      type: Number,
      default: 30,
    },
    strokeWidth: {
      type: String,
      default: '1px',
    },
  },
  computed: {
    underlayClass() {
      const width = this.linesCountHorizontally * this.cellSize;
      const height = this.linesCountVertically * this.cellSize;
      const hLines = _reduce(new Array(this.linesCountHorizontally), (lines, item, i) => `${lines}${this.createLineV(i * this.cellSize + 1, height)}`, '')
        .replace(/#/g, '%23');
      const vLines = _reduce(new Array(this.linesCountVertically), (lines, item, i) => `${lines}${this.createLineH(i * this.cellSize + 1, width)}`, '')
        .replace(/#/g, '%23');

      const svg = `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 ${width} ${height}' width='${width}px' height='${height}px'>${hLines}${vLines}</svg>`;
      return {
        'background-image': `url("data:image/svg+xml;utf8,${svg}")`,
        'background-color': this.backgroundColor,
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
      };
    },
  },
  methods: {
    createLineV(x, height) {
      return `<line x1='${x}' y1='0' x2='${x}' y2='${height}' stroke='${this.lineColor}' stroke-width='${this.strokeWidth}' />`;
    },
    createLineH(y, width) {
      return `<line x1='0' y1='${y}' x2='${width}' y2='${y}' stroke='${this.lineColor}' stroke-width='${this.strokeWidth}' />`;
    },
  },
};
</script>

<style scoped>

</style>
