<template>
  <md-dialog
    :md-active="showDialog"
    @update:mdActive="onUpdateShowDialog($event)"
  >
    <md-dialog-title v-if="titleText">
      {{ titleText }}
    </md-dialog-title>
    <md-dialog-content v-if="contentText">
      {{ contentText }}
    </md-dialog-content>
    <md-dialog-actions>
      <custom-button
        :title="cancelButtonText"
        :icon="cancelIcon"
        type="white-filled"
        @click="onCancel"
      />
      <custom-button
        :title="confirmButtonText"
        :icon="confirmIcon"
        type="primary-filled"
        @click="onConfirm"
      />
    </md-dialog-actions>
  </md-dialog>
</template>

/**
* @emit onConfirm - click button confirm
* @emit onCancel - click button cancel
*/
<script>
import CustomButton from '@/components/buttons/CustomButton.vue';

export default {
  name: 'ConfirmDialog',
  components: { CustomButton },
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    titleText: {
      type: String,
      default: '',
    },
    contentText: {
      type: String,
      default: '',
    },
    confirmButtonText: {
      type: String,
      required: true,
    },
    cancelButtonText: {
      type: String,
      required: true,
    },
    confirmIcon: {
      type: String,
      default: null,
    },
    cancelIcon: {
      type: String,
      default: null,
    },
  },
  methods: {
    onUpdateShowDialog(showDialog) {
      if (!showDialog && this.showDialog !== showDialog) {
        this.$emit('onCancel');
      }
    },
    onConfirm() {
      this.$emit('onConfirm');
    },
    onCancel() {
      this.$emit('onCancel');
    },
  },
};
</script>

<style scoped>

</style>
