import _get from 'lodash/get';
import _filter from 'lodash/filter';
import { READ_NOTIFICATION } from '@/store/modules/notifications/actionTypes';

export default {
  computed: {
    notifications() {
      const notifications = _get(this.$store.getters, 'showNotifications', []);
      return _filter(notifications,
        (notification) => notification.isNew);
    },
  },
  methods: {
    readNotification(notificationId) {
      this.$store.dispatch(READ_NOTIFICATION, notificationId);
    },
  },
};
