const ID = 'id';
const ORIGINAL_NAME = 'originalName';
const SOURCE = 'url';
const SOURCE_SMALL = 'previewUrl';

export default class Image {
  constructor(parameters) {
    const {
      id,
      originalName,
      source,
      sourceSmall,
    } = parameters;
    this.id = id;
    this.originalName = originalName;
    this.source = source;
    this.sourceSmall = sourceSmall;
  }

  static fromApi(object = {}) {
    return new Image(
      {
        id: object[ID],
        originalName: object[ORIGINAL_NAME],
        source: object[SOURCE],
        sourceSmall: object[SOURCE_SMALL],
      },
    );
  }

  toApi() {
    return {
      [ID]: this.id,
      [ORIGINAL_NAME]: this.originalName,
      [SOURCE]: this.source,
      [SOURCE_SMALL]: this.sourceSmall,
    };
  }
}
