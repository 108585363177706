export default {
  computed: {
    sessionUserId() {
      return this.$store.state.session.userId;
    },
    sessionRole() {
      return this.$store.state.session.userRole;
    },
    sessionFirstName() {
      return this.$store.state.session.userFirstName;
    },
    sessionLastName() {
      return this.$store.state.session.userLastName;
    },
    sessionUserName() {
      return this.$store.state.session.userName;
    },
    isTeacher() {
      return this.sessionRole && this.sessionRole.toLowerCase() === 'teacher';
    },
    isStudent() {
      return !this.isTeacher;
    },
  },
};
