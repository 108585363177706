import _invokeMap from 'lodash/invokeMap';
import _invoke from 'lodash/invoke';
import Mark from '@/api/models/Mark';

const TYPE = 'type';
const NAME = 'name';
const MARKS = 'marks';

export default class MarkCollection {
  constructor(parameters) {
    const {
      type,
      name,
    } = parameters;
    this.type = type;
    this.name = name;
    this.marks = _invoke(
      parameters,
      'marks.map',
      (item) => new Mark(item),
    ) || [];
  }

  static fromApi(object = {}) {
    return new MarkCollection(
      {
        type: object[TYPE],
        name: object[NAME],
        marks: _invoke(
          object,
          [MARKS, 'map'],
          Mark.fromApi,
        ) || [],
      },
    );
  }

  toApi() {
    return {
      [TYPE]: this.type,
      [NAME]: this.name,
      [MARKS]: _invokeMap(this.marks, 'toApi'),
    };
  }
}
