import SessionUser from '@/api/models/SessionUser';
import Workspace from '@/api/models/Workspace';

const SESSION_USER = 'user';
const SESSION_WORKSPACE = 'workspace';

export default class Session {
  constructor(parameters) {
    const {
      user,
      workspace,
    } = parameters;
    this.user = user;
    this.workspace = workspace;
  }

  static fromApi(object = {}) {
    return new Session(
      {
        user: object[SESSION_USER] && SessionUser.fromApi(object[SESSION_USER]),
        workspace: object[SESSION_WORKSPACE] && Workspace.fromApi(object[SESSION_WORKSPACE]),
      },
    );
  }

  toApi() {
    return {
      [SESSION_USER]: this.user.toApi(),
      [SESSION_WORKSPACE]: this.workspace.toApi(),
    };
  }
}
