import request from '@/api/httpClient';
import Attempt from '@/api/models/Attempt';

export class AttemptsService {
  static async createAttempt(bookId, pageId, answer, unit) {
    try {
      const attempt = new Attempt({
        bookId, pageId, answer, unit,
      });
      const response = await request.postRequest(
        '/Attempts',
        attempt.toApi(),
        {},
      );
      const newAttempt = Attempt.fromApi(response.data);
      return { success: true, data: newAttempt };
    } catch (e) {
      console.error(e);
      return { success: false, data: { error: '' } };
    }
  }
}
