<template>
  <div
    id="app"
    :class="{ 'app-with-footer': appWithFooter }"
    :dir="getLocale === 'ar' ? 'rtl' : 'ltr'"
  >
    <Header
      :get-localize="getLocalize"
      :show-logout="showLogout"
      :show-class-list="showClassList"
      :show-settings="showSettings"
      :show-workspace="showWorkspace"
      :message="headerMessage"
      @onLogout="logout"
      @onClassList="openClassList"
      @onWriteReader="onWriteReader"
    />
    <div
      :class="currentThemeClass"
      class="app-wrapper"
    >
      <router-view />
    </div>
    <toast-alert
      :show-toast="showToast"
      :message="toastMessage"
      :show-close-button="showCloseButton"
      @onClose="hideToast"
      @onClosed="onClosedToast"
    />
    <offline-dialog
      :show-dialog="!online"
      :get-localize="getLocalize"
    />
  </div>
</template>

<script>
import _get from 'lodash/get';
import httpClient from '@/api/httpClient';
import { LOAD_SESSION } from '@/store/modules/session/actionTypes';
import { LOAD_NOTIFICATIONS } from '@/store/modules/notifications/actionTypes';
import Header from '@/components/header/Header.vue';
import localizationMixin from '@/mixins/localizationMixin';
import 'animate.css';
import { API } from '@/api/api';
import { APP_PAGES, USER_ROLE } from '@/helpers/const';
import { LOGOUT } from '@/store/actionTypes';
import ToastAlert from '@/components/toastAlert/ToastAlert.vue';
import { MESSAGES_DEQUEUE_TOAST_MESSAGE } from '@/store/modules/messages/actionTypes';
import { sleep } from '@/helpers/functions';
import sessionMixin from '@/mixins/sessionMixin';
import classListMixin from '@/mixins/classListMixin';
import { getBaseUrl } from '@/api/utils';
import OfflineDialog from '@/components/offlineDialog/OfflineDialog.vue';

export default {
  name: 'App',
  components: {
    OfflineDialog,
    ToastAlert,
    Header,
  },
  mixins: [
    localizationMixin,
    sessionMixin,
    classListMixin,
  ],
  data: () => ({
    baseApiUrl: getBaseUrl(),
    defaultToastDuration: process.env.VUE_APP_DEFAULT_TOAST_DURATION,
    showLoginForm: process.env.VUE_APP_FM_SHOW_LOGIN_FORM === 'true',
    showToast: false,
    toastMessage: '',
    showCloseButton: false,
    poweredByWriteReaderLink: process.env.VUE_APP_POWERED_BY_WRITE_READER_LINK,
    appWithFooter: !!process.env.VUE_APP_FOOTER_NAME,
    online: true,
  }),
  computed: {
    currentThemeClass() {
      return `theme-${process.env.VUE_APP_FM_THEME}`;
    },
    showLogout() {
      return this.$route.name !== APP_PAGES.SIGN_IN;
    },
    showWorkspace() {
      return this.$route.name !== APP_PAGES.SIGN_IN;
    },
    showSettings() {
      return [USER_ROLE.ADMIN, USER_ROLE.TEACHER].includes(_get(this.$store.state, 'session.userRole'));
    },
    firstToastMessage() {
      return _get(this.$store.state, 'messages.toastMessageQueue.0');
    },
    headerMessage() {
      return _get(this.$store.state, 'messages.headerMessage', '');
    },
    loadSession() {
      return this.showLogout && this.sessionRole === USER_ROLE.TEACHER;
    },
  },
  watch: {
    firstToastMessage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$nextTick(async () => {
          switch (typeof newValue) {
            case 'string':
              await this.toggleToast(newValue, sleep(this.defaultToastDuration));
              break;
            case 'object': {
              const tasks = newValue.tasks || [sleep(this.defaultToastDuration)];
              this.showCloseButton = !!newValue.showCloseButton;
              await this.toggleToast(newValue.message, ...tasks);
              break;
            }
            case 'undefined':
              await this.hideToast();
              break;
            default:
              break;
          }
        });
      }
    },
    loadSession: {
      handler(load) {
        if (load) {
          this.$store.dispatch(LOAD_NOTIFICATIONS);
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  mounted() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  created() {
    httpClient.setUnauthorizedListener(this.logout);
  },
  beforeCreate() {
    this.$nextTick(() => {
      if (this.$route.name !== APP_PAGES.SIGN_IN) {
        this.$store.dispatch(LOAD_SESSION);
      }
    });
  },
  methods: {
    updateOnlineStatus() {
      this.online = navigator.onLine;
    },
    logout() {
      API.AccountService.logout();
      this.$store.dispatch(LOGOUT);

      if (!this.showLoginForm) {
        window.location.href = new URL('api/authservice', this.baseApiUrl);
      } else if (this.$route.name !== APP_PAGES.SIGN_IN) {
        this.$router.push({ name: APP_PAGES.SIGN_IN });
      }
    },
    onClosedToast() {
      this.$store.dispatch(MESSAGES_DEQUEUE_TOAST_MESSAGE);
    },
    onWriteReader() {
      window.open(this.poweredByWriteReaderLink);
    },
    async toggleToast(message, ...tasks) {
      this.toastMessage = message;
      this.showToast = true;
      const result = await Promise.all(tasks);
      if (this.toastMessage === message) {
        this.showToast = false;
      }
      return result;
    },
    async hideToast() {
      this.showToast = false;
      this.showCloseButton = false;
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import "assets/styles/mixins.scss";

html, body {
  background: url("assets/images/bg.png");
  background-color: $primary-background;
}

body {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  font-family: $defaultFont;
  -webkit-font-smoothing: auto;
  font-size: 15px;
  letter-spacing: 0;
  color: $black;
}

#app {
  color: $black;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &.app-with-footer {
    min-height: calc(100vh - 5rem);
  }
}

* {
  margin: 0;
  padding: 0;
  list-style-type: none;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.b-button--filled-white svg {
  fill: $primary-dark !important;
}

.b-buttonfilled svg {
  fill: white !important;
}

.cropper-background {
  background: none;
}

.md-dialog-container {
  max-width: 58rem;
  border-radius: .5em;
}

.md-toolbar.md-theme-default {
  background-color: white;
  box-shadow: 0px 1px 3px rgba(17, 17, 17, 0.08);
}

.md-list-item-container,
.md-input,
.md-input::placeholder,
.md-menu.md-select .md-input,
.md-field.md-has-placeholder .md-input {
  font-size: .9rem !important;
}

.md-list.md-theme-default,
.md-menu-content.md-select-menu {
  border-radius: .5em;
  overflow: hidden;
}

.md-menu-content {
  max-width: 200px;
}

.md-list.md-theme-default
.md-list-item-container:not(.md-list-item-default):not(.md-list-item-expand):not([disabled]):hover {
  background-color: $primary-light;
}

.md-list {
  padding: 0;
}

.md-tabs-navigation {
  background: $primary-dark !important;
  padding: 2px 9px !important;
  overflow-x: auto;
  overflow-y: hidden;

  .md-button {
    height: 40px;
    border-radius: 4px;
    background: lighten($primary-dark, 3%);
    margin: .15rem;
    min-width: 48px;

    &:first-child {
      margin-left: 0;
    }

    &.md-active {
      background: $primary-color;
    }
  }
}

.image-bank__sub_category .md-tabs-navigation {
  background-color: #81b6be !important;
}

.md-tab, .md-dialog-container .md-tab {
  padding: 0;
}

.md-tabs .md-tabs-indicator {
  display: none;
}

.md-speed-dial {

  &.md-bottom-right {
    position: sticky;
    position: -webkit-sticky;
    will-change: transform;
    bottom: 1.2em;
    margin-bottom: 1.2em;
    margin-right: 1.2em;
    align-self: flex-end;
    z-index: 2;
  }
}

.md-switch {
  margin: 0;
  align-items: center;

  &.switch-on {
    .md-switch-thumb {
      background-color: $primary-color;
    }
    .md-switch-container {
      background-color: rgba($primary-color, .38);
    }
  }
}

.md-switch-label {
  display: inline-flex;
  align-items: center;
  height: auto !important;

  i {
      padding-right: .5em;
  }
}

// Badges mobile size
.md-badge {
  @media screen and (max-width: 850px) {
    width: 18px;
    height: 18px;
  }
}

.mark-counter {
  .md-badge {
    width: 16px;
    height: 16px;
  }
}

.md-icon-button, .md-ripple {
  z-index: auto;
}

.md-field label {
  font-size: 14px;
}

@include mobile-width-height {
  .md-icon {
    font-size: 22px !important;
  }

  .md-icon-button {
    width: 2.3rem;
    min-width: 2.3rem;
    height: 2.3rem;
    font-size: 1rem;
  }
}

.md-badge {
  width: 18px;
  height: 18px;
  right: 2px;
}

.md-ripple {
  z-index: auto;
}

.md-tooltip {
  border-radius: .5em;
}

@media (max-width: 600px) {
  .md-dialog-fullscreen {
    width: auto;
    height: auto;
  }
}

.md-dialog-actions {
  padding: 8px 24px 24px 24px;
}

.answer-box {
  .md-field {
    margin-bottom: 0;
  }

  .md-autocomplete .md-count {
    display: none;
  }
}

.front-page {
  .md-field {
    margin: 4px 0 8px;
  }
}

.offline-dialog .md-dialog-container.md-theme-default {
  background: none;
  box-shadow: none;
}

</style>
