<template>
  <div class="settings">
    <div class="settings__back-box">
      <custom-button
        class="settings__back-button"
        :title="getLocalize('settings.actionBack')"
        icon="keyboard_arrow_left"
        :show-shadow="false"
        @click="openBookShelf"
      />
    </div>
    <div class="settings__container">
      <form
        novalidate
        class="md-layout settings__form"
      >
        <md-card class="md-layout-item settings__card">
          <md-card-header class="settings__header">
            <div class="md-title settings__header-title">
              <md-icon>tune</md-icon>
              {{ getLocalize('settings.pageHeading') }}
            </div>
          </md-card-header>

          <md-card-content class="settings__body">
            <div class="md-layout-item settings__section">
              <div class="md-subheading">
                {{ getLocalize('settings.headingImageGallery') }}
              </div>
              <settings-option
                v-model="localWorkspace.showUploadFileInFileDialog"
                icon="file_upload"
                :text="getLocalize('settings.fileUpload')"
                :tooltip="getLocalize('settings.fileUploadTooltip')"
              />
              <settings-option
                v-model="localWorkspace.showCameraInFileDialog"
                :icon="'photo_camera'"
                :text="getLocalize('settings.takePhoto')"
                :tooltip="getLocalize('settings.takePhotoTooltip')"
              />
              <settings-option
                v-model="localWorkspace.showWorkspaceImageInFileDialog"
                icon="workspaces"
                :text="getLocalize('settings.allClassImages')"
                :tooltip="getLocalize('settings.allClassImagesTooltip')"
              />
              <settings-option
                v-model="localWorkspace.showUserImageInFileDialog"
                icon="folder"
                :text="getLocalize('settings.userUploads')"
                :tooltip="getLocalize('settings.userUploadsTooltip')"
              />
            </div>
            <div class="md-layout-item settings__section">
              <settings-option
                v-model="localWorkspace.showImageSearchInFileDialog"
                :icon="'image_search'"
                :text="getLocalize('settings.imageSearch')"
                :tooltip="getLocalize('settings.imageSearchTooltip')"
              />
              <div class="settings__provider">
                <span>
                  {{ getLocalize('imageSearch.providerMessage') }}
                </span>
                <a
                  v-if="imageSearchProviderLink"
                  class="settings__provider_link"
                  :href="imageSearchProviderLink"
                  target="_blank"
                >
                  {{ imageSearchProviderName }}
                </a>
                <span
                  v-else
                  class="settings__provider_link"
                >
                  {{ imageSearchProviderName }}
                </span>
              </div>
            </div>
            <div class="md-layout-item settings__section">
              <div class="md-subheading">
                {{ getLocalize('settings.headingFeedback') }}
              </div>
              <settings-option
                v-model="localWorkspace.textCommentsAvailable"
                :icon="'title'"
                :text="getLocalize('settings.feedbackText')"
                :tooltip="getLocalize('settings.feedbackTextTooltip')"
              />
              <settings-option
                v-model="localWorkspace.smileCommentsAvailable"
                :icon="'emoji_emotions'"
                :text="getLocalize('settings.feedbackEmojis')"
                :tooltip="getLocalize('settings.feedbackEmojisTooltip')"
              />
            </div>
          </md-card-content>

          <md-card-actions class="settings__footer">
            <md-button
              type="button"
              class="md-primary md-raised"
              :disabled="sending"
              @click="onSave"
            >
              {{ getLocalize('settings.actionSave') }}
            </md-button>
          </md-card-actions>
        </md-card>
      </form>
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get';
import { APP_PAGES, USER_ROLE } from '@/helpers/const';
import localizationMixin from '@/mixins/localizationMixin';
import Workspace from '@/api/models/Workspace';
import { SAVE_WORKSPACE } from '@/store/modules/workspace/actionTypes';
import toastMixin from '@/mixins/toastMixin';
import { sleep } from '@/helpers/functions';
import CustomButton from '@/components/buttons/CustomButton.vue';
import SettingsOption from './SettingsOption.vue';

export default {
  name: 'Settings',
  components: {
    SettingsOption,
    CustomButton,
  },
  mixins: [localizationMixin, toastMixin],
  data() {
    return {
      pageRoutes: APP_PAGES,
      imageSearchProviderName: process.env.VUE_APP_IMAGE_SEARCH_PROVIDER_NAME,
      imageSearchProviderLink: process.env.VUE_APP_IMAGE_SEARCH_PROVIDER_LINK,
      sending: false,
      localWorkspace: new Workspace(this.$store.state.workspace),
    };
  },
  computed: {
    workspaceHash() {
      return this.calculateWorkspaceHash(this.$store.state.workspace);
    },
    canWatch() {
      return [USER_ROLE.ADMIN, USER_ROLE.TEACHER].includes(_get(this.$store.state, 'session.userRole'));
    },
  },
  watch: {
    workspaceHash() {
      this.localWorkspace = new Workspace(this.$store.state.workspace);
    },
  },
  mounted() {
    if (!this.canWatch) {
      this.$router.replace({ name: APP_PAGES.WORKSPACE });
    }
  },
  methods: {
    async onSave() {
      if (this.calculateWorkspaceHash(this.localWorkspace)
        !== this.calculateWorkspaceHash(this.$store.state.workspace)) {
        this.sending = true;
        const savingTask = this.$store.dispatch(SAVE_WORKSPACE, this.localWorkspace);
        await this.toggleToast(this.getLocalize('toast.preservation'), savingTask);
        this.sending = false;
      }
      await this.toggleToast(this.getLocalize('toast.saved'), sleep(2000));
      this.openBookShelf();
    },
    openBookShelf() {
      this.$router.push({ name: APP_PAGES.WORKSPACE });
    },
    calculateWorkspaceHash(workspace) {
      const a = workspace.showImageSearchInFileDialog;
      const b = workspace.showCameraInFileDialog;
      const c = workspace.showUploadFileInFileDialog;
      const d = workspace.showWorkspaceImageInFileDialog;
      const e = workspace.showUserImageInFileDialog;
      const f = workspace.smileCommentsAvailable;
      const g = workspace.textCommentsAvailable;
      return `${a}${b}${c}${d}${e}${f}${g}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/desktop/pages/settings.scss";
</style>
